import React from 'react';
import { FormLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Styles from './AutocompleteStyles';

interface OwnProps {
    id: string,
    classes?: any,
    label: string,
    name: string,
    value: any,
    options: any[],
    placeholder: string,
    disabled?: boolean,
    auth: boolean,
    getOptionLabel: (option: any) => string,
    onChange: (event: any, newValue: string | null, reason: string) => void,
    getOptionSelected: (option: any, value: any) => boolean
}

class AutocompleteInput extends React.Component<OwnProps> {

    render() {
        const { classes, label, id, options, value, disabled, placeholder, auth, getOptionLabel, getOptionSelected, onChange } = this.props;

        return (<>
            <FormLabel
                classes={{ root: classes.label }}
                component="legend"
            >
                {label}
            </FormLabel>
            <Autocomplete
                classes={{
                    root: classes.autocompleteRoot,
                    inputRoot: auth ? classes.autocompleteInputAuth : classes.autocompleteInput
                }}
                id={id}
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                onChange={onChange}
                value={value}
                disabled={disabled ? disabled : false}
                renderInput={(params) => (
                    <TextField {...params} placeholder={placeholder} variant="outlined" required/>
                )}
            />
        </>
        );
    }
}

export default withStyles(Styles as any)(AutocompleteInput);