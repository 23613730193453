import React from 'react';
import { connect } from 'react-redux';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

import { SidebarItem, UserModel } from 'models';
import { RootState } from 'redux/store';
import { isSeller } from 'services/authService';

import Logo from 'assets/logo/logo.png';
import EITLogo from 'assets/images/eit_logo_small.png';
import PersonIcon from '@material-ui/icons/Person';
import MovieIcon from '@material-ui/icons/Movie';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SidebarStyles from './SidebarStyles';

interface OwnProps {
    classes?: any,
    open: boolean,
    handleDrawerClose: (payload: any) => void
}

interface StateProps {
    user: UserModel
}

type Props = StateProps & OwnProps

const isMovieActive = (match: any, location: any) => {
    const pathname: string = location.pathname;
    if (pathname === '/movies' || pathname === '/catalogue') {
        return true;
    } else if (pathname.startsWith('/movies') || pathname.startsWith('/catalogue')) {
        return true;
    } else return false;
}

class LeftSideBar extends React.Component<Props> {

    render() {
        const { classes } = this.props;

        const mainListItems: SidebarItem[] = [
            { name: isSeller(this.props.user) ? 'My collections' : 'Collections', url: '/catalogue', icon: <MovieIcon />, isActive: isMovieActive }, // Seller: movies uploaded; Buyer: movies owned (with a license)
        ];

        if (isSeller(this.props.user)) {
            mainListItems.push(
                { name: 'My bespoke licenses', url: '/myLicenseTemplates', icon: <AssignmentTurnedInIcon /> }, // Seller Only
            );
        }

        mainListItems.push(
            { name: 'My signed licenses', url: '/myCustomLicenses', icon: <AssignmentIcon /> }, // Buyer: purchased/reserved licenses; Seller: licenses tied to uploaded movies
            { name: 'Profile', url: '/profile', icon: <PersonIcon /> }
        );

        return (<>
            {/* Left Sidebar */}
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.sidebar,
                }}
                open={this.props.open}
            >
                <div className={classes.toolbarLogo}>
                    <NavLink to="/catalogue">
                        <img src={Logo} alt="Logo" />
                    </NavLink>
                </div>
                <List className={classes.itemsContainer}>
                    {mainListItems.map((item: any, index: any) => {
                        return (
                            <NavLink
                                key={index}
                                className={classes.sidebarItemLink}
                                activeClassName="active"
                                isActive={item.isActive}
                                to={item.url}
                            >
                                <ListItem className={classes.sidebarItem}>
                                    <ListItemIcon
                                        className={classes.sidebarItemIcon}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.sidebarItemTxt }}
                                        primary={item.name}
                                    />
                                </ListItem>
                            </NavLink >
                        )
                    })}

                </List>

                {<div className={classes.sidebarFooter}>
                    <img className={classes.eitLogo} src={EITLogo} alt="EIT Logo"/>
                    <span className={classes.sidebarFooterTxt}>
                        Project supported by EIT
                    </span>
                </div>}

            </Drawer>
        </>)
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
    mapStateToProps
)(withStyles(SidebarStyles)(LeftSideBar));