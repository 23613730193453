import { createStyles } from '@material-ui/styles';

const useStyles = (theme: any) => createStyles({
  /* New Dashbord Layout */
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    height: 72,
  },
  appBar: {
    backgroundColor: 'white',
    color: '#7a7a7a',
    boxShadow: '0px 4px 10px rgba(240, 240, 240, 0.25)',
  },
  appBarShift: {
    marginLeft: 330,
    width: `calc(100% - ${330}px)`,
  },
  categoryDropdown: {
    /*width: "18%",*/
    width: "9.5%",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center"
  },
  expandIcon: {
    fontSize: 24,
    verticalAlign: "middle",
    marginLeft: 5
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },

});

export default useStyles;