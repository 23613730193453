import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
    /* License Rights Title & Options */
    licenseTitle: {
        color: '#D8996F',
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        marginBottom: 20
    },
    licenseSubtitle: {
        color: '#D8996F',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: '0.04em',
        marginBottom: 15
    },
    doneIcon: {
        marginLeft: 5,
        fontSize: 15
    },
    titleMargin: {
        marginTop: 30
    },
    optionsContainer: {
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'row',
        '& > *': {
            marginRight: 35
        }
    },
    licenseButton: {
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        fontWeight: 400,
        fontSize: 16,
        color: '#7A7A7A',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgba(43,55,238,0.8)',
            color: 'white'
        }
    },
    activeButton: {
        backgroundColor: 'rgba(43,55,238,1)',
        color: 'white'
    },
    savedButton: {
        backgroundColor: '#D8996F',
        color: 'white'
    },
    noLicenses: {
        marginTop: 30,
        fontSize: 20,
        fontWeight: 700,
        letterSpacing: 0.5,
        color: '#7A7A7A',
        textAlign: 'center'
    },

    /* License Rights Box */
    licenseRightsBody: {
        position: 'relative',
        padding: 20,
        paddingBottom: 80,
        border: '1px solid #2B37EE',
        borderRadius: 10,
        minHeight: 475,
    },

    /* Territory Box Layout */
    territoryContainer: {
        '& >*': {
            paddingRight: 33
        }
    },
    territoryItemGrid: {
        marginTop: 20
    },
    territoryItem: {
        backgroundColor: 'white',
        borderRadius: 10,
        minWidth: 206,
        paddingTop: 25,
        paddingLeft: 25,
        paddingBottom: 25,
        minHeight: 350,
        display: 'flex',
        flexDirection: 'column',
    },

    /* Territory Box Content */
    territoryTitle: {
        fontSize: 16,
        fontWeight: 700,
        color: '#2B37EE',
        textTransform: 'uppercase',
        lineHeight: '19px',
        paddingBottom: 8,
    },
    closeContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 5
    },
    territoryOptionsContainer: {
        flex: 1,
        paddingRight: 15
    },
    territoryOptions: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column'
    },
    exclusivityContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    blockedPeriodsContainer: {
        marginTop: 8,
    },
    territoryLabel: {
        color: '#7A7A7A',
        fontSize: 14,
        letterSpacing: '0.02em',
        height: 35
    },
    plusIcon: {
        fontSize: 22,
    },
    checkboxIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: 22,
        }
    },
    blockedPeriodStyle: {
        '& .rs-picker-toggle.active': {
            borderColor: '#2B37EE'
        },
        '& .rs-picker-toggle-placeholder': {
            color: '#7A7A7A'
        },
        '& .rs-picker-toggle-caret': {
            color: '#c28963'
        },
        '& .rs-picker-toggle-value': {
            color: '#c28963 !important',
            fontWeight: 'bold'
        }
    },
    periodRemoveStyle: {
        color: '#c28963',
    },

    /* Save Button */
    saveBtn: {
        width: 123,
        position: 'absolute',
        right: 25,
        bottom: 25,
        backgroundColor: 'rgba(43,55,238,1)',
        color: 'white',
        '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: 'white'
        }
    },

    /* Educational Window */
    educationalContainer: {
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20
    },
    eduElement: {
        padding: 15
    },
    eduRightFormControl: {
        width: '70%',
        minWidth: '18em',
        maxWidth: '25em'
    },
    eduDatePicker: {
        width: '100%',
        '&.rs-picker-disabled': {
            opacity: 1,
            '& .rs-picker-toggle-value': {
                color: 'rgba(0, 0, 0, 0.5) !important'
            },
            '& .rs-picker-toggle.active': {
                boxShadow: 'none'
            }
        },
        '& .rs-picker-toggle': {
            display: 'flex',
            background: '#fbfbfb',
            fontSize: 16,
            height: 48,
            padding: '0 20px',
            border: 0,
            '&:hover': {
                border: '0 !important'
            },
            '&.active': {
                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
                    '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
                    '0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }
        },
        '& .rs-picker-toggle-value': {
            display: 'flex',
            alignItems: 'center',
            color: 'black !important'
        },
        '& .rs-picker-toggle-caret': {
            top: '12px !important',
            right: '18px !important'
        },
        '& .rs-picker-toggle-clean': {
            top: '12px !important',
            right: '40px !important'
        }
    }
});

export default useStyles;
