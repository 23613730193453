import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';

import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import { CustomLicenseModel, LicenseTemplateModel } from 'models';
import { RootState } from 'redux/store';
import { addBFILicenseToCart } from 'services/licenseService';
import Loading from 'components/loading/Loading';

import Styles from 'styles/dashboard';

interface OwnProps {
    classes?: any,
    history: any,
    match: any
}

interface StateProps {
    customLicense: CustomLicenseModel,
    licenseTemplate: LicenseTemplateModel
}

interface State {
    loading: boolean,
    disabledBtn: boolean,
    licenseError: string
}

type Props = StateProps & OwnProps

class CustomizeLicensePage extends React.Component<Props, State> {
    state = {
        loading: false,
        disabledBtn: true,
        licenseError: ''
    };

    addToCart = async () => {
        const productId = this.props.match.params.id;
        this.setState({ loading: true });

        /*await addCustomLicenseToCart(productId, this.props.licenseTemplate, this.props.customLicense).then(
            (value) => { // On run
                this.setState({ loading: false });
                this.props.history.push("/myCustomLicenses");
                // window.location.reload();
            },
            (reason) => { // on fail
                console.log(reason)
            });*/

        await addBFILicenseToCart(productId).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myCustomLicenses");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false, licenseError: reason });
                console.log(reason)
            });
    }

    setDisabledState = (disabled: boolean) => {
        this.setState({ disabledBtn: disabled });
    }

    render() {
        const { classes } = this.props;
        const productId = this.props.match.params.id;
        return (
            <div className={classes.content}>

                <div className={classes.headerContainer}>

                    <Typography
                        variant="h5"
                        className={classes.dashboardSubtitleBlue}
                    >
                        Customize License
                    </Typography>
                </div>

                {this.state.loading ?
                    <Loading /> : <></>}

                {this.state.loading ? <></> :
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <LicenseWindow
                                licenseType='custom'
                                productId={productId}
                                licenseId=''
                                operationMode='create'
                                setDisabledState={this.setDisabledState.bind(this)}
                            />
                            <div className={classes.footerContainer}>
                                <NavLink className={classes.dashLinkBtn} to={`/movies/${productId}`}>
                                    <Button className={classes.secondaryBtn} variant="outlined">
                                        Back to Movie
                                    </Button>
                                </NavLink>

                                <Button
                                    className={classes.dashboardButton}
                                    variant="outlined"
                                    disableRipple
                                    disabled={this.state.disabledBtn}
                                    onClick={() => {
                                        this.addToCart()
                                    }}
                                >
                                    Purchase License
                                </Button>
                            </div>
                        </Grid>
                    </Grid>}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    customLicense: state.customLicense,
    licenseTemplate: state.licenseTemplate
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
    mapStateToProps
)(withStyles(Styles as any)(CustomizeLicensePage));