import { baseUrl, api } from 'services/customAxios'
import { UserModel } from 'models'
import jwt_decode from "jwt-decode";
//`http://${settings.API_URL}:${settings.API_PORT}/products/${this.props.match.params.id}`
//http://${settings.API_URL}:${settings.API_PORT}/auth/login
const login = async (username: string, password: string) => {
    return await api.post(baseUrl + '/auth/login', { user: {
        username: username,
        password: password
    } })
        .then(res => {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            return res.data;
        })
        .catch(error => {
            console.log(error)
            console.log('login error')
            console.log(error.response.data.message)
            return Promise.reject(error);
        })
}

const register = async (newUser: any) => {
    return await api.post(baseUrl + '/auth/register', { user: newUser })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            const errorMessage = error.response.data.message;
            return Promise.reject(errorMessage ? errorMessage : 'Cannot submit form with errors.');
        })
}

const logout = async () => {
    localStorage.setItem('accessToken', '')
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken)
        return Promise.reject('User not logged in')
    const decipheredToken: any = jwt_decode(refreshToken !== undefined ? refreshToken : '');

    return await api.post(baseUrl + '/auth/logout', { refreshToken: refreshToken, userid: decipheredToken.userid })
        .then(res => {
            localStorage.clear();
            window.location.reload();
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const verifyEmail = async (token: string) => {
    return await api.put(baseUrl + '/auth/verify/' + token)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const isLoggedIn = () => {
    const token = localStorage.getItem("accessToken")
    if (!token)
        return false;
    else return true;
}

const isSeller = (user: UserModel) => {
    return user.role === 'seller';
}

const isBuyer = (user: UserModel) => {
    return user.role === 'buyer';
}

const checkUsername = async (username: string) => {
    return await api.post(baseUrl + '/auth/checkUsername', { user: {username: username} })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const checkEmail = async (email: string) => {
    return await api.post(baseUrl + '/auth/checkEmail', { user: { email: email } })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const resendEmail = async (username: string) => {
    return await api.post(baseUrl + '/auth/resendEmail', { username: username })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

export {
    login,
    register,
    logout,
    isLoggedIn,
    isSeller,
    isBuyer,
    checkEmail,
    checkUsername,
    verifyEmail,
    resendEmail
}