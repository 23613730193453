import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import reducer from "./reducers";

//const store = createStore(reducer);

const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['your/action/type'],
          // Ignore these field paths in all actions
          ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
          // Ignore these paths in the state
          ignoredPaths: ['items.dates'],
        },
      }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


export default store;
