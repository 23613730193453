import React from 'react';
import {
    Typography,
    Button,
    // Checkbox,
    // FormControlLabel,
    Link,
} from '@material-ui/core';
import { Link as NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { verifyEmail } from 'services/authService';

import AuthSplash from 'components/authSplash/AuthSplash';
import LogoMFH from 'assets/logo/mfh-logo.jpg';

import AuthStyles from 'styles/auth';

interface OwnProps {
    classes: any,
    history: any,
    match?: any
}

interface State {
    header: string,
    content: string
}

class VerificationPage extends React.Component<OwnProps, State> {
    state = {
        header: 'Account validated with success',
        content: 'Your account has been validated with success. Please login into the platform :)'
    }

    async componentDidMount() {
        await verifyEmail(this.props.match.params.id).then(
            (value) => { // On run
                this.setState({
                    header: value.header,
                    content: value.content
                });
                console.log(value);
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.authFormContainer}>
                <div className={classes.authFormBox}>

                    <div className="sublogo-container-login">
                        <img className="logo-mfh" src={LogoMFH} alt="Make Film History Logo" />
                    </div>

                    <div className={classes.verificationContainer}>
                        <Typography component="h1" variant="h5" className={classes.verificationTitle}>
                            {this.state.header}
                        </Typography>
                        <p className={classes.verificationSubtitle}>
                            {this.state.content}
                        </p>
                    </div>

                    <Link
                        className={classes.redirectLink}
                        component={NavLink}
                        to="/auth/login"
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="default"
                            className={classes.submit}
                        >
                            Go to Login Page
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withStyles(AuthStyles as any)(VerificationPage);