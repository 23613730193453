import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { UserModel } from 'models';
import { FilterOptions, SliderActions } from 'models/enums';
import combineStyles from 'utils/combineStyles';
import FilterGenres from './FilterGenres';
import FilterDuration from './FilterDuration';
import FilterYear from './FilterYear';
// import FilterSellers from './FilterSellers';
// import { isBuyer } from 'services/authService';

import DashboardStyles from 'styles/dashboard';
import FilterStyles from './FStyles';

interface OwnProps {
    classes?: any,
    display: boolean,
    handleSelect: (type: string, checkedValue: string) => void,

    genreValues: string[],
    genreList: string[],

    durationRange: number[],
    defaultMinDuration: number,
    defaultMaxDuration: number,

    yearRange: number[],
    defaultMinYear: number,
    defaultMaxYear: number,
    handleSliderChange: (type: string, action: string, value?: number[]) => void,

    sellerValues: string[],
    sellerList: string[],
}

interface StateProps {
    user: UserModel
}

type Props = OwnProps & StateProps

class Filter extends React.Component<Props> {

    render() {
        const { classes, display } = this.props;

        let filterstyle = {
            opacity: 0,
            maxHeight: 0,
            overflow: 'hidden'
        } as React.CSSProperties;

        if (display) {
            filterstyle = {
                opacity: 1,
                maxHeight: 400,
                marginBottom: 50
            }
        }

        return (
            <div className={classes.filterBox} style={filterstyle}>
                <div className={classes.filtersFlexContainer}>
                    <FilterGenres
                        genreList={this.props.genreList}
                        genreValues={this.props.genreValues}
                        handleGenreSelect={(value: string) => this.props.handleSelect(FilterOptions.GENRES, value)}
                    />

                    <FilterDuration
                        minValue={this.props.defaultMinDuration}
                        maxValue={this.props.defaultMaxDuration}
                        range={this.props.durationRange}
                        handleChange={(e, value) => this.props.handleSliderChange(FilterOptions.DURATION, SliderActions.CHANGE, value as number[])}
                        resetSlider={() => this.props.handleSliderChange(FilterOptions.DURATION, SliderActions.RESET)}
                        commitChanges={(e, value) => this.props.handleSliderChange(FilterOptions.DURATION, SliderActions.COMMIT, value as number[])}
                    />

                    {(this.props.defaultMinYear && this.props.defaultMaxYear) && <FilterYear
                        minValue={this.props.defaultMinYear}
                        maxValue={this.props.defaultMaxYear}
                        range={this.props.yearRange}
                        handleChange={(e, value) => this.props.handleSliderChange(FilterOptions.YEAR, SliderActions.CHANGE, value as number[])}
                        resetSlider={() => this.props.handleSliderChange(FilterOptions.YEAR, SliderActions.RESET)}
                        commitChanges={(e, value) => this.props.handleSliderChange(FilterOptions.YEAR, SliderActions.COMMIT, value as number[])}
                    />}

                    {/*isBuyer(this.props.user) ? <FilterSellers
                        sellerList={this.props.sellerList}
                        sellerValues={this.props.sellerValues}
                        handleSellerSelect={(value: string) => this.props.handleSelect(FilterOptions.SELLERS, value)}
                    /> : <></>*/}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => ({
    user: state.user
});

const combinedStyles = combineStyles(DashboardStyles, FilterStyles);

export default connect(mapStateToProps)(withStyles(combinedStyles)(Filter));