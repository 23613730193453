import { Action, ACTION_TYPES } from "../actions";

export const searchResultsReducer = (state = [], action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SEARCH.SET_SEARCH_RESULTS:
            return action.payload;
        default:
            return state;
    }
};

export const searchInputReducer = (state = '', action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SEARCH.SET_SEARCH_INPUT:
            return action.payload;
        default:
            return state;
    }
};

export const searchStateReducer = (state = false, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SEARCH.UPDATE_SEARCH_STATE:
            return action.payload;
        default:
            return state;
    }
};