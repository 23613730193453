import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';

import Loading from 'components/loading/Loading';
import ProductInfo from 'pages/product/ProductInfo';
import VideoPopup from 'pages/product/ProductVideo';
import combineStyles from 'utils/combineStyles';
import { GenreModel, ProductModel, UserModel } from 'models';
import { RootState } from 'redux/store';
import { getProduct } from 'services/productService';
import { isSeller } from 'services/authService';
// import { isSeller, isBuyer } from 'services/authService';
import { 
    getLicenseTemplateByProductBFI,
    createTemplateBFI, 
    addBFILicenseToCart 
} from 'services/licenseService';
// import settings from 'config';

import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';

const LICENSE_SELLER_ERROR = 'Error: License template already exists.';
const LICENSE_BUYER_ERROR = 'Error: No license was created for this movie.';

/*const MockGenres = [
    {
        name: 'action',
        selected: false
    },
    {
        name: 'science',
        selected: true
    },
    {
        name: 'mystery',
        selected: true
    },
    {
        name: 'psychological',
        selected: true
    },
    {
        name: 'drama',
        selected: false
    },
    {
        name: 'romance',
        selected: false
    },
    {
        name: 'horror',
        selected: false
    }
];*/

interface OwnProps {
    classes: any,
    history: any,
    match: any
}

interface State {
    product: ProductModel,
    loading: boolean,
    licenseError: string,
    templateExists: boolean,
    dialog: boolean,
    dialogTitle: string,
    videoSrc: string
}

interface StateProps {
    user: UserModel
}

type Props = StateProps & OwnProps

class ProductPage extends React.Component<Props, State> {
    state = {
        product: {
            _id: '',
            productid: -1,
            title: '',
            shorttitle: '',
            creationdate: '',
            poster: '',
            licensor: -1,
            licensetemplateid: '',
            director: '',
            year: 0,
            contentRating: '',
            duration: -1,
            genres: [] as GenreModel[],
            synopsis: '',
            language: '',
            movie: '',
            trailer: '',
            seller: ''
        },
        loading: true,
        licenseError: '',
        templateExists: false,
        dialog: false,
        dialogTitle: '',
        videoSrc: ''
    }

    async componentDidMount() {
        /* Runs after the render */
        await getProduct(this.props.match.params.id).then(
            (value) => { // On run
                console.log(value);
                this.setState({ product: value, loading: false });
            },
            (reason) => { // on fail
                console.log(reason)
            });

        await getLicenseTemplateByProductBFI(this.props.match.params.id, this.props.user.role).then(
            (value) => { // On run
                if (value.licenseTemplate) {
                    this.setState({ templateExists: true})
                }
            },
            (reason) => { // on fail
                console.log(reason)
            }
        )
    }

    openVideoPopup(dialogValue: boolean) {
        this.setState({
            dialog: dialogValue
        })
    }

    setVideoInfo(videoSrc: string, dialogTitle: string) {
        this.setState({
            videoSrc: videoSrc,
            dialogTitle: dialogTitle
        })
    }

    async createLicense() {
        if (this.state.templateExists) {
            this.setState({ licenseError: LICENSE_SELLER_ERROR });
            return;
        }

        this.setState({ loading: true });
        await createTemplateBFI(this.state.product.productid.toString()).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myLicenseTemplates");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false, licenseError: reason });
                console.log(reason)
            });
    }

    async purchaseLicense() {
        if (!this.state.templateExists) {
            this.setState({ licenseError: LICENSE_BUYER_ERROR });
            return;
        }

        this.setState({ loading: true });
        await addBFILicenseToCart(this.state.product.productid.toString()).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myCustomLicenses");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false, licenseError: reason });
                console.log(reason)
            });
    }

    render() {
        const { classes } = this.props;
        const { loading, product } = this.state;

        if (loading) {
            return <Loading />;
        }

        if (!product.title) {
            return (<div className={classes.productNotFound}>
                <Typography
                    variant="h1"
                    className={classes.productNotFoundTitle}
                >
                    Oops...
                </Typography>
                <Typography
                    variant="h2"
                    className={classes.productNotFoundTxt}
                >
                    Movie was not found!
                </Typography>
            </div>);
        }

        return (
            <div>
                <div className={classes.headerContainer}>

                    <Typography
                        variant="h5"
                        className={classes.dashboardSubtitle}
                    >
                        {product.title}
                    </Typography>
                </div>
                <Typography
                    className={classes.licenseError}
                    component="h1"
                    variant="h6"
                    style={{ color: 'red', display: this.state.licenseError ? 'block' : 'none' }}
                >
                    {this.state.licenseError}
                </Typography>

                <ProductInfo
                    product={product}
                    openVideoPopup={this.openVideoPopup.bind(this)}
                    setVideoInfo={this.setVideoInfo.bind(this)}
                />

                <VideoPopup
                    openPopup={this.state.dialog}
                    setOpenPopup={this.openVideoPopup.bind(this)}
                    dialogTitle={this.state.dialogTitle}
                    videoSrc={this.state.videoSrc}
                />

                <div className={classes.productOptions}>
                    <NavLink className={classes.dashLinkBtn} to={"/catalogue"}>
                        <Button className={classes.secondaryBtn} variant="outlined">
                            {isSeller(this.props.user) ? "Back to My Collections" : "Back to Collections"}
                        </Button>
                    </NavLink>

                    {/* {isSeller(this.props.user)? (
                        <>
                            {(settings.SIMPLE_VERSION || this.state.templateExists) ?
                                <Button
                                    className={classes.dashboardButton}
                                    variant="outlined"
                                    onClick={this.createLicense.bind(this)}
                                    disabled
                                >
                                    Create License
                                </Button> :
                                
                                <Button className={classes.dashboardButton} variant="outlined" disabled>
                                    {<NavLink className={classes.dashLinkBtn} to={"/movies/" + product.productid + "/createLicense"}>}
                                        Create License
                                    {</NavLink>}
                                </Button>
                                
                            }
                        </>
                    ) : ''} */}

                    {/* {isBuyer(this.props.user) ? (

                        <>
                            {(settings.SIMPLE_VERSION || !this.state.templateExists) ?
                                <Button
                                    className={classes.dashboardButton}
                                    variant="outlined"
                                    onClick={this.purchaseLicense.bind(this)}
                                    disabled
                                >
                                    Purchase License
                                </Button> :
                                <Button className={classes.dashboardButton} variant="outlined" disabled>
                                    {<NavLink className={classes.dashLinkBtn} to={"/movies/" + product.productid + "/customizeLicense"}>}
                                    Customize License
                                    {</NavLink>}
                                </Button>

                            }
                        </>
                    ) : ''} */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
    mapStateToProps
)(withStyles(combinedStyles)(ProductPage));