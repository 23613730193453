import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
    tableContainer: {
        borderRadius: 10,
        boxShadow: 'none',
        border: '1px solid #D7D7D7'
    },
    tableHead: {
        '& .MuiTableCell-head': {
            color: '#2B37EE',
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& .MuiTableRow-root > *:not(:last-child)': {
            borderRight: '1px solid #D7D7D7'
        }
    },
    tableBody: {
        '& .MuiTableCell-body': {
            color: '#000000',
            fontSize: 16,
            padding: 20
        },
        '& .MuiTableRow-root > *:not(:last-child)': {
            borderRight: '1px solid #D7D7D7'
        }
    },
    downloadLink: {
        color: '#2B37EE',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        }
    },
    downloadIcon: {
        fill: '#2B37EE',
        marginRight: 5 
    },
    noLicenses: {
        textAlign: 'center'
    }
});

export default useStyles;