import { createStyles } from '@material-ui/styles';

const useStyles = (theme: any) => createStyles({
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 50
    },
    footerContainer: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        '& >*': {
            marginLeft: 24
        }
    },
    dashboardTitle: {
        fontSize: 36,
        fontWeight: 700,
        color: '#D8996F'
    },
    dashboardSubtitle: {
        fontSize: 30,
        fontWeight: 700,
        color: '#D8996F'
    },
    dashboardSubtitleBlue: {
        fontSize: 30,
        fontWeight: 700,
        color: '#2B37EE'
    },
    dashLinkBtn: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    dashboardButton: {
        minWidth: 160,
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#2B37EE',
        color: 'white',
        border: 'none',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '0.02em',
        '&.Mui-disabled': {
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'none'
        },
        '&:hover': {
            backgroundColor: 'rgba(43,55,238,0.9)'
        }
    },
    dashboardMargin: {
        marginTop: 30
    },
    secondaryBtn: {
        minWidth: 160,
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#E5E5E5',
        color: '#7A7A7A',
        border: '2px solid #7A7A7A',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
        '&:hover': {
            color: 'white',
            backgroundColor: '#7A7A7A'
        }
    },
    btnIcon: {
        marginLeft: 5
    }
});

export default useStyles;