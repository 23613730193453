import { createStyles } from '@material-ui/styles';

const footerHeight = '160px';

const useStyles = createStyles({
    footerContainer: {
        position: 'absolute',
        width: '100%',
        backgroundColor: 'white',
        height: footerHeight,
        display: 'flex',
        justifyContent: 'center'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& >*:not(:last-child)': {
            height: 70,
            marginTop: 20,
            marginBottom: 20,
            marginRight: 20,
            paddingRight: 20,
            borderRight: '1px solid rgba(122,122,122,0.3)'
        }
    },
    
    /* Footer Content */
    eitLogo: {
        width: 300
    },
    supporterTxt: {
        width: '37%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        lineHeight: '1.8em',
        letterSpacing: '0.2px',
        fontWeight: 500,
        color: '#7a7a7a'
    },
    copyrightTxt: {
        letterSpacing: '0.6px',
        fontWeight: 300,
    },

});

export default useStyles;
