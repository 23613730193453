import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';

import FieldData from 'components/fielddata/FieldData';
import TextArea from 'components/controls/textarea/TextArea';
import TextInput from 'components/controls/textInput/TextInput';
import Genres from 'pages/product/ProductGenres';
import combineStyles from 'utils/combineStyles';
import { ProductModel } from 'models';
import { formatSecondsToTime, removeHTML } from 'utils/utils';

import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';

import MovieIcon from '@material-ui/icons/Movie';
import TheatersIcon from '@material-ui/icons/Theaters';
import DefaultPoster from 'assets/images/default-img.jpg';

interface Props {
    classes: any,
    product: ProductModel,
    openVideoPopup: (value: boolean) => void,
    setVideoInfo: (videoSrc: string, dialogTitle: string) => void
}

class ProductInfo extends React.Component<Props> {
    handleMovieSelect() {
        this.props.setVideoInfo(this.props.product.movie, 'Movie Screener');
        this.props.openVideoPopup(true);
    }

    handleTrailerSelect() {
        this.props.setVideoInfo(this.props.product.trailer as string, 'Movie Trailer');
        this.props.openVideoPopup(true);
    }

    render() {
        const { classes, product } = this.props;
        const year = product.year;
        const duration = formatSecondsToTime(product.duration);
        const poster = (product.poster ? product.poster : DefaultPoster);
        const synopsis = product.synopsis ? 
            removeHTML(product.synopsis).replace(/$(\r|\n)(?=.)/gm, ' ') : product.synopsis;

        return (
            <>
                <Grid container spacing={3}>

                    {/* Left Column */}
                    <Grid item container sm={4} alignItems="flex-start">

                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.moviePosterContainer}>
                                <img className={classes.moviePoster} src={poster} alt="Movie Poster" />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.movieOptions}>

                                    <Button
                                        className={classes.movieOption}
                                        variant="outlined"
                                        onClick={this.handleMovieSelect.bind(this)}
                                    >
                                        <MovieIcon />
                                        <span className={classes.optionDescription}>Screener</span>
                                    </Button>

                                    {product.trailer ?
                                        <Button
                                            className={classes.movieOption}
                                            variant="outlined"
                                            onClick={this.handleTrailerSelect.bind(this)}
                                        >
                                            <TheatersIcon />
                                            <span className={classes.optionDescription}>Trailer</span>
                                        </Button> : <></>}
                                </div>
                            </Grid>
                            {product.language ? <Grid item xs={12}>
                                <TextInput
                                    type="text"
                                    label="Language"
                                    placeholder="Enter the movie language"
                                    value={product.language}
                                    disabled={true}
                                    required={true}
                                />
                            </Grid> : <></>}
                        </Grid>
                    </Grid>

                    {/* Right Column */}
                    <Grid item container sm={8} alignItems="flex-start">
                        <Grid item container spacing={3}>
                            <Grid item sm={6} >
                                <TextInput
                                    type="text"
                                    label="Title"
                                    placeholder="Enter the movie title"
                                    value={product.title}
                                    disabled={true}
                                    required={true}
                                />
                            </Grid>

                            {product.director ?
                                <Grid item sm={6} >
                                    <TextInput
                                        type="text"
                                        label="Director"
                                        placeholder="Enter the director name"
                                        value={product.director}
                                        disabled={true}
                                        required={true}
                                    />
                                </Grid> : <></>}

                            {product.year ?
                                <Grid item sm={product.director ? 4 : 6}>
                                    <TextInput
                                        type="number"
                                        label="Year"
                                        placeholder="Enter the film year"
                                        value={year ? year : -1}
                                        disabled={true}
                                        required={true}
                                    />
                                </Grid> : <></>}

                            {product.contentRating ?
                                <Grid item sm={product.director ? 4 : 6}>
                                    <TextInput
                                        type="text"
                                        label="Content rating"
                                        placeholder="Select the content rating"
                                        value={product.contentRating}
                                        disabled={true}
                                        required={true}
                                    />
                                </Grid> : <></>}

                            <Grid item sm={product.director ? 4 : 6}>
                                <TextInput
                                    type="text"
                                    label="Duration"
                                    placeholder="Select the content rating"
                                    value={duration}
                                    disabled={true}
                                    required={true}
                                />
                                {/*<FieldData
                                    label="Duration"
                                    description={duration}
                                />*/}
                            </Grid>

                            <Grid item sm={12}>
                                <FieldData
                                    label="Genre"
                                    description=""
                                />
                                <Genres genres={product.genres} />
                            </Grid>

                            {product.synopsis ?
                                <Grid item sm={12}>
                                    <TextArea
                                        type="text"
                                        label="Synopsis"
                                        placeholder="Enter the description of the film"
                                        value={synopsis}
                                        multiline={true}
                                        rows={5}
                                        disabled={true}
                                        required={true}
                                    />
                                </Grid> : <></>}

                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(ProductInfo);