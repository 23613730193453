import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';

import combineStyles from 'utils/combineStyles';

import DashboardStyles from 'styles/dashboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface OwnProps {
    classes?: any,
    linkTxt: string,
    linkUrl: string
}

const ArrowBackStyles = createStyles({
    backContainer: {
        height: 42,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: 0,
        left: 0,
        color: '#7A7A7A'
    },
    backIcon: {
        fontSize: 22,
        marginRight: 5
    },
    backToMovies: {
        color: '#7A7A7A',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
    }
});

function ArrowBack(props: OwnProps) {
    const { classes, linkTxt, linkUrl } = props;

    return (
        <NavLink className={classes.dashLinkBtn} to={linkUrl}>
            <div className={classes.backContainer}>
                <ArrowBackIcon className={classes.backIcon} />

                <Typography component="p" className={classes.backToMovies}>
                    {linkTxt}
                </Typography>
            </div>
        </NavLink>
    )
}

const combinedStyles = combineStyles(DashboardStyles, ArrowBackStyles);

export default withStyles(combinedStyles)(ArrowBack);