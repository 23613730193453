import axios from 'axios';
import settings from 'config'
const api = axios.create()
const protocol = settings.PROTOCOL || 'http';
const expressIP = settings.API_URL || 'localhost'
const expressPort = Number(settings.API_PORT) || 3004
const apiPath = settings.API_PATH || ''
const baseUrl = `${protocol}://${expressIP}:${expressPort}${apiPath}`;

/* Request interceptor for API calls */
// add the auth token header to requests
api.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('accessToken');
        // const { origin } = new URL(config.url !== undefined ? config.url : '');
        // const allowedOrigins = [baseUrl];

        if (accessToken) {
            config.headers.authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/* Response interceptor for API calls */
// refresh token on receiving token expired error
api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        //console.log('token expired')
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem("refreshToken");
        
        //console.log(refreshToken)
        if (refreshToken && 
            error?.response?.status === 403 &&
            !originalRequest._retry) {
            //console.log('reissuing token')
            originalRequest._retry = true;
            const res = await axios.post(`${baseUrl}/auth/refreshToken`, { refreshToken: refreshToken });

            if (res.status === 200) {
                localStorage.setItem("accessToken", res.data.accessToken);
                //console.log("Access token refreshed!");
                window.location.reload();
                return axios(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export {
    api,
    baseUrl
}