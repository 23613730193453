import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
    label: {
        fontSize: 16,
        fontWeight: 700,
        color: '#2B37EE',
        textTransform: 'uppercase',
        lineHeight: '19px',
        paddingBottom: 8,
    },
    autocompleteRoot: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    autocompleteInputAuth: {
        padding: '0px 20px !important',
        height: 48,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.2,
        borderRadius: 4,
        outline: 0,
        backgroundColor: 'white',
        border: 'none',
        '&.Mui-focused': {
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
                '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
                '0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
        '& .MuiAutocomplete-input': {
            padding: '0px !important',
            '&::placeholder': {
                color: '#7A7A7A',
                opacity: 1
            },
        },
        '& .MuiAutocomplete-endAdornment': {
            paddingRight: 8
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
    },
    autocompleteInput: {
        height: 48,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.2,
        borderRadius: 4,
        outline: 0,
        border: 'none',
        '&.Mui-focused': {
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
                '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
                '0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
        '& .MuiAutocomplete-input': {
            padding: '0 16px !important'
        },
        '& .MuiAutocomplete-endAdornment': {
            paddingRight: 8
        },
    }
});

export default useStyles;
