import React from 'react';
import axios from 'axios';

interface IProps {
	className: any,
	url: string,
	filename: string,
	children: React.ReactNode
}

export function AuthenticatedLink(props: IProps) {
	const { url, filename, children } = props;

	const handleAction = async () => {
		const accessToken = localStorage.getItem('accessToken');
		const result: any = await axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + accessToken
			}
		});

		const objectURL = window.URL.createObjectURL(new Blob([result.data]));

		const link = document.createElement('a');
		link.href = objectURL;
		link.target = '_blank';
		link.setAttribute('download', filename);

		document.body.appendChild(link);
		link.click();
		link.remove();
		window.URL.revokeObjectURL(link.href);
	}

	return (
		<>
			<div
				role="button"
				className={props.className} 
				onClick={handleAction}>
				{children}
			</div>
		</>
	)
}