import { createReducer } from '@reduxjs/toolkit'
import { Action, LicenseTemplateActions } from 'redux/actions'
import {
    commercialRightsDB
} from 'database/rightsDB'
import {
    DeliveryType,
    EducationalLicenseType,
    PeriodType
} from 'models/enums'
import {
    LicenseTemplateModel,
    PeriodModel,
    TemplateClauseModel,
    TemplateRightModel,
    TemplateSubRightModel,
    TemplateTerritoryModel
} from 'models'

const {
    updateLT,
    addRightEntryLT,
    removeRightEntryLT,
    updateClauseLT,
    removeClauseEntryLT,
    addTerritoryEntryLT,
    removeTerritoryEntryLT,
    updateTerritoryLT,
    updateExclusivityLT,
    updatePeriodLT,
    updateBlockedPeriodLT,
    removeBlockedPeriodLT,
    updateEduTypeLT,
    updateEduPeriodFormatLT
} = LicenseTemplateActions

const inicialTemplateLicense: LicenseTemplateModel = {
    licenseTemplateId: '',
    state: '', // created, disabled
    creationDate: '',
    licensor: '',
    vizziID: '',
    filmTitle: '',
    commercialRights: {
        'Exhibitions': {
            allowed: false,
            subRights: {
                'Cinematic Rights': {
                    allowed: false,
                    clauses: {
                        'Theatrical': {
                            allowed: false,
                            territories: []
                        },
                        'NonTheatrical': {
                            allowed: false,
                            territories: []
                        },
                        'Open': {
                            allowed: false,
                            territories: []
                        }
                    }
                },
                'Ancilliary Rights': {
                    allowed: false,
                    clauses: {
                        "Airline": {
                            allowed: false,
                            territories: []
                        },
                        "Hotel": {
                            allowed: false,
                            territories: []
                        },
                        "Ship": {
                            allowed: false,
                            territories: []
                        },
                        "Train": {
                            allowed: false,
                            territories: []
                        },
                        "Commercial Vehicle": {
                            allowed: false,
                            territories: []
                        }
                    }
                },
            }
        },
        'TV': {
            allowed: false,
            subRights: {
                'Distribution Channels': {
                    allowed: false,
                    clauses: {
                        'Cable': {
                            allowed: false,
                            territories: []
                        },
                        'Satellite': {
                            allowed: false,
                            territories: []
                        },
                        'Terrestrial': {
                            allowed: false,
                            territories: []
                        },
                        'Catch-Up': {
                            allowed: false,
                            territories: []
                        }
                    }
                },
                'Subscription Methods': {
                    allowed: false,
                    clauses: {
                        "Free": {
                            allowed: false,
                            territories: []
                        },
                        "Basic": {
                            allowed: false,
                            territories: []
                        },
                        "Premium": {
                            allowed: false,
                            territories: []
                        }
                    }
                },
            }
        },
        'VOD': {
            allowed: false,
            subRights: {
                'Type': {
                    allowed: false,
                    clauses: {
                        'AdVOD': {
                            allowed: false,
                            territories: []
                        },
                        'FVOD': {
                            allowed: false,
                            territories: []
                        },
                        'SVOD': {
                            allowed: false,
                            territories: []
                        },
                        'TVOD': {
                            allowed: false,
                            territories: []
                        },
                        'Internet Streaming': {
                            allowed: false,
                            territories: []
                        }
                    }
                },
            }
        }
    },
    nonCommercialRights: {
        'Educational': {
            allowed: false,
            type: EducationalLicenseType.BOTH,
            periodFormat: PeriodType.BOTH,
        }
    },
    deliveryMethods: [
        DeliveryType.DIGITAL_TAPE,
        DeliveryType.DVD
    ]
}

const subRightIsEmpty = (subRignt: TemplateSubRightModel) => {
    const clauses = Object.keys(subRignt.clauses);
    for (let i = 0; i < clauses.length; i++) {
        if (subRignt.clauses[clauses[i]].territories.length > 0)
            return false;
    }
    return true;
}

const rightIsEmpty = (rignt: TemplateRightModel) => {
    const subRights = Object.keys(rignt)
    for (let i = 0; i < subRights.length; i++) {
        const clauses = Object.keys(subRights[i])
        for (let j = 0; j < clauses.length; j++) {
            if (rignt.subRights[i].clauses[j].territories.length > 0)
                return false;
        }
    }
    return true;
}

const licenseTemplateReducer = createReducer(inicialTemplateLicense, (builder) => {
    builder
        /* License Template */
        .addCase(updateLT, (state: any, action: Action) => {
            const {
                licenseTemplateId,
                licensor,
                creationDate,
                commercialRights,
                nonCommercialRights,
                deliveryMethods,
                vizziID,
                filmTitle
            } = action.payload;
            state.licenseTemplateId = licenseTemplateId;
            state.licensor = licensor;
            state.state = action.payload.state;
            state.creationDate = creationDate;
            state.commercialRights = commercialRights;
            state.nonCommercialRights = nonCommercialRights;
            state.deliveryMethods = deliveryMethods;
            state.vizziID = vizziID;
            state.filmTitle = filmTitle;
        })

        /* Rights */
        .addCase(addRightEntryLT, (state: any, action: Action) => {
            const { rightName } = action.payload;

            if (rightName === 'Educational') {
                state.nonCommercialRights[rightName].allowed = true;
            }
        })
        .addCase(removeRightEntryLT, (state: any, action: Action) => {
            const { rightName, licenseType, operationMode } = action.payload;

            if (rightName === 'Educational') {
                const edu = state.nonCommercialRights['Educational']
                if (licenseType === 'template' && operationMode === 'create')
                    edu.allowed = false;
                edu.periodFormat = PeriodType.BOTH;
                edu.type = EducationalLicenseType.BOTH;
            }
            else {
                const subRights = Object.keys(commercialRightsDB[rightName])

                for (let i = 0; i < subRights.length; i++) {
                    const clauses = commercialRightsDB[rightName][subRights[i]];
                    for (let j = 0; j < clauses.length; j++) {
                        const clause = state.commercialRights[rightName].subRights[subRights[i]].clauses[clauses[j]];
                        clause.territories.splice(0, clause.territories.length)
                        if (licenseType === 'template' && operationMode === 'create')
                            clause.allowed = false;
                    }
                }
            }

        })

        /* Clauses */
        .addCase(updateClauseLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, licenseType, operationMode } = action.payload;

            const right = state.commercialRights[rightName];
            const subRight = right.subRights[subRightName]
            const clause = subRight.clauses[clauseName];
            if (licenseType === 'template' && operationMode === 'create') {
                right.allowed = true;
                subRight.allowed = true;
                clause.allowed = true;
            }
        })
        .addCase(removeClauseEntryLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, licenseType, operationMode } = action.payload;

            const clausesList = state.commercialRights[rightName].subRights[subRightName].clauses;
            const clause: TemplateClauseModel = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName];

            // Empty array
            clause.territories.splice(0, clause.territories.length)
            if (licenseType === 'template' && operationMode === 'create') {
                clause.allowed = false;

                let allowed = false;
                for (const clauseItem in clausesList) {
                    if (clausesList[clauseItem].allowed) {
                        allowed = true;
                    }
                }

                if (!allowed) {
                    state.commercialRights[rightName].subRights[subRightName].allowed = false;
                }

            }
        })

        /* Territories Entries */
        .addCase(addTerritoryEntryLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId } = action.payload;

            const clause: TemplateClauseModel = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName];

            const territories = clause.territories;
            territories.push({
                territoryId: territoryId,
                territoryName: '',
                exclusivityAllowed: false,
                nonExclusivityAllowed: false,
                //periodAllowedForLicensing: {},
                blockedPeriods: []
            })
        })
        /*.addCase(updateTerritoryEntryLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId } = action.payload;

            const clause: TemplateClauseModel = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName];

            const territories = clause.territories;
            territories.push({
                territoryId: territoryId,
                territoryName: '',
                exclusivityAllowed: false,
                nonExclusivityAllowed: false,
                //periodAllowedForLicensing: {},
                blockedPeriods: []
            })
        })*/
        .addCase(removeTerritoryEntryLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, licenseType, operationMode } = action.payload;

            const right: TemplateRightModel = state.commercialRights[rightName];
            const subRight: TemplateSubRightModel = right.subRights[subRightName]
            const clause: TemplateClauseModel = subRight.clauses[clauseName];
            const territories: TemplateTerritoryModel[] = clause.territories;

            if (territories.length === 1 && licenseType === 'template' && operationMode === 'create') {
                clause.allowed = false;
                // Check SUBRIGHT
                if (subRightIsEmpty(subRight)) {
                    subRight.allowed = false;
                    if (rightIsEmpty(right)) {
                        subRight.allowed = false;
                    }
                }
            }

            const terrToRemove = territories.find(
                (element: TemplateTerritoryModel) => element.territoryId === territoryId
            )
            if (terrToRemove !== undefined)
                territories.splice(territories.indexOf(terrToRemove), 1);
        })

        /* Territory Input */
        .addCase(updateTerritoryLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, territoryName } = action.payload;

            const right = state.commercialRights[rightName];
            const subRight = right.subRights[subRightName]
            const clause = subRight.clauses[clauseName];

            clause.territories.find(
                (element: TemplateTerritoryModel) => element.territoryId === territoryId
            ).territoryName = territoryName;
        })

        /* Exclusivity Input */
        .addCase(updateExclusivityLT, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId, exclusivity, nonExclusivity } = action.payload;

            const territory = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName].territories.find(
                (element: TemplateTerritoryModel) => element.territoryId === territoryId
            )

            territory.exclusivityAllowed = exclusivity;
            territory.nonExclusivityAllowed = nonExclusivity;
        })

        /* Period Allowed for Licensing Input */
        .addCase(updatePeriodLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, pal } = action.payload;
            const clause = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName];

            clause.territories.find(
                (element: TemplateTerritoryModel) => element.territoryId === territoryId
            ).periodAllowedForLicensing = pal;
        })

        /* BlockedPeriod Input */
        .addCase(updateBlockedPeriodLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, periodId, period } = action.payload;
            const right = state.commercialRights[rightName]
            let blockedPeriods: PeriodModel[];
            if (rightName === 'Educational') {
                blockedPeriods = state.nonCommercialRights[rightName].blockedPeriods
            }
            else {
                const territories = right.subRights[subRightName].clauses[clauseName].territories;
                blockedPeriods = territories.find(
                    (element: TemplateTerritoryModel) => element.territoryId === territoryId
                ).blockedPeriods;
            }

            const bp = blockedPeriods.find(
                (element: PeriodModel) => element.periodId === periodId
            )

            // Check if Period already exists
            if (bp === undefined) {
                // Does not exist yet, creating
                blockedPeriods.push({
                    periodId: periodId,
                    start: period.start,
                    end: period.end
                })
            }
            else {
                // Period exists, updating
                bp.start = period.start;
                bp.end = period.end;
            }
        })
        .addCase(removeBlockedPeriodLT, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, periodId } = action.payload;
            let blockedPeriods: PeriodModel[];
            if (rightName === 'Educational') {
                blockedPeriods = state.nonCommercialRights[rightName].blockedPeriods
            }
            else {
                const territories = state.commercialRights[rightName].subRights[subRightName].clauses[clauseName].territories;
                blockedPeriods = territories.find(
                    (element: TemplateTerritoryModel) => element.territoryId === territoryId
                ).blockedPeriods;
            }

            const bp = blockedPeriods.find(
                (element: PeriodModel) => element.periodId === periodId
            )

            // Check if Period already exists
            if (bp !== undefined) {
                blockedPeriods.splice(blockedPeriods.indexOf(bp), 1);
            }
        })

        /* Educational */
        .addCase(updateEduTypeLT, (state: any, action: Action) => {
            const { rightName, type } = action.payload;
            if (rightName === 'Educational')
                state.nonCommercialRights[rightName].type = type;
        })
        .addCase(updateEduPeriodFormatLT, (state: any, action: Action) => {
            const { rightName, periodFormat } = action.payload;
            if (rightName === 'Educational')
                state.nonCommercialRights[rightName].periodFormat = periodFormat;
        })
})

export default licenseTemplateReducer;