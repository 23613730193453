import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import AuthRouter from 'AuthRouter';
import AppRouter from 'AppRouter';
import AuthLayout from 'templates/layouts/AuthLayout';
import MainLayout from 'templates/layouts/MainLayout';
import Unsupported from 'pages/unsupported/Unsupported';

function App(props: any) {
    const history = useHistory();
    const [ isUnsupported, setIsUnsupported ] = useState(false);

    const checkScreenSize = () => {
        const screenSize = window.innerWidth;
    
        if (screenSize <= 1024) {
            setIsUnsupported(true);
        } else {
            setIsUnsupported(false);
        }
    }

    useEffect(() => {
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => {
            window.removeEventListener("resize", checkScreenSize)
        }
    }, []);

    if (isUnsupported) {
        return <Unsupported {...props} />;
    }
    return (
        <Switch>
            <Route path="/auth">
                <AuthLayout history={history}>
                    <AuthRouter history={history}/>
                </AuthLayout>
            </Route>
            <Route path="/">
                <MainLayout history={history}>
                    <AppRouter history={history}/>
                </MainLayout>
            </Route>
        </Switch>
    );
}

export default App;
