import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as NavLink } from 'react-router-dom';
import {
    Button,
    Link,
    Typography,
} from '@material-ui/core';

import AuthStyles from 'styles/auth';

interface OwnProps {
    classes?: any
}

class TermsConditions extends Component<OwnProps> {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.authFormContainer}>
                <div className={classes.termsBox}>
                    <Typography component="h1" variant="h5" className={classes.termsTitle}>
                        Terms and Conditions
                    </Typography>

                    <Typography component="p" className={classes.lastUpdate}>
                        Last updated [7 October 2021]
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Agreement to Terms
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and MAP Marketplace (“we,” “us” or “our”), concerning your access to and use of the <Link className={classes.authLink} component={NavLink} to="/">https://map-marketplace.mog-technologies.com/makefilmhistory</Link> website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        The services provided on the Site, including the licensing of media assets through the use of smart contracts recorded on a blockchain spine, are not intended for use in any jurisdiction or country where their use is prohibited by law or subject to registration requirements, or where contracts for the licensing of copyright assets are subject to legal requirements that exclude the valid conclusion of electronic contracts or require registration or other formalities.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Intellectual Property Rights
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trade marks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trade mark laws and various other intellectual property rights and unfair competition laws of England and Wales, foreign jurisdictions, and international conventions.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms and Conditions, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Copyright Infringement
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        The user-uploaded content available on the Site, including media assets (trailers, previews and other video files) and licensing agreements, may be protected by copyright and/or other applicable intellectual property rights. Users uploading such content represent and warrant that they own the copyright or other relevant intellectual property rights on the uploaded content, that they are not under any obligation, contractual or of other nature, preventing the upload and making available of such materials, or that they have concluded a suitable licensing agreement permitting the upload of such content to our Site.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        If you identify any content on our Site that appears to infringe your copyright or other intellectual property rights, or contractual rights, or those of third parties, please contact us immediately at <Link className={classes.authLink} href="mailto:stevew@metfilm.co.uk">stevew@metfilm.co.uk</Link>. Upon notification and verification, we will proceed to expeditiously remove the allegedly infringing content from our Site.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        User Representations
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        By using the Site, you represent and warrant that:
                    </Typography>

                    <ol className={classes.termsList}>
                        <li>
                            all registration information you submit will be true, accurate, current, and complete;
                        </li>
                        <li>
                            you will maintain the accuracy of such information and promptly update such registration information as necessary;
                        </li>
                        <li>
                            you have the legal capacity and you agree to comply with these Terms and Conditions;
                        </li>
                        <li>
                            you are not under the age of 18;
                        </li>
                        <li>
                            you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;
                        </li>

                        <li>
                            you will not use the Site for any illegal or unauthorized purpose;
                        </li>

                        <li>
                            your use of the Site will not violate any applicable law or regulation;
                        </li>

                        <li>
                            you are an authorized legal representative of your business or academic institution and have the legal capacity to enter into contracts for the licensing of media assets;
                        </li>

                        <li>
                            you own the copyright or other relevant intellectual property rights on the content you upload to the Site (including lists of films, information pertaining to uploaded films, video files of any descriptions, additional files - e.g. sound files or text files, licensing agreement templates, licensing agreements, and any other user-submitted content) and that you are not under any obligation, contractual or of other nature, preventing the upload and making available of such materials, or that you have concluded a suitable licensing agreement permitting the upload of such content to our Site;
                        </li>

                        <li>
                            the content you offer for licensing is not subject to the rights of third parties, except where such rights are non-exclusive in nature and permit the further licensing of such content without restrictions;
                        </li>

                        <li>
                            you intend to be bound by the licensing agreements concluded through the platform and expressly waive the right to dispute the validity of such licensing agreements, in relation to their codification as smart contracts and their recording on the blockchain spine;
                        </li>

                        <li>
                            you are using the website for business purposes and are not acting in a personal capacity as a consumer.
                        </li>
                    </ol>

                    <Typography component="p" className={classes.termsTxt}>
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        User Registration
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You will be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Prohibited Activities
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        As a user of the Site, you agree not to:
                    </Typography>

                    <ol className={classes.termsList}>
                        <li>
                            systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
                        </li>
                        <li>
                            make any unauthorised use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
                        </li>
                        <li>
                            use a buying agent or purchasing agent to make purchases on the Site.
                        </li>
                        <li>
                            circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.
                        </li>
                        <li>
                            engage in unauthorized framing of or linking to the Site.
                        </li>

                        <li>
                            trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;
                        </li>

                        <li>
                            make improper use of our support services or submit false reports of abuse or misconduct.
                        </li>

                        <li>
                            engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
                        </li>

                        <li>
                            interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.
                        </li>

                        <li>
                            attempt to impersonate another user or person or use the username of another user.
                        </li>

                        <li>
                            sell or otherwise transfer your profile.
                        </li>

                        <li>
                            use any information obtained from the Site in order to harass, abuse, or harm another person.
                        </li>

                        <li>
                            use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.
                        </li>
                        <li>
                            decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.
                        </li>
                        <li>
                            attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.
                        </li>
                        <li>
                            harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.
                        </li>
                        <li>
                            delete the copyright or other proprietary rights notice from any Content.
                        </li>

                        <li>
                            copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
                        </li>

                        <li>
                            upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.
                        </li>

                        <li>
                            upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
                        </li>

                        <li>
                            except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.
                        </li>

                        <li>
                            disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
                        </li>

                        <li>
                            use the Site in a manner inconsistent with any applicable laws or regulations.
                        </li>
                    </ol>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Submissions
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (&quot;Submissions&quot;) provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Third-party Websites and Content
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        The Site may contain (or you may be sent via the Site) links to other websites (&quot;Third-Party Websites&quot;) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (&quot;Third-Party Content&quot;).
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms and Conditions no longer govern.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Site or relating to any applications you use or install from the Site. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Site Management
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We reserve the right, but not the obligation, to:
                    </Typography>

                    <ol className={classes.termsList}>
                        <li>
                            monitor the Site for violations of these Terms and Conditions;
                        </li>

                        <li>
                            take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;
                        </li>
                        <li>
                            in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
                        </li>

                        <li>
                            in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;
                        </li>

                        <li>
                            otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
                        </li>
                    </ol>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Privacy Policy
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We care about data privacy and security. Please review our <Link className={classes.authLink} component={NavLink} to="/auth/privacyPolicy">Privacy Policy</Link> regarding our user data collection. It will help you better understand our practices. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms and Conditions.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Term And Termination
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        These Terms and Conditions shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Modifications And Interruptions
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We will not be liable to you or any third party for any modification, suspension, or discontinuance of the Site.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you.You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Governing Law
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        These Terms and Conditions and your use of the Site or any dispute of any sort that might come between MAP Marketplace and you, or its business partners and associates are governed by and construed in accordance with the laws of England and Wales without regard to principles of conflict laws. The courts of England and Wales have exclusive jurisdiction on any dispute arising between MAP Marketplace and you in relation to any controversies pertaining to our Site and the.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Corrections
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Site, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
                    </Typography>


                    <Typography component="h2" className={classes.termsSubtitle}>
                        Disclaimer
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Limitations of Liability
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Indemnification
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable legal fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Site; (3) breach of these Terms and Conditions; (4) any breach of your representations and warranties set forth in these Terms and Conditions; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with whom you connected via the Site.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims.We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Electronic Communications, Transactions, and Signatures
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        Visiting the Site, sending us emails, and completing online forms constitute electronic communications.You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Miscellaneous
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        These Terms and Conditions and any policies or operating rules posted by us on the Site constitute the entire agreement and understanding between you and us.Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        These Terms and Conditions operate to the fullest extent permissible by law.We may assign any or all of our rights and obligations to others at any time.We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site.You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the parties hereto to execute these Terms and Conditions.
                    </Typography>

                    <Typography component="h2" className={classes.termsSubtitle}>
                        Contact Us
                    </Typography>

                    <Typography component="p" className={classes.termsTxt}>
                        In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
                    </Typography>

                    <Typography component="p" className={classes.termsContact}>
                        Met Film Production Ltd
                    </Typography>

                    <Typography component="p" className={classes.termsContact}>
                        Ealing Studios<br/>
                        Ealing Green<br/>
                        London<br/>
                        W5 5EP<br/>
                        United Kingdom<br/>
                    </Typography>

                    <Typography component="p" className={classes.termsContact}>
                        44 (0) 20 8280 9127
                    </Typography>

                    <Typography component="p" className={classes.termsContact}>
                        <Link 
                            className={classes.authLink} 
                            href="mailto:assistant@metfilm.co.uk"
                        >
                            assistant@metfilm.co.uk
                        </Link>
                    </Typography>

                    <NavLink className={classes.termsLinkBtn} to="/">
                        <Button className={classes.termsBtn} variant="outlined">
                            Back to Home Page
                        </Button>
                    </NavLink>

                </div>
            </div>
        );
    }
}

export default withStyles(AuthStyles as any)(TermsConditions);