import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import { GenreModel } from 'models';

interface IGenresProps {
    genres: GenreModel[]
}

const useStyles = makeStyles({
    genresContainer: {
        marginTop: 10,
        marginBottom: 10
    },
    buttonGroup: {
        display: 'grid',
        gridGap: 25,
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))'
    },
    toggleButton: {
        height: 40,
        marginLeft: 0,
        borderRadius: '10px !important',
        borderLeft: 'none',
        border: 'none',
        color: '#7A7A7A',
        fontWeight: 400,
        backgroundColor: 'white',
        textTransform: 'capitalize',
        fontSize: 16,
        paddingLeft: 15,
        paddingRight: 15,
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5)'
        }
    },
    toggleButtonSelected: {
        color: '#000000 !important'
    },
    description: {
        fontSize: 16,
        color: 'black',
        height: 40,
        display: 'flex',
        alignItems: 'center'
    }
});

export default function ToggleButtonsMultiple(props: IGenresProps) {
    const classes = useStyles();
    const { genres } = props;
    const selectedCategories = genres.filter(genre => genre.selected === true);
    const selected = selectedCategories.map((genre) => genre.name);

    const [formats, setFormats] = React.useState(() => selected);

    const handleFormat = (event: any, newFormats: any) => {
        setFormats(newFormats);
    };

    return (
        <div>
            {selectedCategories.length === 0 ?
                <span className={classes.description}>
                    No categories selected
                </span> :

                <div className={classes.genresContainer}>
                    <ToggleButtonGroup className={classes.buttonGroup} value={formats} onChange={handleFormat}>
                        {selectedCategories.map((genre: GenreModel, index: number) => {
                            return (
                                <ToggleButton
                                    classes={{
                                        root: classes.toggleButton,
                                        selected: classes.toggleButtonSelected
                                    }}
                                    key={index}
                                    value={genre.name}
                                    disabled={true}
                                >
                                    {genre.name}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </div>}
        </div>
    );
}