import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function MScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollOptions: ScrollToOptions = {
        top: 0
    };

    scrollOptions.behavior = "smooth";
    window.scrollTo(scrollOptions);
  }, [pathname]);

  return null;
}