import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import LogoImg from 'assets/logo/logo-img.png';
import LogoTxt from 'assets/logo/logo-txt.png';
import LoginImage from 'assets/images/login-illustration.png';

import './AuthSplash.css';

class AuthSplash extends Component {
    render() {
        return (
            <>
                <div className="auth-splash-container">
                    <div className="splash-bkg">
                        <div className="auth-logo">

                            <NavLink to="/">
                                <div className="logo-container">
                                    <img className="logo-img" src={LogoImg} alt="Project Logo" />
                                    <img src={LogoTxt} alt="Project Logo Txt" />
                                </div>
                            </NavLink>
                        </div>
                        <img className="auth-image" src={LoginImage} alt="Login Illustration" />
                        <div className="copyright-disclaimer">
                            <p>Copyright © MAP project 2021.</p>
                        </div>
                    </div>
                </div>

                <div className="auth-splash-hidden">
                    <div className="splash-bkg">
                        <img className="auth-image" src={LoginImage} alt="Login Illustration" />
                    </div>
                </div>
            </>
        );
    }
}

export default AuthSplash;