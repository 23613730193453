import React from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';

import TextInput from 'components/controls/textInput/TextInput';
import combineStyles from 'utils/combineStyles';
import { UserModel } from 'models';
import { RootState } from 'redux/store';
import { changePassword } from 'services/userService';
import {
    cleanseInput,
    validateInput,
    validateConfirmPassword,
    validateNewPassword
} from 'utils/validation';
import { normalizeMessage } from 'utils/utils';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Styles from 'styles/auth';
import DashStyles from 'styles/dashboard';

interface OwnProps {
    classes?: any,
    history: any
}

interface StateProps {
    user: UserModel
}

type Props = StateProps & OwnProps

interface State {
    form: {
        password: string,
        confirmPassword: string
    },
    errors: {
        passwordError: string,
        confirmPasswordError: string,
    },
    showPassword: boolean,
    showConfirmPassword: boolean,
    formErrorMessage: string,
    formSuccessMessage: string
}

class Profile extends React.Component<Props, State> {
    state = {
        form: {
            password: '',
            confirmPassword: '',
        },
        errors: {
            passwordError: '',
            confirmPasswordError: ''
        },
        showPassword: false,
        showConfirmPassword: false,
        formSuccessMessage: '',
        formErrorMessage: ''
    };

    handleChange = (event: any) => {
        // Cleanse input
        const value = event.target.name === 'username' || event.target.name === 'email' ?
            cleanseInput(event.target.value, true) : cleanseInput(event.target.value);

        // Update form state
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [event.target.name]: value
            }
        }))
        this.setInputStateError(`${event.target.name}Error`, '')
    }

    handleClickShowConfirmPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            showConfirmPassword: !this.state.showConfirmPassword
        }))
    };

    handleClickShowPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            showPassword: !this.state.showPassword
        }))
    };

    setInputStateError = (errorName: string, errorValue: string) => {
        this.setState(prevState => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                [errorName]: errorValue
            }
        }))
    }

    inputStyle = (error: string) => {
        const { classes } = this.props;
        const getelement = (errors: any, error: string) => {
            return errors[error]
        }
        const errors: any = getelement(this.state.errors, error);
        return errors === '' || errors === 'undefined' ?
            classes.inputTextfield :
            classes.inputTextfieldError;
    }

    onBlurHandle = async (name: string) => {
        // Validate
        const errorName = `${name}Error`;
        let errorValue = '';
        const getelement = (form: any, name: string) => {
            return form[name]
        }

        const inputValue = getelement(this.state.form, name);

        if (!(inputValue === '' || typeof inputValue === 'undefined')) { // If input is empty do nothing
            if (name === 'confirmPassword')
                errorValue = validateConfirmPassword(this.state.form.password, inputValue);
            else errorValue = validateInput(name, inputValue);
        }

        // Update new Error value
        this.setInputStateError(errorName, errorValue)
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();

        // validate form
        const validation = validateNewPassword(this.state.form);
        let formErrorMessage = '';
        let formSuccessMessage = ''

        if (validation === ' ') {
            console.log("Inputs Valid", this.state.form);

            await changePassword(this.state.form.password).then(
                (value) => { // On run
                    console.log('User account updated')
                    console.log(value)
                    formSuccessMessage = value.message;
                },
                (reason) => { // on fail
                    console.log('Registration failed')
                    console.log(reason)
                    formErrorMessage = 'Cannot submit form with errors.'
                    //put error on username or email

                });
        } else formErrorMessage = normalizeMessage(validation) + ' is invalid!';

        //Form error
        this.setState(prevState => ({
            ...prevState,
            form: {
                password: '',
                confirmPassword: ''
            },
            formSuccessMessage: formSuccessMessage,
            formErrorMessage: formErrorMessage
        }))
    }

    render() {
        const { classes, user } = this.props;

        return (
            <React.Fragment >
                <div className={classes.headerContainer}>
                    <Typography component="h4" className={classes.dashboardTitle}>
                        Profile
                    </Typography>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <Grid container>
                        <Grid
                            className={classes.avatarContainer}
                            item container xs={4} lg={3} xl={3}
                        >
                            <Avatar
                                className={classes.avatar}
                                color={'#2B37EE'}
                                name={`${user.firstname} ${user.lastname}`}
                                round="50%"
                                size="200"
                            />
                        </Grid>
                        <Grid item container xs={12} md={10} lg={9} xl={7} spacing={3}>
                            <Grid 
                                className={classes.formErrorContainer} 
                                item 
                                sm={12}
                            >
                                <Typography 
                                    className={classes.authFeedback} 
                                    component="h1" 
                                    variant="h6" 
                                    style={{ color: 'red', display: this.state.formErrorMessage ? 'block' : 'none' }}
                                >
                                    {this.state.formErrorMessage}
                                </Typography>

                                <Typography 
                                    className={classes.authFeedback} 
                                    component="h1" 
                                    variant="h6" 
                                    style={{ color: 'green', display: this.state.formSuccessMessage ? 'block' : 'none' }}
                                >
                                    {this.state.formSuccessMessage}
                                </Typography>
                            </Grid>

                            <Grid item sm={6} >
                                <TextInput
                                    type="text"
                                    label="First Name"
                                    placeholder="Enter your first name"
                                    value={user.firstname}
                                    disabled={true}
                                    required={true}
                                    autoComplete="given-name"
                                />
                            </Grid>

                            <Grid item sm={6} >
                                <TextInput
                                    type="text"
                                    label="Last Name"
                                    placeholder="Enter your last name"
                                    value={user.lastname}
                                    disabled={true}
                                    required={true}
                                    autoComplete="family-name"
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <TextInput
                                    type="text"
                                    label="Username"
                                    placeholder="Enter your username"
                                    value={user.username}
                                    disabled={true}
                                    required={true}
                                    autoComplete="username"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    type="text"
                                    label="Email Address"
                                    placeholder="Enter your email address"
                                    value={user.email}
                                    disabled={true}
                                    required={true}
                                    autoComplete="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextInput
                                    id="password"
                                    className={`${this.inputStyle('passwordError')}`}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password"
                                    name="password"
                                    placeholder="Enter your new password"
                                    value={this.state.form.password}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="current-password"
                                    onBlur={() => this.onBlurHandle('password')}
                                    helperText={this.state.errors.passwordError}
                                    error={this.state.errors.passwordError !== ''}
                                    endAdornment={
                                        <InputAdornment
                                            classes={{ positionEnd: classes.positionEndRegister }}
                                            position="end"
                                        >
                                            <IconButton
                                                classes={{ root: classes.iconButton }}
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    id="confirmPassword"
                                    className={`${this.inputStyle('confirmPasswordError')}`}
                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    placeholder="Confirm your password"
                                    value={this.state.form.confirmPassword}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="current-password"
                                    onBlur={() => this.onBlurHandle('confirmPassword')}
                                    helperText={this.state.errors.confirmPasswordError}
                                    error={this.state.errors.confirmPasswordError !== ''}
                                    fullWidth
                                    endAdornment={
                                        <InputAdornment
                                            classes={{ positionEnd: classes.positionEndRegister }}
                                            position="end"
                                        >
                                            <IconButton
                                                classes={{ root: classes.iconButton }}
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.buttonsContainer}>
                                    <Button
                                        className={classes.dashboardButton}
                                        type="submit"
                                        variant="outlined"
                                    >
                                        <span className="btn-txt">Save</span>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const combinedStyles = combineStyles(DashStyles, Styles);

export default connect(mapStateToProps)(withStyles(combinedStyles)(Profile));