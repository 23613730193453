import React, { Component } from 'react';
import { Input, FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';

const useStyles = createStyles({
    inputContainer: {
        display: 'flex',
        width: '100%',
        padding: '14px 20px',
        background: '#fbfbfb',
        fontSize: 16,
        color: 'black',
        lineHeight: '30px',
        borderRadius: 5,
        '-webkit-transition': 'border 500ms ease-out',
        '-moz-transition': 'border 500ms ease-out',
        '-o-transition': 'border 500ms ease-out',
        'transition': 'border 500ms ease-out',
        '&.Mui-error': {
            border: '1px solid red',
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5)'
        }
    },
    inputTxt: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 'unset',
        '&::placeholder': {
            color: '#7A7A7A',
            opacity: 1
        },
        '&:-webkit-autofill': {
            '-webkit-box-shadow': ' 0 0 0 30px white inset'
        },
        '&:-webkit-autofill:hover': {
            '-webkit-box-shadow': ' 0 0 0 30px white inset'
        },
        '&:-webkit-autofill:focus': {
            '-webkit-box-shadow': ' 0 0 0 30px white inset'
        }
    },
    focused: {
        boxShadow: '2px 2px #ccc !important',
        '-moz-box-shadow': '2px 2px #ccc !important',
        '-webkit-box-shadow': '2px 2px #ccc !important'
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        color: '#2B37EE',
        textTransform: 'uppercase',
        lineHeight: '19px',
        paddingBottom: 8,
        '&.Mui-focused': {
            color: '#2B37EE !important',
        },
        '&.Mui-error': {
            color: '#2B37EE',
        }
    },
});

class TextArea extends Component<any> {
    render() {
        const {
            id, classes,
            type, name, value, setInputValue, placeholder, disabled, required,
            label, error, helperText, ...props
        } = this.props;

        const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
        const inputLabelId = label && id ? `${id}-label` : undefined;

        const setInputFunction = (value: any) => {
            if (setInputValue) { setInputValue(value) }
        }

        return (
            <FormControl className="form-input" error={error} fullWidth>
                <FormLabel
                    htmlFor={id}
                    id={inputLabelId}
                    classes={{ root: classes.label }}
                    component="legend"
                >
                    {label}
                </FormLabel>

                <Input
                    id={id}
                    classes={{
                        root: classes.inputContainer,
                        input: classes.inputTxt,
                        focused: classes.focused
                    }}
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    disableUnderline
                    fullWidth
                    disabled={disabled}
                    required={required ? true : false}
                    onChange={(e: any) => setInputFunction(e)}
                    {...props}
                />

                {helperText && (
                    <FormHelperText id={helperTextId}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(useStyles as any)(TextArea);