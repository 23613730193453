import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ArrowBack from 'components/arrowback/ArrowBack';
import ProductItem from 'pages/products/ItemProduct';
import Loading from 'components/loading/Loading';
import combineStyles from 'utils/combineStyles';
import { ProductModel, UserModel } from 'models';
import { SearchActions } from 'redux/actions';
import { bindActionCreators } from 'redux';
import { cleanseInput } from 'utils/validation';
import { getAllProducts } from 'services/productService';
import { isSeller } from 'services/authService';

import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

interface OwnProps {
    classes?: any,
    history: any,
    location: any
}

interface State {
    searchResults: ProductModel[],
    loading: boolean
}

interface StateProps {
    user: UserModel,
    searchInput: string,
    setSearchInput: any
}

type Props = StateProps & OwnProps

class Search extends React.Component<Props, State> {
    state = {
        input: '',
        searchResults: [],
        loading: true
    };

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const input = params.get('input') as string;

        if (!this.props.searchInput && !input) {
            this.props.history.push('/catalogue');
            return;
        }

        if (this.props.searchInput) {
            this.getListProducts(this.props.searchInput);
        } else this.props.setSearchInput(input);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.searchInput !== this.props.searchInput) {
            this.setState({ loading: true });
            this.getListProducts(this.props.searchInput);
        }
    }

    getListProducts(searchInput: string) {
        getAllProducts().then(
            (value) => { // On run
                console.log(value);
                const result = this.getFilteredResults(value.products, searchInput);
                this.setState({
                    searchResults: result,
                    loading: false
                });
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    searchAlgorithm(title: string, searchInput: string) {
        const input = cleanseInput(searchInput).toLowerCase();
        // const inputList = input.split(/[ ,]+/);
        let searchResult = false;

        if (title.includes(input)) { searchResult = true; }

        return searchResult;
    }

    getFilteredResults(products: ProductModel[], searchInput: string) {
        const filteredResults = [];
        for (let i = 0; i < products.length; i++) {
            const productTitle = cleanseInput(products[i].title).toLowerCase();
            if (this.searchAlgorithm(productTitle, searchInput)) {
                filteredResults.push(products[i]);
            }
        }

        return filteredResults;
    }

    SearchHeader = () => {
        const { classes } = this.props;

        return (
            <div className={classes.searchHeader}>
                <ArrowBack
                    linkTxt={isSeller(this.props.user) ? "Back to My Collections" : "Back to Collections"}
                    linkUrl="/catalogue"
                />

                <Typography component="h4" className={classes.searchResults}>
                    Showing results for <span className={classes.searchHighlight}>{this.props.searchInput}</span>
                </Typography>
            </div>

        );
    }
    render() {
        const { classes } = this.props;

        const SearchLoading = (<>
            <this.SearchHeader />
            <Loading />
        </>);

        return (
            <Suspense fallback={SearchLoading}>
                <this.SearchHeader />

                {this.state.loading ?
                    <Loading /> :
                    <>
                        {this.state.searchResults.length === 0 ?
                            <Typography className={classes.noProducts}>No products found! </Typography> : (
                                <Grid container spacing={6}>
                                    {this.state.searchResults.map((product: ProductModel, index: any) => (
                                        <Grid key={product._id} item xs={3}>
                                            <NavLink className={classes.productLink} to={'/movies/' + product._id} >
                                            <ProductItem poster={product.poster} title={product.title} director={product.director} movie={true} />
                                            </NavLink>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                    </>
                }
            </Suspense>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    searchInput: state.searchInputReducer
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setSearchInput: SearchActions.setSearchInput,
    }, dispatch)
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(Search));