import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import DropdownOptions from 'templates/dropdown/DropdownOptions';
import { UserActions } from 'redux/actions'
import { UserModel } from 'models';
import { bindActionCreators } from 'redux';

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Dropdown.css';

const ACTIVE_CLASS = 'show';
const INACTIVE_CLASS = '';

interface DropdownProps {
  user: UserModel;
}

const useStyles = makeStyles({
  userIcon: {
    color: '#2B37EE',
    fontSize: '38px',
    verticalAlign: 'middle',
    marginRight: 8
  },
  avatar: {
    marginRight: 8,
    letterSpacing: '0.5px'
  },
  expandIcon: {
    fontSize: 24,
    verticalAlign: 'middle',
    marginLeft: 5
  }
});

function Dropdown(props: DropdownProps) {
  const classes = useStyles();

  const [isToggled, setToggle] = useState(false);
  const toggleMenuClass = (isToggled ? ACTIVE_CLASS : INACTIVE_CLASS);

  const { user } = props;

  return (
    <div className="user-dropdown">
      <Typography
        className="dropdown-toggle"
        component="div"
        onClick={() => {
          setToggle(!isToggled)
        }}
      >
        <div className="user-icon">
          <Avatar
            className={classes.avatar}
            color="#2B37EE"
            name={`${user.firstname} ${user.lastname}`}
            round="50%"
            size="40"
          />
        </div>
        <span className="navbar-username">
          {`${user.firstname} ${user.lastname}`}
        </span>

        <span className="expand-more">
          <ExpandMoreIcon
            classes={{ root: classes.expandIcon }}
            fontSize="inherit"
          />
        </span>
      </Typography>

      <div className={`dropdown-menu dropdown-menu-right ${toggleMenuClass}`}>
        <DropdownOptions setToggle={setToggle} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateUser: UserActions.updateUser }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);