enum EducationalLicenseType {
    COURSE = 'Course/students',
    PPR = 'Public Performance Rights',
    BOTH = 'Both Educational License Types'
}

enum PeriodType {
    ONE_YEAR = 'One Year',
    THREE_YEAR = 'Three Years',
    BOTH = 'Both Period Formats'
}

enum DeliveryType {
    DVD = 'DVD',
    DIGITAL_TAPE = 'Digital Tape'
}

type IDuration = {
    [key: string]: {
        label: string,
        minValue: number,
        maxValue?: number
    }
}

const FilterOptions = {
    GENRES: 'genres',
    DURATION: 'duration',
    YEAR: 'year',
    SELLERS: 'sellers'
};

const SliderActions = {
    CHANGE: 'change',
    COMMIT: 'commit',
    RESET: 'reset'
};

const DurationValues: IDuration = {
    'Short': {
        label: 'Short (< 60 minutes)',
        minValue: 0,
        maxValue: 60,
    },
    'Medium': {
        label: 'Medium (60-90 minutes)',
        minValue: 60,
        maxValue: 90
    },
    'Long': {
        label: 'Long (> 90 minutes)',
        minValue: 90
    }
};

export {
    EducationalLicenseType,
    PeriodType,
    DeliveryType,
    FilterOptions,
    SliderActions,
    DurationValues
}