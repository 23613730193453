import utf8 from 'utf8';
import isUtf8 from 'is-utf8';
import { OrgModel } from 'models';

const regex = {
    name: /^(?!.*[!"#$%&()*+,./:;<=>?@[\\\]^_`{|}~])(?!.*\d).+$/,
    username: /^(?!\.)(?!.*?\.\.)(?!.*\.$)[a-z][a-z\d._]{7,63}$/,
    password: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).{8,64}/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    telephone: /[\w+]+/
}

const validateForm = (form: {
    firstName: string,
    lastName: string,
    username: string,
    password: string,
    email: string,
    confirmPassword: string,
    institution: OrgModel
}): string => {
    // Run all validators and role must have a value

    // First/last names
    if (!regex.name.test(form.firstName))
        return 'firstName';
    if (!regex.name.test(form.lastName))
        return 'lastName';

    // Username
    if (!regex.username.test(form.username))
        return 'username';

    // Password
    if (!regex.password.test(form.password))
        return 'password';

    // Email
    if (!regex.email.test(form.email))
        return 'email';

    // Confirme Password
    if (validateConfirmPassword(form.password, form.confirmPassword) !== '')
        return 'confirmPassword';

    // Institution
    if (!form.institution || !form.institution?.orgid)
        return 'institution';

    return ' ';
}

const validateNewPassword = (form: {
    password: string,
    confirmPassword: string
}): string => {
    // Password
    if (!regex.password.test(form.password))
        return 'password';
    // Confirme Password
    
    if (validateConfirmPassword(form.password, form.confirmPassword) !== '')
        return 'confirmPassword';

    return ' ';
}

const validateInput = (name: string, value: string): string => {
    let error = '';
    switch (name) {
        case 'username':
            error = validateUsername(value);
            break;
        case 'email':
            error = validateEmail(value);
            break;
        case 'firstName' || 'lastName':
            error = validateName(value, name);
            break;
        case 'password':
            error = validatePassword(value);
            break;
        case 'role':
            error = validateRole(value);
            break;
    }
    return error;
}

const validatePassword = (password: string) => {

    if (!/.{8,64}/.test(password)) {
        return "Password size must be between 8 and 64 characters.";
    }
    if (!/(?=.*\d).+/.test(password)) {
        return "Password must have at least one number.";
    }
    if (!/(?=.*[a-z]).+/.test(password)) {
        return "Password must have at least one lowercase letter.";
    }
    if (!/(?=.*[A-Z]).+/.test(password)) {
        return "Password must have at least one uppercase letter.";
    }
    if (!/(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).+/.test(password)) {
        return "Password must have at least one special character.";
    }

    return '';
}

const validateUsername = (username: string, isEmailLocalPart = false) => {
    let name = 'Username';
    if (isEmailLocalPart)
        name = 'Email local part';

    if (!/.{8,64}/.test(username))
        return `${name} size must be between 8 and 64 characters.`;

    if (!/[a-z\d._]+/.test(username))
        return `${name} can only have letters, numbers, dots or underscore.`;

    if (!/^[a-z].+/.test(username))
        return `${name} first character must be a letter.`;

    if (!/^(?!.*?\.\.).+$/.test(username))
        return `${name} cannot have consecutive dots.`;

    if (!/^(?!\.).+/.test(username))
        return `${name} cannot have dots at start.`;

    if (!/(?!.*\.$).+$/.test(username))
        return `${name} cannot have dots at end.`;

    return '';
}

const validateEmail = (email: string) => {
    if (!/^.+@.+$/.test(email))
        return "Email must contain an @ separating local part from domain name.";

    if (!/^(?!.*@.*@.*).+$/.test(email))
        return "Email must contain one and only one @, separating local part from domain name.";

    if (!/^.{1,63}@.+$/.test(email))
        return "Email local part size must be between 1 and 63 characters.";

    if (!/^.+@.{1,255}$/.test(email))
        return "Email domain name size must be between 1 and 255 characters.";

    // // - The first part has the same treatment as the username
    // const localPartValidation = validateUsername(email.substring(0, email.indexOf('@') + 1), true);

    // if (localPartValidation !== '')
    //     return localPartValidation;

    // - The domain part contains only letters, numbers, hyphens (-) and periods (.).
    if (!/.+@[a-z\d.-]+/.test(email))
        return "Email domain part contains only letters, numbers, hyphens (-) and periods (.).";

    if (!/@(?!-+).+/.test(email))
        return `Email domain part cannot have hyphens at start.`;

    if (!/(?!.*-+$).+$/.test(email))
        return `Email domain part cannot have hyphens at end.`;

    return ''
}

const validateName = (name: string, type: string) => {
    if (!/(?!.*[!"#$%&()*+,./:;<=>?@[\\\]^_`{|}~]).+/.test(name)) {
        return `${type} cannot have special characters such as !"#$%&()*+,./:;<=>?@[\\]^_\`{|}~]`;
    }
    if (!/(?!.*\d).+/.test(name)) {
        return `${type} cannot have numbers.`;
    }
    return ''
}

const validateConfirmPassword = (password: string, confirmPassword: string): string => {
    if (confirmPassword !== password)
        return "Your password and confirmation password do not match."
    return ''
}

const validateRole = (role: string) => {
    if (!(role === 'seller' || role === 'buyer'))
        return 'Role must be selected.';
    return '';
}

/**
 * Cleanse Input through many cleanng processes
 * @param {*} input 
 * @param {*} toLowercase 
 * @returns 
 */
const cleanseInput = (input: any, toLowercase = false): string => {
    /* Input Canonicalization */
    // Convert to specific format (canonical form). 
    // Normally transforms special caracters to hexadecimal values

    let newInput = String(input);
    if (!isUtf8(Buffer.from(newInput)))
        newInput = utf8.encode(newInput);

    /* Input Normalization */
    // transform equivalent unicode sequences of code points into a cannonically equivalent string
    //https://devdocs.io/javascript/global_objects/string/normalize
    newInput = newInput.normalize('NFC');

    /* Input Sanitization */
    // At the db level, is used prepared statements to prevent sql injection
    // jsx sanitizes values before rendering them automatically
    // "By default, React DOM escapes any values embedded in JSX before rendering them. Thus it ensures that you can never inject anything that's not explicitly written in your application. Everything is converted to a string before being rendered. This helps prevent XSS (cross-site-scripting) attacks."
    // Trim to clean whitespaces at start and end
    // convert to lowercase: username, email
    newInput = newInput.trim();
    newInput = toLowercase ? newInput.toLowerCase() : newInput;

    return newInput;
}

const dateToString = (date: Date | string): string => {
    if (date instanceof Date)
        return date.toString()
    else return date;
}

export {
    cleanseInput,
    dateToString,
    validateForm,
    validateInput,
    validateConfirmPassword,
    validateNewPassword,
    regex
}