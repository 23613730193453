import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import checkboxFilled from "assets/images/checkbox-filled.svg";
import checkboxEmpty from "assets/images/checkbox-empty.svg";

interface MCheckboxProps {
    name: string;
    label: string;
    value: any;
    checkedValues: any[];
    handleSelect: any;
}

const useStyles = makeStyles({
    controlLabel: {
      marginRight: 0,
      marginLeft: -6,
      flex: "0 1 auto"
    },
    label: {
      fontSize: 13,
      letterSpacing: "0.02em",
      fontWeight: 400,
    }
  });
  
  
export default function CheckboxField(props: MCheckboxProps) {
    const classes = useStyles();

    const filledCheckbox = (<img src={checkboxFilled} alt="Selected checkbox" />);
    const emptyCheckbox = (<img src={checkboxEmpty} alt="Deselected checkbox" />);

    return (
        <FormControlLabel
            classes={{ root: classes.controlLabel, label: classes.label }}
            label={props.label}
            control={
                <Checkbox
                    icon={emptyCheckbox}
                    checkedIcon={filledCheckbox}
                    name={props.name}
                    value={props.value}
                    checked={props.checkedValues.includes(props.value)}
                    onChange={() => props.handleSelect(props.value)}
                />
            }
        />


    );
}