import React from 'react';
import { withStyles } from '@material-ui/styles';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import MapLogo from 'assets/logo/logo.png';
import UStyles from './UStyles';

interface Props {
    classes?: any,
    history: any,
    location: any
}

class Unsupported extends React.Component<Props> {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.pageContainer}>
                <img className={classes.mapLogo} src={MapLogo} alt="MAP Marketplace logo" />
                <div className={classes.unsupportedContent}>
                    <InfoRoundedIcon className={classes.unsupportedIcon} />
                    
                    <span className={classes.unsupportedTxt}>
                        This screen resolution is not supported
                    </span>

                    <span className={classes.unsupportedDescription}>
                        Sorry, MAP Marketplace is not supported on this device. <br className={classes.divisor}/>
                        Give it a try on your desktop computer instead.
                    </span>
                </div>
            </div>
        )
    }
}


export default withStyles(UStyles)(Unsupported);