import { Theme } from '@material-ui/core/styles/createMuiTheme';

function combineStyles(...styles: any) {
    return function CombineStyles(theme: Theme) {
        const outStyles = styles.map((arg: any) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme);
            }
            // Objects need no change.
            return arg;
        });

        return outStyles.reduce((acc: number, val: any) => Object.assign(acc, val));
    };
}

export default combineStyles;