import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoginPage from 'pages/auth/login/LoginPage';
import RegisterPage from 'pages/auth/register/RegisterPage';
import VerificationPage from 'pages/auth/verification/VerificationPage';
import TermsConditions from 'pages/auth/TermsConditions';
import PrivacyPolicy from 'pages/auth/PrivacyPolicy';
import { isLoggedIn } from 'services/authService';

const AuthRouter = (props: any) => {
    /*if (isLoggedIn()) {
        return <Redirect to={{ pathname: "/catalogue" }} />;
    }*/

    return (
        <Switch>
            <Route path="/auth/login" render={(props) => (
                isLoggedIn() ? (
                    <Redirect to="/catalogue" />
                ) : (
                    <LoginPage {...props} />
                )
            )} />

            <Route path="/auth/register" render={(props) => (
                isLoggedIn() ? (
                    <Redirect to="/catalogue" />
                ) : (
                    <RegisterPage {...props} />
                )
            )} />
            
            <Route path="/auth/verification/:id" component={VerificationPage} />
            <Route path="/auth/termsAndConditions" component={TermsConditions} />
            <Route path="/auth/privacyPolicy" component={PrivacyPolicy} />
            <Redirect to={{ pathname: "/auth/login" }} />;
        </Switch>
    );
}

export default AuthRouter;
