import { baseUrl, api } from 'services/customAxios'
import jwt_decode from "jwt-decode";
import { CustomLicenseModel, LicenseTemplateModel } from 'models'

const createTemplate = async (productSN: string, lt: LicenseTemplateModel) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.post(baseUrl + '/licenseTemplates/create', {
        productSN: productSN,
        userid: sessionToken.userid,
        licenseTemplate: lt
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log('message: ' + error.message)
            return Promise.reject(error);
        })
}

const createTemplateBFI = async (productSN: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return api.post(baseUrl + '/licenseTemplates/createBFI', {
        userid: sessionToken.userid,
        productSN: productSN
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            const errorMessage = error.response.data.message;
            console.log('message: ' + error.message)
            return Promise.reject(errorMessage ? errorMessage : 'License template creation error.');
        })
}

const getLicenseTemplateByProductId = async (productId: string) => {
    // Get UserId
    const accessToken = localStorage?.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken);

    return await api.get(baseUrl + `/licenseTemplates/product`, {
        params: {
            userid: sessionToken.userid,
            productId: productId
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getLicenseTemplateByProductBFI = async (productId: string, role:string) => {
    // Get UserId
    const accessToken = localStorage?.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken);

    const url = (role === "seller") ? "bfiproductFromSeller" : "bfiproduct"
    return await api.get(baseUrl + `/licenseTemplates/` + url, {
        params: {
            userid: sessionToken.userid,
            productId: productId
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getLicenseTemplateById = async (licenseTemplateId: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + `/licenseTemplates/${licenseTemplateId}`, {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data.licenseTemplate[0];
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getLicenseTemplates = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/licenseTemplates/', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            console.log(res.data)
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getBFILicenseTemplates = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/licenseTemplates/bfiproducts', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            console.log(res.data)
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const addCustomLicenseToCart = async (productSN: string, licenseTemplate: LicenseTemplateModel, customLicense: CustomLicenseModel) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.post(baseUrl + '/cart', {
        productSN: productSN,
        userid: sessionToken.userid,
        customLicense: customLicense,
        licenseTemplate: licenseTemplate
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log('message: ' + error.message)
            return Promise.reject(error);
        })
}

const addBFILicenseToCart = async (productSN: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.post(baseUrl + '/cart/bfi', {
        productSN: productSN,
        userid: sessionToken.userid
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            const errorMessage = error.response.data.message;
            console.log('message: ' + error.message)
            return Promise.reject(errorMessage ? errorMessage : 'Purchase license error.');
        })
}

export {
    createTemplate,
    createTemplateBFI,
    getLicenseTemplateByProductId,
    getLicenseTemplateByProductBFI,
    getLicenseTemplateById,
    getLicenseTemplates,
    getBFILicenseTemplates,
    addCustomLicenseToCart,
    addBFILicenseToCart
}