import { baseUrl, api } from 'services/customAxios'
import jwt_decode from "jwt-decode";
import { OrgModel, UserModel } from 'models';

const getUser = async (): Promise<UserModel> => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/users/' + sessionToken.userid)
        .then(res => {
            const user: UserModel = res.data.user;
            return user;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getInstitutions = async (): Promise<OrgModel[]>  => {
    return await api.get(baseUrl + '/orgs')
        .then(res => {
            const orgs = res.data.orgs;
            return orgs;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const changePassword = async (password: string) => {
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.put(baseUrl + '/users/password', {
        userid: sessionToken.userid,
        password: password
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

export {
    getUser, getInstitutions, changePassword
}