import React from 'react';
import { withStyles } from '@material-ui/styles';
import { DateRangePicker } from 'rsuite';
import { isBefore, isWithinInterval, isAfter, isSameDay, isToday } from 'date-fns'
import 'rsuite/dist/styles/rsuite-default.css';
import { connect } from 'react-redux';
import { ValueType } from "rsuite/lib/DateRangePicker";
import { LicenseTemplateActions, CustomLicenseActions } from 'redux/actions'
import { bindActionCreators } from 'redux'
import { CustomTerritoryModel, PeriodModel } from 'models';
import { RootState } from 'redux/store'
import { TemplateTerritoryModel } from 'models/LicenseTemplateModel';
import Styles from 'components/licenses/styles/LicenseStyles';

const {
    beforeToday,
    combine
} = DateRangePicker;

interface OwnProps {
    classes?: any,
    rightName: string,
    subRightName: string,
    clauseName: string,
    territoryId: string,
    territoryName: string,
    periodId: string,
    licenseType: string,
}

interface DispatchProps {
    updateBlockedPeriodLT: (payload: any) => void,
    removeBlockedPeriodLT: (payload: any) => void,
    updatePeriodCL: (payload: any) => void,
    removePeriodCL: (payload: any) => void
}

interface StateProps {
    period: PeriodModel,
    blockedPeriods: PeriodModel[],
    periods: PeriodModel[]
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
    selectedDates: Date[],
    updated: boolean
}

class PeriodComponent extends React.Component<Props, State> {
    state = {
        selectedDates: [],
        updated: false
    }

    updatePeriod = (newPeriod: PeriodModel) => {

        // Update Period
        if (this.props.licenseType === 'template') {
            this.props.updateBlockedPeriodLT({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                periodId: this.props.periodId,
                period: newPeriod
            })
        }// else update normal license period
        if (this.props.licenseType === 'custom') {
            /*if(this.props.rightName === 'Educational') {
                this.props.updateEduPeriodCL({
                    rightName: this.props.rightName,
                    period: newPeriod
                })
            }
            else {*/
            this.props.updatePeriodCL({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                periodId: this.props.periodId,
                period: newPeriod
            })
            //}
        }
    }

    parseDate = (date: string | Date): Date => {
        if (!(date instanceof Date))
            return new Date(date.toString())
        else return date;
    }

    dateToString = (date: Date | string): string => {
        if (date instanceof Date)
            return date.toString()
        else return date;
    }

    componentDidMount() {
        const { period } = this.props;

        if (!(period === null || typeof period === 'undefined')) {

            if (!(period.start === '' || period.end === ''))
                this.setState(prevState => ({
                    ...prevState,
                    selectedDates: [
                        this.parseDate(period.start),
                        this.parseDate(period.end)
                    ]
                }));
        }
    }

    addDate = (newDate: Date) => {
        this.setState({ selectedDates: [...this.state.selectedDates, this.parseDate(newDate)] });
    };

    /* Triggers when a change is done to the state */


    isWithinIntervals = (date: Date) => {
        const { blockedPeriods } = this.props;

        for (let i = 0; i < blockedPeriods.length; i++) {
            const start = this.parseDate(blockedPeriods[i].start);
            const end = this.parseDate(blockedPeriods[i].end);

            if (isWithinInterval(date, { start: start, end: end }) ||
                isSameDay(date, end) ||
                isSameDay(date, start)) {
                return true;
            }
        }
        if (this.props.licenseType === 'custom') {
            for (let i = 0; i < this.props.periods.length; i++) {
                const start = this.parseDate(this.props.periods[i].start);
                const end = this.parseDate(this.props.periods[i].end);

                if (isWithinInterval(date, { start: start, end: end }) ||
                    isSameDay(date, end) ||
                    isSameDay(date, start)) {
                    return true;
                }
            }
        }

        return false;
    }

    getFirstBlockedDate = () => {
        const { blockedPeriods } = this.props;
        //procurar o blocked periodo mais perto e bloquear a partir daí
        let firstBlockedDate = new Date(8640000000000000); //Infinity

        for (let i = 0; i < blockedPeriods.length; i++) {
            // startblockedDate tem de ser depois da 1a selecionada
            const start = this.parseDate(blockedPeriods[i].start);

            if (isAfter(start, this.state.selectedDates[0])) {
                if (isBefore(start, firstBlockedDate)) {
                    firstBlockedDate = start;
                }
            }
        }

        if (this.props.licenseType === 'custom') {
            for (let i = 0; i < this.props.periods.length; i++) {
                // startblockedDate tem de ser depois da 1a selecionada
                const start = this.parseDate(this.props.periods[i].start);

                if (isAfter(start, this.state.selectedDates[0])) {
                    if (isBefore(start, firstBlockedDate)) {
                        firstBlockedDate = start;
                    }
                }
            }
        }


        return firstBlockedDate;
    }

    getLastBlockedDate = () => {
        const { blockedPeriods } = this.props;
        let lastBlockedDate = new Date(); //Today 

        for (let i = 0; i < blockedPeriods.length; i++) {
            const end = this.parseDate(blockedPeriods[i].end);

            if (isBefore(end, this.state.selectedDates[0])) {
                if (isAfter(end, lastBlockedDate)) {
                    lastBlockedDate = end;
                }
            }
        }

        if (this.props.licenseType === 'custom') {
            for (let i = 0; i < this.props.periods.length; i++) {
                const end = this.parseDate(this.props.periods[i].end);

                if (isBefore(end, this.state.selectedDates[0])) {
                    if (isAfter(end, lastBlockedDate)) {
                        lastBlockedDate = end;
                    }
                }
            }
        }
        return lastBlockedDate;
    }

    componentDidUpdate() {
        if (this.state.selectedDates.length > 2) {
            console.log('hello there')
            console.log(this.state.selectedDates)
            // remove from origin state
            this.removePeriodFromState()
            this.setState({
                selectedDates: [this.state.selectedDates[2]],
                updated: false
            })
        }

        //verificar se existe no redux state
    }

    sortState = () => {
        if (isBefore(this.state.selectedDates[1], this.state.selectedDates[0])) {
            this.setState(prevState => ({
                ...prevState,
                selectedDates: [
                    this.state.selectedDates[1],
                    this.state.selectedDates[0]
                ]
            }));
        }
    }

    addPeriodToState = () => {
        let newPeriod: PeriodModel;

        if (isBefore(this.state.selectedDates[1], this.state.selectedDates[0]))
            newPeriod = {
                periodId: this.props.periodId,
                start: this.dateToString(this.state.selectedDates[1]),
                end: this.dateToString(this.state.selectedDates[0])
            }
        else newPeriod = {
            periodId: this.props.periodId,
            start: this.dateToString(this.state.selectedDates[0]),
            end: this.dateToString(this.state.selectedDates[1])
        }

        this.updatePeriod(newPeriod)
    }

    removePeriodFromState = () => {
        if (this.props.licenseType === 'template') {
            this.props.removeBlockedPeriodLT({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                blockedPeriodId: this.props.periodId
            })
        }// else update normal license period
        if (this.props.licenseType === 'custom') {
            this.props.removePeriodCL({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                blockedPeriodId: this.props.periodId
            })
        }

    }

    getSelectedDate = () => {
        const { blockedPeriods, periodId } = this.props;

        let ret: ValueType = [];

        if (this.props.licenseType === 'template') {
            if (blockedPeriods.length !== 0) {
                const bp = blockedPeriods.find(
                    element => element.periodId === periodId
                )

                if (bp !== undefined)
                    ret = [this.parseDate(bp.start), this.parseDate(bp.end)];
            }
        }
        if (this.props.licenseType === 'custom') {
            if (this.props.periods.length !== 0) {
                const p = this.props.periods.find(
                    element => element.periodId === periodId
                )

                if (p !== undefined)
                    ret = [this.parseDate(p.start), this.parseDate(p.end)];
            }
        }
        return ret;
    }

    render() {
        const { classes } = this.props;

        // Ao bloquear todas as datas before 1st selection, resolve-se possiveis problemas
        return (<>
            {console.log(this.props.periodId)}
            <DateRangePicker
                className={classes.blockedPeriodStyle}
                style={{
                    width: 280
                }}
                //https://rsuitejs.com/components/date-range-picker/
                //showOneCalendar
                ranges={[]}
                //placeholder={<input type="date"></input>}
                disabledDate={combine(
                    beforeToday(),
                    (date: Date) => {
                        if (isToday(date)) return true;

                        const firstBlockedDate = this.getFirstBlockedDate();
                        const lastBlockedDate = this.getLastBlockedDate();
                        if (this.state.selectedDates.length === 0 ||
                            this.state.selectedDates.length === 2)
                            return this.isWithinIntervals(date)
                        else return isAfter(date, firstBlockedDate) ||
                            isSameDay(date, firstBlockedDate) ||
                            isBefore(date, lastBlockedDate) ||
                            isSameDay(date, lastBlockedDate);
                    }
                )}
                onSelect={(date) => this.addDate(new Date(date))}
                onOk={() => {
                    this.sortState();
                    this.addPeriodToState();
                }}
                value={this.getSelectedDate()}
                cleanable={false}
                onClean={() => {
                    if (this.state.selectedDates.length === 2) {
                        this.removePeriodFromState();
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        selectedDates: []
                    }));
                }}
                placement="auto"
            />
        </>);
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const emptyPeriod: PeriodModel = { periodId: ownProps.periodId, start: '', end: '' }; // empty date
    let period: PeriodModel = emptyPeriod;
    let blockedPeriods: PeriodModel[] = [], periods: PeriodModel[] = [];


    const templateTerritories = state.licenseTemplate.commercialRights[ownProps.rightName].subRights[ownProps.subRightName].clauses[ownProps.clauseName].territories;
    const customTerritories = state.customLicense.commercialRights[ownProps.rightName][ownProps.subRightName][ownProps.clauseName].territories;
    if (ownProps.licenseType === 'template') {
        const templateTerritory = templateTerritories.find(
            (element: TemplateTerritoryModel) => element.territoryId === ownProps.territoryId
        )

        const bpTemplate = templateTerritory !== undefined ? templateTerritory.blockedPeriods : [];

        const templatePeriod = bpTemplate.find(
            (element: PeriodModel) => element.periodId === ownProps.periodId
        );
        period = templatePeriod !== undefined ? templatePeriod : emptyPeriod;
        blockedPeriods = bpTemplate;
        periods = []
    }

    // State Getter for Customization Page
    if (ownProps.licenseType === 'custom') {
        const customTerritory = customTerritories.find(
            (element: CustomTerritoryModel) => element.territoryId === ownProps.territoryId
        )
        periods = customTerritory !== undefined ? customTerritory.periods : periods;
        const bpCustom = customTerritory !== undefined ? customTerritory.periods : [];

        const customPeriod = bpCustom.find(
            (element: PeriodModel) => element.periodId === ownProps.periodId
        );
        period = customPeriod !== undefined ? customPeriod : emptyPeriod;

        if (ownProps.territoryName === '' || ownProps.territoryName === undefined)
            blockedPeriods = []
        else {
            const templateTerritory = templateTerritories.find(
                (element: TemplateTerritoryModel) => element.territoryName === ownProps.territoryName
            )
            blockedPeriods = templateTerritory !== undefined ? templateTerritory.blockedPeriods : [];
        }

    }

    return {
        period: period,
        blockedPeriods: blockedPeriods,
        periods: periods
    }

};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        updateBlockedPeriodLT: LicenseTemplateActions.updateBlockedPeriodLT,
        removeBlockedPeriodLT: LicenseTemplateActions.removeBlockedPeriodLT,

        updatePeriodCL: CustomLicenseActions.updatePeriodCL,
        removePeriodCL: CustomLicenseActions.removePeriodCL
    }, dispatch)
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(Styles as any)(PeriodComponent));