import { baseUrl, api } from 'services/customAxios'
import jwt_decode from "jwt-decode";
import { ProductModel } from 'models'

const createProduct = async (product: ProductModel) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.post(baseUrl + '/products/create', { product: product, userid: sessionToken.userid })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error.response)
            console.log('message: ' + error.message)
            return Promise.reject(error);
        })
}

const getProduct = async (productId: string) => {

    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/products/' + productId,
        {
            params: {
                userid: sessionToken.userid
            }
        })
        .then(res => {
            return res.data.product;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getAllProducts = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/products/all', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getCollectionProducts = async (collectionId?: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');


    return await api.get(baseUrl + '/products', {
        params: {
            userid: sessionToken.userid,
            productid: collectionId
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getProductCategories = async () => {

    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/products/categories',
        {
            params: {
                userid: sessionToken.userid
            }
        })
        .then(res => {
            return res.data.categories;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

export {
    createProduct,
    getProduct,
    getCollectionProducts,
    getAllProducts,
    getProductCategories
}