import { createReducer } from '@reduxjs/toolkit'
import { 
    commercialRightsDB
 } from 'database/rightsDB'
import { Action, LicenseWindowActions } from 'redux/actions'
import territoriesDB from 'database/territoriesDB';
import { 
    ClauseWindowModel,
    CommercialRightModel,
    LicenseWindowModel, 
    NonCommercialRightModel, 
    RightWindowModel, 
    SubRightWindowModel, 
    TerritoryWindowModel
} from 'models';

const {
    updateRightsCurrentOpenTabLW,
    addRightsTabLW,
    removeFromRightsLeftToBeChosenLW,
    updateClausesCurrentOpenTabLW,
    addClausesTabLW,
    removeFromClausesLeftToBeChosenLW,
    updateRightsLeftToBeChosenLW,
    updateClausesLeftToBeChosenLW,
    removeClauseEntryLW,
    removeRightEntryLW,
    addTerritoryEntryLW,
    removeTerritoryLW,
    updateTerritoryLW,
    removeTerritoryEntryLW,
    addBlockedPeriodEntryLW,
    removeBlockedPeriodEntryLW
} = LicenseWindowActions;

const rights = Object.keys(commercialRightsDB);

const countries = territoriesDB.countries;
const inicialLWState: LicenseWindowModel = {
    openTabs: [],
    currentOpenTab: '+',
    leftToBeChosen: JSON.parse(JSON.stringify(rights))
};

const licenseWindowReducer = createReducer(inicialLWState, (builder) => {
    builder
        /* Rights Window */
        .addCase(updateRightsCurrentOpenTabLW, (state, action: Action) => {
            state.currentOpenTab = action.payload
        })
        .addCase(addRightsTabLW, (state: any, action: Action) => {
            const { newTab, template } = action.payload;

            if(newTab === 'Educational') {
                const newEduWindow: NonCommercialRightModel= {
                    right: newTab,
                    blockedPeriods: []
                }
                state.openTabs.push(newEduWindow)
            }            
            else {
                const subRights = Object.keys(commercialRightsDB[newTab])
                const tab: CommercialRightModel = {
                    right: newTab
                }
    
                // No template provided
                if (template === null) {
                    for (let i = 0; i < subRights.length; i++) {
                        const values= JSON.parse(JSON.stringify(commercialRightsDB[newTab][subRights[i]]));
                        const newSubRitheWindow: SubRightWindowModel = {
                            openTabs: [],
                            currentOpenTab: 'Add Clause',
                            leftToBeChosen: values
                        }
                        tab[subRights[i]] = newSubRitheWindow
                    }
                    state.openTabs.push(tab)
                }
                // Template provided
                else {
                    console.log('subrights', subRights);
                    for (let i = 0; i < subRights.length; i++) {
                        console.log(template)
                        if (template.commercialRights[newTab].allowed &&
                            template.commercialRights[newTab].subRights[subRights[i]].allowed) {
    
                                const newSubRitheWindow: SubRightWindowModel = {
                                openTabs: [],
                                currentOpenTab: 'Add Clause',
                                leftToBeChosen: JSON.parse(JSON.stringify(commercialRightsDB[newTab][subRights[i]]))
                            }
                            tab[subRights[i]] = newSubRitheWindow
                        }
                    }
                    state.openTabs.push(tab)
                }
            }
        })
        .addCase(removeFromRightsLeftToBeChosenLW, (state, action: Action) => {
            state.leftToBeChosen.splice(state.leftToBeChosen.indexOf(action.payload), 1);
        })
        .addCase(updateRightsLeftToBeChosenLW, (state: any, action: Action) => {
            state.leftToBeChosen = action.payload;
        })

        /* Clauses Window */
        .addCase(updateClausesCurrentOpenTabLW, (state: any, action: Action) => {
            const { newTab, rightName, subRightName } = action.payload;
            state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName].currentOpenTab = newTab
        })
        .addCase(addClausesTabLW, (state: any, action: Action) => {
            const { newTab, rightName, subRightName, subRightTemplate } = action.payload;
            const subRightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName]

            if (subRightTemplate !== null) {
                if (subRightTemplate.clauses[newTab].allowed) {
                    //Get available countries
                    const t_Array = subRightTemplate.clauses[newTab].territories
                    const territories = [];
                    for (let i = 0; i < t_Array.length; i++) {
                        territories.push(t_Array[i].territoryName)                        
                    }

                    subRightWindow.openTabs.push({
                        clause: newTab,
                        territories: [],
                        territoriesLeftToBeChosen: territories.sort()
                    })
                }                   
            }
            else {
                subRightWindow.openTabs.push({
                    clause: newTab,
                    territories: [],
                    territoriesLeftToBeChosen: countries
                })
            }
        })
        .addCase(removeFromClausesLeftToBeChosenLW, (state: any, action: Action) => {
            const { newTab, rightName, subRightName } = action.payload;
            const subRightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName]

            subRightWindow.leftToBeChosen.splice(subRightWindow.leftToBeChosen.indexOf(newTab), 1);
        })
        .addCase(updateClausesLeftToBeChosenLW, (state: any, action: Action) => {
            const { rightName, subRightName, leftToBeChosen } = action.payload;
            const subRightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName]
            subRightWindow.leftToBeChosen = leftToBeChosen;
        })
        .addCase(removeClauseEntryLW, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName } = action.payload;
            const subRightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName]

            // Update subright clauses left to be chosen
            subRightWindow.leftToBeChosen.push(clauseName);

            const clauseToRemove = subRightWindow.openTabs.find(
                (element: ClauseWindowModel) => element.clause === clauseName
            )

            // Update subright open tabs
            subRightWindow.openTabs.splice(subRightWindow.openTabs.indexOf(clauseToRemove), 1);

            // Update current subRight open tab
            if (subRightWindow.openTabs.length === 0)
                subRightWindow.currentOpenTab = 'Add Clause'
            else subRightWindow.currentOpenTab = subRightWindow.openTabs[0].clause;
        })
        .addCase(removeRightEntryLW, (state: any, action: Action) => {
            const rightName = action.payload;

            // Update license window's rights left to be chosen
            state.leftToBeChosen.push(rightName)

            // Update subright open tabs
            const rightToRemove = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )

            state.openTabs.splice(state.openTabs.indexOf(rightToRemove), 1);

            // Update current subRight open tab
            if (state.openTabs.length === 0)
                state.currentOpenTab = '+'
            else state.currentOpenTab = state.openTabs[0].right;
        })
        /* Territory Window */
        .addCase(addTerritoryEntryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId } = action.payload;
            const clause = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName].openTabs.find(
                (element: ClauseWindowModel) => element.clause === clauseName
            )

            clause.territories.push({
                territoryId: territoryId,
                territory: '',
                blockedPeriods: []
            })
        })
        .addCase(updateTerritoryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId, territoryName } = action.payload;
            const clause = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName].openTabs.find(
                (element: ClauseWindowModel) => element.clause === clauseName
            )

            const old_territory = clause.territories.find(
                (element: TerritoryWindowModel) => element.territoryId === territoryId
            )?.territory;

            //remove new from lefttobechosen and insert old territory
            if(old_territory !== "" && old_territory !== undefined)
                clause.territoriesLeftToBeChosen.push(old_territory);
            clause.territoriesLeftToBeChosen.splice(clause.territoriesLeftToBeChosen.indexOf(territoryName), 1);
               
            const temp = clause.territoriesLeftToBeChosen
            clause.territoriesLeftToBeChosen = temp.sort();

            // Insert new on territory
            const territoryWindow = clause.territories.find(
                (element: TerritoryWindowModel) => element.territoryId === territoryId
            ); 
            if (territoryWindow) { territoryWindow.territory = territoryName; }

        })
        .addCase(removeTerritoryEntryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId } = action.payload;
            const clause = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName].openTabs.find(
                (element: ClauseWindowModel) => element.clause === clauseName
            )
            // get index of the territory to remove
            const territoryToRemove = clause.territories.find(
                (element: TerritoryWindowModel) => element.territoryId === territoryId
            )
            //remove from lefttobechosen
            const t_nameToRemove = territoryToRemove.territory;

            if(t_nameToRemove !== "" && t_nameToRemove !== undefined) {
                const terr = clause.territoriesLeftToBeChosen.find(
                    (element: string) => element === t_nameToRemove
                )
                if (terr === undefined)
                    clause.territoriesLeftToBeChosen.push(t_nameToRemove);
            }

            const temp = clause.territoriesLeftToBeChosen
            clause.territoriesLeftToBeChosen = temp.sort();

            // Remove from territories
            clause.territories.splice(clause.territories.indexOf(territoryToRemove), 1);
        })
        .addCase(removeTerritoryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId } = action.payload;
            const clause = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            )[subRightName].openTabs.find(
                (element: ClauseWindowModel) => element.clause === clauseName
            )
            const territories = clause.territories

            const territoryToRemove = territories.find(
                (element: TerritoryWindowModel) => element.territoryId === territoryId
            ).territory
            //remove new from lefttobechosen and insert old territory
            if(territoryToRemove !== "" && territoryToRemove !== undefined){
                const terr = clause.territoriesLeftToBeChosen.find(
                    (element: string) => element === territoryToRemove
                )
                if (terr === undefined)
                    clause.territoriesLeftToBeChosen.push(territoryToRemove);
            }

            const temp = clause.territoriesLeftToBeChosen
            clause.territoriesLeftToBeChosen = temp.sort();
            
            // Update TerritoryName
            territories.find(
                (element: TerritoryWindowModel) => element.territoryId === territoryId
            ).territory = ''

        })

        /* Territory's Blocked Periods Window */
        .addCase(addBlockedPeriodEntryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId, blockedPeriodId } = action.payload;
            
            const rightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            );

            if (rightName === 'Educational') {
                rightWindow.blockedPeriods.push(blockedPeriodId)
            }
            else {
                const territory = rightWindow[subRightName].openTabs.find(
                    (element: ClauseWindowModel) => element.clause === clauseName
                ).territories.find(
                    (element: TerritoryWindowModel) => element.territoryId === territoryId
                )
    
                territory.blockedPeriods.push(blockedPeriodId)
            }            
        })
        .addCase(removeBlockedPeriodEntryLW, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId, periodId } = action.payload;
            
            const rightWindow = state.openTabs.find(
                (element: RightWindowModel) => element.right === rightName
            );
            
            if (rightName === 'Educational') {
                const blockedPeriods = rightWindow.blockedPeriods;
                blockedPeriods.splice(blockedPeriods.indexOf(periodId), 1);
            }
            else {
                const blockedPeriods = rightWindow[subRightName].openTabs.find(
                    (element: ClauseWindowModel) => element.clause === clauseName
                ).territories.find(
                    (element: TerritoryWindowModel) => element.territoryId === territoryId
                ).blockedPeriods
    
                // get index of the territory to remove
    
                blockedPeriods.splice(blockedPeriods.indexOf(periodId), 1);
            }            
        })

});

export default licenseWindowReducer;