import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
    activeFilters: {
        color: 'white',
        backgroundColor: '#7A7A7A',
        '&:hover': {
            opacity: 0.8
        },
    },
    productLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        },
        '&:hover .movie-image-hover': {
            opacity: 0.8
        },
        '&:focus': {
            textDecoration: 'none'
        }
    },
    productItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    productImgContainer: {
        position: 'relative',
        paddingBottom: '100%',
        maxWidth: '100%',
    },
    productImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 1
    },
    collectionImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        zIndex: 1
    },
    imageHover: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: '#2B37EE',
        opacity: 0,
        transition: 'opacity .3s ease-out',
        '-moz-transition': 'opacity .3s ease-out',
        '-webkit-transition': 'opacity .3s ease-out',
        '-o-transition': 'opacity .3s ease-out',
        '& .MuiSvgIcon-root' : {
            fontSize: 36,
            color: 'white'
        }
    },
    productTitle: {
        paddingTop: 15,
        color: '#000000',
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: '0.02em'
    },
    productDirector: {
        paddingTop: 8,
        color: '#7A7A7A',
        fontSize: 14,
        fontWeight: 400
    },
    buttonsContainer: {
        '&>*:first-child': {
            marginRight: 20
        }
    },
    footerBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 30
    },
    searchHeader: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 70
    },
    searchResults: {
        fontSize: 28,
        color: '#D8996F',
        textAlign: 'center',
        fontWeight: 700
    },
    searchHighlight: {
        color: '#2B37EE'
    },
    noProducts: {
        fontSize: 20,
        fontWeight: 700,
        letterSpacing: 0.5,
        color: '#7A7A7A',
        textAlign: 'center'
    }

});

export default useStyles;