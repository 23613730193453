import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import Styles from 'templates/footer/FStyles';
import EITLogo from 'assets/images/eit_logo.png';

interface OwnProps {
    classes?: any,
}

class Footer extends Component<OwnProps> {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.footerContainer}>
                <div className={classes.footer}>
                    <span>
                        <img className={classes.eitLogo} src={EITLogo} alt="EIT Logo" />
                    </span>

                    <span className={classes.supporterTxt}>
                        MAP Marketplace is supported by the European Institute of Innovation and Technology (EIT)
                    </span>

                    <span className={classes.copyrightTxt}>
                        © MAP Marketplace, {new Date().getFullYear()}
                    </span>
                </div>
            </div>
        );
    }

}

export default withStyles(Styles as any)(Footer);