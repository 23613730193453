import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import videojs from 'video.js';
import combineStyles from 'utils/combineStyles';

import CloseIcon from '@material-ui/icons/Close';
import DashboardStyles from 'styles/dashboard';
import MovieStyles from './PStyles';
import 'video.js/dist/video-js.css';

interface OwnProps {
    classes?: any;
    videoSrc: string;
    openPopup: boolean;
    dialogTitle: string;
    setOpenPopup: (value: boolean) => void | undefined;
}

function VideoPopup(props: OwnProps) {
    const { classes, openPopup } = props;

    const [videoEl, setVideoEl] = useState(null);
    const onVideo = useCallback((el) => {
        setVideoEl(el);
    }, []);

    // * Initialize VideoJS element
    useEffect(() => {
        if (videoEl === null) return;
        const videoElement = videoEl as unknown as Element;

        const player = videojs(videoElement, {
            autoplay: true,
            controls: true,
            muted: true,
            sources: [
                {
                    src: props.videoSrc,
                    type: 'application/x-mpegURL'
                },
            ],
        });

        return () => {
            player.dispose();
        };
    }, [props, videoEl]);

    const onClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            props.setOpenPopup(false)
        }
    }

    return (
        <Dialog
            open={openPopup}
            onClose={onClose}
            maxWidth="xl"
        >
            <DialogContent className={classes.popupContainer}>
                <div className={classes.videoHeader}>

                    <Typography
                        variant="h5"
                        className={classes.dashboardSubtitleBlue}
                    >
                        {props.dialogTitle}
                    </Typography>

                    <IconButton className={classes.closeButton} onClick={() => props.setOpenPopup(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className={classes.videoContainer}>
                    <div data-vjs-player>
                        <video
                            className="video-js vjs-big-play-centered"
                            ref={onVideo}
                            playsInline
                        />
                    </div>
                </div>

                <div className={classes.productOptions}>
                    <Button
                        className={classes.closeButtonFooter}
                        variant="outlined"
                        onClick={() => props.setOpenPopup(false)}
                    >
                        Close
                    </Button>

                </div>
            </DialogContent>
        </Dialog>
    );
}

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);
export default withStyles(combinedStyles)(VideoPopup);