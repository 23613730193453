import React from 'react';
// import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    /*List,
    ListItem,
    ListItemText,*/
    Paper, Typography,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell,
} from '@material-ui/core';
import { getBFILicenseTemplates } from 'services/licenseService'
import {
    commercialRightsDB,
    nonCommercialRightsDB
} from 'database/rightsDB'
import Loading from 'components/loading/Loading';
import combineStyles from 'utils/combineStyles';

import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';

interface OwnProps {
    classes: any,
    history: any
}
interface State {
    templates: any[],
    loading: boolean
}

class LTlist extends React.Component<OwnProps, State> {
    state = {
        templates: [],
        loading: true
    }

    async componentDidMount() {
        await getBFILicenseTemplates().then(
            (value) => { // On run
                console.log(value)
                this.setState({
                    templates: value.licenseTemplates,
                    loading: false
                })
            },
            (reason) => { // on fail
                console.log(reason)
            }
        );
    }

    getRights = (index: any) => {
        const temp = [];
        const value: any = this.state.templates[index];

        // Commercial Rights Filtering
        const cRights = Object.keys(commercialRightsDB);
        for (let i = 0; i < cRights.length; i++) {
            if (value.commercialRights !== undefined && value.commercialRights[cRights[i]].allowed)
                temp.push(cRights[i])
            if (value.rights !== undefined && value.rights[cRights[i]].allowed)
                temp.push(cRights[i])
        }

        // Non-Commercial Rights Filtering
        const ncRights = Object.keys(nonCommercialRightsDB);
        for (let i = 0; i < ncRights.length; i++) {
            if (value.nonCommercialRights !== undefined && value.nonCommercialRights[ncRights[i]].allowed)
                temp.push(ncRights[i])
        }
        return temp.join(', ');
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >
                <div className={classes.headerContainer}>
                    <Typography
                        className={classes.dashboardTitle}
                        variant="h4"
                    >
                        My bespoke licenses
                    </Typography>
                </div>

                {this.state.loading ? <Loading /> :

                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Movie Title</TableCell>
                                    <TableCell>Licensor</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={classes.tableBody}>
                                {this.state.templates.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            className={classes.noLicenses}
                                            colSpan={3}
                                        >
                                            No entries were found.
                                        </TableCell>
                                    </TableRow>
                                    :
                                    this.state.templates.map((row: any, index: any) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <NavLink
                                                    className={classes.downloadLink}
                                                    to={`/catalogue/${row.vizziID}`}
                                                >
                                                    {row.filmTitle}
                                                </NavLink>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.licensor}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                        </Table>
                    </TableContainer>}

                {/*<List>
                    {this.state.templates.map((item: any, index: any) => {
                        return (
                            <NavLink key={index} to={'/myLicenseTemplates/' + item.licenseTemplateId} >
                                <ListItem button >
                                    <ListItemText primary={this.getRights(index)} />
                                    <ListItemText primary={item.creationDate} />
                                </ListItem>
                            </NavLink>
                        )
                    })}
                </List>*/}

            </React.Fragment>
        );
    }
}
const combinedStyles = combineStyles(DashStyles, Styles);

export default withStyles(combinedStyles)(LTlist);