import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import combineStyles from 'utils/combineStyles';
import Loading from 'components/loading/Loading';
import ProductItem from 'pages/products/ItemProduct';
import { CollectionModel, UserModel } from 'models';
import { getCollections } from 'services/collectionService';
import { isSeller } from 'services/authService';
import { RootState } from 'redux/store';

import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

interface OwnProps {
    classes?: any,
    history: any,
    match: any
}

interface State {
    collections: any[],
    loading: boolean
}

interface StateProps {
    user: UserModel
}

type Props = StateProps & OwnProps

class CollectionsPage extends React.Component<Props, State> {
    state = {
        collections: [],
        loading: true,
    }

    componentDidMount() {

        getCollections().then(
            (value) => {
                console.log(value);

                this.setState({
                    loading: false,
                    collections: value.collections
                });
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    CollectionsHeader = () => {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.headerContainer}>
                    <Typography component="h4" className={classes.dashboardTitle}>
                        {isSeller(this.props.user) ? "My Collections" : "Collections"}
                    </Typography>
                </div>

            </>
        );
    }

    render() {
        const { classes } = this.props;

        const CollectionsLoading = (<>
            <this.CollectionsHeader />
            <Loading />
        </>);

        return (
            <Suspense fallback={CollectionsLoading}>
                <this.CollectionsHeader />
                
                {this.state.loading ?
                    <Loading /> :
                    <>
                        {typeof this.state.collections !== 'undefined' && this.state.collections.length === 0 ?
                            <Typography className={classes.noProducts}>No movie collections!</Typography> : (
                                <Grid container spacing={6}>
                                    {this.state.collections.map((collection: CollectionModel, index: any) => (
                                        <Grid key={collection._id} item xs={3}>
                                            <NavLink key={index} className={classes.productLink} to={'/catalogue/' + collection._id} >
                                                <ProductItem poster={collection.poster?.url} title={collection.title} movie={false} />
                                            </NavLink>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }
                    </>
                }
            </ Suspense>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);
export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
    mapStateToProps
)(withStyles(combinedStyles)(CollectionsPage));