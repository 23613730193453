import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
  toolbarLogo: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 72
  },
  sidebar: {
    width: 330,
    position: 'relative',
    backgroundColor: '#FBFBFB',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: 'none',
  },
  itemsContainer: {
    marginTop: 40
  },
  sidebarItemLink: {
    textDecoration: 'none',
    color: '#7A7A7A',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
    '&.active .MuiListItem-root': {
      backgroundColor: '#F0F0F0',
      color: '#2B37EE'
    }
  },
  sidebarItem: {
    height: 64,
    '&:hover': {
      color: '#2B37EE',
      backgroundColor: '#F0F0F0'
    },
    '&:hover .MuiListItemIcon-root': {
      color: 'rgba(43,55,238,0.8)'
    }
  },
  sidebarItemIcon: {
    color: 'currentColor',
    marginLeft: 30,
    minWidth: 45
  },
  sidebarItemTxt: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.02em',
  },
  sidebarFooter: {
    position: 'absolute',
    width: '100%',
    bottom: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '22px',
    textAlign: 'center'
  },
  sidebarFooterTxt: {
    color: '#7a7a7a',
    fontWeight: 500,
    letterSpacing: '0.2px',
    marginTop: 10
  },
  eitLogo: {
    width: 250,
    marginTop: 10
  }
});

export default useStyles;