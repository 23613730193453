import moment from 'moment';

// * Format seconds to HH:mm:ss (H - hours, m - minutes, s - seconds)
export const formatSecondsToTime = (seconds = 0): string => {
    const mom = moment().startOf('day').seconds(seconds);
    let duration = '';

    if (mom.hours()) {
        duration = `${mom.hours()}h ${mom.minutes()}m`;
    } else {
        duration = `${mom.minutes()}m ${mom.seconds()}s`;
    }

    return duration;
};

// * Slider step calculator, ie, if number is too big, reduce step
export const stepCalculator = (num: number): number => {
    if (num > 999999) {
        return 10000;
    } else if (num > 14999) {
        return 1000;
    } else if (num > 9999) {
        return 500;
    } else if (num > 1499) {
        return 100;
    } else if (num > 999) {
        return 50;
    } else if (num > 499) {
        return 10;
    } else return 1;
}

export const titleCase = (str: string): string => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export const normalizeMessage = (str: string): string => {
    return titleCase(str.replace(/([A-Z])/g, ' $1'));
}

export const getNewCheckedValues = (checkedValue: string, checkedValues: string[]): string[] => {
    const newValues = checkedValues?.includes(checkedValue)
        ? checkedValues?.filter((value: string) => value !== checkedValue)
        : [...(checkedValues ?? []), checkedValue];

    return newValues;
}

export const removeHTML = (str: string): string => { 
    const tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
}