import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from "@material-ui/core";

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
    },
    palette: {
      background: {
        default: "#E5E5E5"
      },

      common: {
        black: "#000000"
      },
      primary: {
        main: "#2B37EE"
      },

      secondary: {
        main: "#D8996F"
      },

      text: {
        primary: "#000"
      },
    }
  })
);
