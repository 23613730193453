import React, { useState } from 'react';
import clsx from 'clsx';
// import { useImage } from 'react-image';
import { Img } from 'react-image';
import { withStyles } from '@material-ui/styles';

import combineStyles from 'utils/combineStyles';

import DefaultPoster from 'assets/images/default-image.jpg';
// import EditIcon from 'assets/images/edit-icon.svg';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

interface Props {
    classes?: any,
    movie: boolean,
    title: string,
    poster?: string,
    director?: string
}

function ItemProduct(props: Props) {
    const { classes, poster, title, director, movie } = props;
    const [ srcImg ] = useState(poster ? poster : DefaultPoster);
    // const [ srcImg, setImage ] = useState(poster ? poster : DefaultPoster);
    // const { src, error } = useImage({
    //     srcList: [ srcImg ],
    // });

    
    
    /*const [ imgError, setImgError ] = useState(false);

    const onError = () => {
        if (!imgError) {
            setImgError(true);
            setImage(DefaultPoster);
        }
    }*/
    
    return (
        <div className={classes.productItem}>

            <div className={classes.productImgContainer}>
                {/* <img className={classes.productImage} src={src} alt="Movie Poster" /> */}
                <Img
                    src={[srcImg, DefaultPoster]}
                    className={movie ? classes.productImage : classes.collectionImage} 
                />
                <div className={clsx(classes.imageHover, "movie-image-hover")}>
                    <MovieOutlinedIcon />
                    {/*<img src={EditIcon} alt="Edit Icon" />*/}
                </div>
            </div>

            <div className={classes.productTitle}>
                {title}
            </div>

            <div className={classes.productDirector}>
                {director}
            </div>
        </div>
    )
}

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles)(ItemProduct);