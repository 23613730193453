import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({

    /* Login page styling */
    loginTitle: {
        fontSize: 40,
        fontWeight: 700,
        color: 'black',
        paddingBottom: 60
    },
    positionEndLogin: {
        marginRight: -12,
        '& svg': {
            width: 18,
            height: 18,
        }
    },
    forgotLink: {
        color: '#7A7A7A',
        fontSize: 16,
        textDecoration: 'underline',
        '&:hover': {
            color: 'rgba(43,55,238, 0.9)'
        },
        '&:focus': {
            color: 'rgba(43,55,238, 0.9)'
        },
    },
    loginLinkContainer: {
        display: 'flex',
        fontSize: 16,
        marginTop: 30,
        marginBottom: 40
    },

    /* Register page styling */
    registerTitle: {
        fontSize: 40,
        fontWeight: 700,
        color: 'black'
    },
    formContainer: {
        paddingBottom: 15
    },
    positionEndRegister: {
        marginRight: -12,
        '& svg': {
            width: 18,
            height: 18,
        }
    },
    disclaimerContainer: {
        marginTop: -10
    },
    permissionCheckbox: {
        '& .MuiFormControlLabel-label': {
            letterSpacing: '0.2px',
            fontSize: 14
        }
    },
    permissionsDisclaimer: {
        letterSpacing: '0.2px',
        fontSize: 14
    },
    termsCheckbox: {
        marginTop: 0,
        '& .MuiFormControlLabel-label': {
            letterSpacing: '0.2px',
            fontSize: 14
        }
    },
    signUpLinkContainer: {
        display: 'flex',
        fontSize: 16,
        marginTop: 60,
        marginBottom: 60
    },

    /* Common styling */
    authContainer: {
        position: 'relative',
        display: 'flex',
        minHeight: '100vh'
    },
    authFormContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto'
    },
    authFormBox: {
        width: 520
    },
    positionStart: {
        marginRight: 12,
    },
    iconButton: {
        padding: 8,
    },
    authLink: {
        color: '#2B37EE',
        fontWeight: 700,
        textDecoration: 'underline',
        '&:hover': {
            color: 'rgba(43,55,238, 0.9)'
        },
        '&:focus': {
            color: 'rgba(43,55,238, 0.9)'
        }
    },
    authTxt: {
        color: 'black',
        marginRight: 10
    },
    authError: {
        fontSize: '1rem',
        paddingBottom: 20,
        marginTop: -35
    },
    authFeedback: {
        fontSize: '1rem',
        paddingBottom: 20,
    },
    submit: {
        width: 208,
        height: 48,
        backgroundColor: '#2B37EE',
        color: 'white',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: 16,
        letterSpacing: '0.02em',
        '&:hover': {
            backgroundColor: 'rgba(43,55,238,0.9)'
        }
    },
    inputTextfield: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'grey',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'grey',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'grey',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'grey',
            },
        }
    },
    inputTextfieldError: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'red',
            },
        }
    },
    /* Terms and conditions/privacy policy page styling */
    termsTitle: {
        fontSize: 40,
        fontWeight: 700,
        color: 'black',
        paddingBottom: 20
    },
    lastUpdate: {
        color: '#7A7A7A',
        paddingBottom: 10
    },
    termsSubtitle: {
        fontSize: 30,
        fontWeight: 600,
        color: '#2B37EE',
        paddingTop: 5,
        paddingBottom: 15,
    },
    privacySubtitle: {
        fontSize: 25,
        fontWeight: 600,
        color: '#2B37EE',
        paddingTop: 5,
        paddingBottom: 15,
    },
    termsBox: {
        marginTop: 75,
        marginBottom: 75,
        maxWidth: 650,
        textAlign: 'justify'
    },
    termsList: {
        '& > li': {
            marginTop: 5,
            fontSize: '1rem',
            color: 'black',
        }
    },
    termsTxt: {
        color: 'black',
        marginBottom: 18
    },
    termsContact: {
        color: 'black',
        marginBottom: 5
    },
    termsLinkBtn: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    termsBtn: {
        marginTop: 30,
        minWidth: 160,
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#E5E5E5',
        color: '#7A7A7A',
        border: '2px solid #7A7A7A',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
        '&:hover': {
            color: 'white',
            backgroundColor: '#7A7A7A'
        }
    },

    /* Verification page styling */
    verificationContainer: {
        paddingTop: 15,
        paddingBottom: 40
    },
    verificationTitle: {
        fontSize: 35,
        fontWeight: 700,
        color: 'black'
    },
    verificationSubtitle: {
        color: '#000000',
        fontSize: 18,
        fontWeight: 400,
        letterSpacing: '0.02em',
        paddingTop: 8
    },
    redirectLink: {
        '&:hover': {
            textDecoration: 'none',
        }
    },
    
    /* Profile page */
    avatar: {
        '@media (max-width: 1600px)': {
            width: '175px !important',
            height: '175px !important',
        },
        '@media (max-width: 1350px)': {
            width: '150px !important',
            height: '150px !important',
        }
    },
    formErrorContainer: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    },
    buttonsContainer: {
        marginTop: 10,
        marginBottom: 50
    }
});

export default useStyles;