import React, { Suspense } from 'react';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { CollectionModel, ProductModel, UserModel, DialogModel } from 'models';
import { FilterOptions, SliderActions } from 'models/enums';
import { SearchActions } from 'redux/actions';
import Filter from 'templates/filter/Filter';
import ProductItem from 'pages/products/ItemProduct';
import ContractPreview from 'pages/products/ContractPreview';
import Loading from 'components/loading/Loading';
import combineStyles from 'utils/combineStyles';
import { getNewCheckedValues } from 'utils/utils';
import { isBuyer, isSeller } from 'services/authService';
import { getContractPreview, checkPurchase } from 'services/cartService';
import { addBFILicenseToCart } from 'services/licenseService';
import { getCollectionProducts, getProductCategories } from 'services/productService';

import { createTemplateBFI } from 'services/licenseService';

// import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';
import { getCollectionById } from 'services/collectionService';

interface OwnProps {
    classes?: any,
    history: any,
    match: any
}

interface State {
    collection: CollectionModel | undefined,
    purchaseError: string,
    products: ProductModel[],
    originalProducts: ProductModel[],
    createdLicense: boolean,
    purchased: boolean,

    loading: boolean,
    filters: boolean,

    genreValues: string[],
    genreList: string[],

    durationRange: number[],
    defaultMinDuration: number,
    defaultMaxDuration: number,

    activeYearFilter: boolean,
    yearRange: number[],
    defaultMinYear: number,
    defaultMaxYear: number,

    sellerValues: string[],
    sellerList: string[],
    dialog: DialogModel
}

interface StateProps {
    user: UserModel,
    searchState: boolean,
    searchInput: string,
    searchResults: ProductModel[]
}

type Props = StateProps & OwnProps

class ProductsPage extends React.Component<Props, State> {
    state = {
        collection: undefined as CollectionModel | undefined,
		createdLicense: false,
        purchaseError: '',
        products: [] as ProductModel[],
        originalProducts: [] as ProductModel[],
        loading: true,
        filters: false,
        genreValues: [] as string[],
        genreList: [],
        purchased: false,

        activeDurationFilter: false,
        durationRange: [0, 10],
        defaultMinDuration: 0,
        defaultMaxDuration: 10,

        activeYearFilter: false,
        yearRange: [0, 10],
        defaultMinYear: 0,
        defaultMaxYear: 10,

        sellerValues: [] as string[],
        sellerList: [],
        dialog: {
            open: false,
            setOpenPopup: this.openContractPreview.bind(this),
            title: 'Contract Preview'
        }
    }

    componentDidMount() {		
        getCollectionProducts(this.props.match.params.id).then(
            (value) => { // On run
                //console.log('Products Retrieved with success')
                console.log(value);

                const minDuration = Math.floor(value.minDuration / 60);
                const maxDuration = Math.ceil(value.maxDuration / 60);

                this.setState({
                    loading: false,
                    originalProducts: value.products,
                    products: value.products,

                    durationRange: [minDuration, maxDuration],
                    defaultMinDuration: minDuration,
                    defaultMaxDuration: maxDuration,

                    yearRange: [value.minYear, value.maxYear],
                    defaultMinYear: value.minYear,
                    defaultMaxYear: value.maxYear,

                    sellerList: value.sellers,
                });
            },
            (reason) => { // on fail
                console.log(reason)
            });

        getProductCategories().then(
            (value) => { // On run  
                this.setState({ genreList: value });
            },
            (reason) => { // on fail
                console.log(reason)
            });

        getCollectionById(this.props.match.params.id).then(
            (value) => { // On run 
                this.setState({ collection: value });

                checkPurchase(value._id, this.props.match.params.id).then(
					(value: any) => {  
                        console.log(value.licenseTemplates)
                        if (value.licenseTemplates.length){
						console.log('License was already purchased.');
						this.setState({ purchased: true });
                        }else{
                            console.log('else false')
                            this.setState({ purchased: false });
                        }
					},
					(reason: any) => { // on fail
						console.log('License was not purchased');
                        this.setState({ purchased: false });
					});

				getContractPreview(value._id).then(
					(value: string) => {                  
						console.log('License was created for this collection.');
						this.setState({ createdLicense: true });
					},
					(reason: any) => { // on fail
						console.log('License does not exist');
                        this.setState({ createdLicense: false });
					});
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    openContractPreview(dialogValue: boolean) {
        this.setState(prevState => {
            const dialog = Object.assign({}, prevState.dialog);
            dialog.open = dialogValue;                 
            return { dialog };
        });
    }

    selectedFiltersOption() {
        const { classes } = this.props;

        if (this.state.filters) { return classes.activeFilters; }
        else return '';
    }

    handleSelect(type: string, checkedValue: string) {
        let changedState = '';
        let newValue: string[] = [];

        switch (type) {
            case FilterOptions.GENRES:
                newValue = getNewCheckedValues(checkedValue, this.state.genreValues);
                changedState = 'genreValues';

                break;
            case FilterOptions.SELLERS:
                newValue = getNewCheckedValues(checkedValue, this.state.sellerValues);
                changedState = 'sellerValues';

                break;
            default:
                console.error('[filter] handleSelect -> Filter does not exist');
                return;
        }

        this.setState((prevState) => ({
            ...prevState,
            [changedState]: newValue
        }), () => {
            this.applyFilters();
        });
    }

    handleSliderChange(type: string, action: string, value?: number[]) {
        switch (type) {
            case FilterOptions.YEAR:
                this.applySliderChanges('yearRange', 'activeYearFilter', 'defaultMinYear', 'defaultMaxYear', action, value);
                break;
            case FilterOptions.DURATION:
                this.applySliderChanges('durationRange', 'activeDurationFilter', 'defaultMinDuration', 'defaultMaxDuration', action, value);
                break;
            default:
                console.error('[filter] handleSliderChange -> Slider filter does not exist.');
                return;
        }
    }

    applySliderChanges(
        changedState: string, activeState: string, minState: string, maxState: string,
        action: string, value?: number[]
    ) {
        switch (action) {
            case SliderActions.CHANGE: {
                if (!Array.isArray(value)) { return; }

                const minYear = value[0];
                const maxYear = value[1];

                if (minYear !== maxYear) {
                    this.setState((prevState) => ({
                        ...prevState,
                        [changedState]: value
                    }));
                }

                break;
            }
            case SliderActions.COMMIT: {
                const range = value as number[];

                this.setState((prevState) => ({
                    ...prevState,
                    [changedState]: range,
                    [activeState]: true
                }), () => {
                    this.applyFilters();
                });

                break;
            }
            case SliderActions.RESET: {
                const minValue = this.state[minState as keyof State];
                const maxValue = this.state[maxState as keyof State];

                this.setState((prevState) => ({
                    ...prevState,
                    [changedState]: [minValue, maxValue],
                    [activeState]: false
                }), () => {
                    this.applyFilters();
                });

                break;
            }
            default:
                console.error('[filter] applySliderChanges -> Slider action does not exist.');
                return;
        }
    }

    /**
     * Reset year slider to its initial values
     */
    resetYearSlider() {
        this.setState({
            yearRange: [this.state.defaultMinYear, this.state.defaultMaxYear],
            activeYearFilter: false
        }, () => {
            this.applyFilters();
        });
    }

    /**
     * Function that is fired when the mouseup is triggered while using the slider
     * @param event - The event source of the callback
     * @param value - Current range value (array: [minYear, maxYear])
     */
    commitYearChanges(event: any, value: number | number[]) {
        const yearRange = value as number[];
        this.setState({
            yearRange: yearRange,
            activeYearFilter: true
        }, () => {
            this.applyFilters();
        })
    }

    /**
     * Handle changes to the slider's value
     * @param event - The event source of the callback
     * @param newValue - New range value (array: [minYear, maxYear])
     */
    handleYearChange(event: any, newValue: number | number[]) {
        if (Array.isArray(newValue)) {
            const minYear = newValue[0];
            const maxYear = newValue[1];

            if (minYear !== maxYear) {
                this.setState({ yearRange: newValue });
            }
        }
    }

    applyFilters() {
        const {
            genreValues, durationRange, activeDurationFilter,
            yearRange, activeYearFilter, sellerValues
        } = this.state;

        const newProductsList = [];
        for (let i = 0; i < this.state.originalProducts.length; i++) {
            const product = this.state.originalProducts[i];
            const selectedGenres = product.genres.filter(genre => genre.selected === true);
            const genres = selectedGenres.map((genre) => genre.name);

            const duration = product.duration / 60;
            const durationFilter = (duration >= durationRange[0] && duration <= durationRange[1]);
            const yearFilter = (product.year >= yearRange[0] && product.year <= yearRange[1]);

            const checkGenre = genreValues.length === 0 ? true : genreValues.some(cat => genres.includes(cat));
            const checkDuration = !activeDurationFilter ? true : durationFilter;
            const checkYear = !activeYearFilter ? true : yearFilter;
            const checkSellers = sellerValues.length === 0 ? true : sellerValues.includes(product.seller);

            if (checkGenre && checkDuration && checkYear && checkSellers) {
                newProductsList.push(product);
            }
        }

        this.setState({ products: newProductsList });
    }

    async buyCollection() {
        if (!this.state.collection) {
            this.setState({ purchaseError: 'Collection not found.' })
            return;
        }
        const collectionId = this.state.collection?._id;

        this.setState({ loading: true });
        await addBFILicenseToCart(collectionId).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myCustomLicenses");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false });
                console.log(reason)
            });
    }

    async createCollectionTemplate() {
        if (!this.state.collection) {
            this.setState({ purchaseError: 'Collection not found.' })
            return;
        }
        const collectionId = this.state.collection?._id;

        this.setState({ loading: true });
        await createTemplateBFI(collectionId).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myLicenseTemplates");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false });
                console.log(reason)
            });
    }


    ProductsHeader = () => {
        const { classes } = this.props;
        const { collection } = this.state;
        const catalogue = isSeller(this.props.user) ? "My Collections" : "Collections";
        const title = collection ? collection.title : catalogue;
        const collectionId = collection ? collection._id : "";

        return (
            <>
                <div className={classes.headerContainer}>
                    <Typography component="h4" className={classes.dashboardTitle}>
                        {title}
                    </Typography>

                    {!this.state.loading ? <div className={classes.buttonsContainer}>
                        {isBuyer(this.props.user) ? (
                            <Button
                                className={classes.dashboardButton}
                                variant="outlined"
                                onClick={() => this.openContractPreview(true)}
								disabled={ (this.state.purchased || !this.state.createdLicense) ? true : false}
                            >
                                License Collection
                            </Button>
                        ) :
                            <Button
                                className={classes.dashboardButton}
                                variant="outlined"
                                onClick={this.createCollectionTemplate.bind(this)}
                                disabled={true}
                            >
                                Bespoke License already created
                            </Button>
                        }

                        <Button
                            className={clsx(classes.secondaryBtn, this.selectedFiltersOption())}
                            variant="outlined"
                            onClick={() => this.setState({ filters: !this.state.filters })}
                        >
                            <span className="btn-txt">Filters</span> <FilterListIcon className={classes.btnIcon} />
                        </Button>

                        {/*isSeller(this.props.user) ? (
                            <NavLink className={classes.dashLinkBtn} to="/movies/create">
                                <Button className={classes.dashboardButton} variant="outlined">
                                    <span className="btn-txt">Add Movie</span> <AddIcon className={classes.btnIcon} />
                                </Button>
                            </NavLink>
                        ) : ''*/}
                    </div> : <></>}

                </div>

                {this.state.createdLicense ? <ContractPreview
                    openPopup={this.state.dialog.open}
                    setOpenPopup={this.state.dialog.setOpenPopup}
                    dialogTitle={this.state.dialog.title}
                    collectionId={collectionId}
                    history={this.props.history}
                /> : <></>}

                <Filter
                    genreValues={this.state.genreValues}
                    genreList={this.state.genreList}

                    durationRange={this.state.durationRange}
                    defaultMinDuration={this.state.defaultMinDuration}
                    defaultMaxDuration={this.state.defaultMaxDuration}

                    yearRange={this.state.yearRange}
                    defaultMinYear={this.state.defaultMinYear}
                    defaultMaxYear={this.state.defaultMaxYear}
                    handleSliderChange={this.handleSliderChange.bind(this)}

                    sellerValues={this.state.sellerValues}
                    sellerList={this.state.sellerList}

                    display={this.state.filters}
                    handleSelect={this.handleSelect.bind(this)}
                />
            </>
        );
    }

    render() {
        const { classes } = this.props;

        const ProductsLoading = (<>
            <this.ProductsHeader />
            <Loading />
        </>);

        return (
            <Suspense fallback={ProductsLoading}>
                <this.ProductsHeader />

                {this.state.loading ?
                    <Loading /> :
                    <>
                        {typeof this.state.products !== 'undefined' && this.state.products.length === 0 ?
                            <Typography className={classes.noProducts}>No products registered! </Typography> : (
                                <Grid container spacing={6}>
                                    {this.state.products.map((product: ProductModel, index: any) => (
                                        <Grid key={product.productid} item xs={3}>
                                            <NavLink key={index} className={classes.productLink} to={'/movies/' + product.productid} >
                                                <ProductItem poster={product.poster} title={product.title} director={product.director} movie={true} />
                                            </NavLink>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                        }

                        <div className={classes.footerBtn}>
                            <NavLink className={classes.dashLinkBtn} to="/catalogue">
                                <Button
                                    className={classes.secondaryBtn}
                                    variant="outlined"
                                >
                                    {isSeller(this.props.user) ? "Back to My Collections" : "Back to Collections"}
                                </Button>
                            </NavLink>
                        </div>
                    </>
                }
            </ Suspense>
        )
    }
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    searchState: state.searchStateReducer,
    searchResults: state.searchResultsReducer,
    searchInput: state.searchInputReducer
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        updateSearchState: SearchActions.updateSearchState,
        setSearchInput: SearchActions.setSearchInput,
        setSearchResults: SearchActions.setSearchResults
    }, dispatch)
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(ProductsPage));