import React from 'react';
import { connect } from 'react-redux';
import { InputBase, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { cleanseInput } from 'utils/validation';
import { SearchActions } from 'redux/actions';
import { bindActionCreators } from 'redux';

import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "40%",
    height: 40,
    background: '#F0F0F0',
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: 15,
    boxShadow: "none",
    flexGrow: 1
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 15,
  },
}));

interface Props {
  history: any,
  setSearchInput: any,
}

function Search(props: Props) {
  const classes = useStyles();
  const [isFocused, setIsFocused] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  
  const onSearchSubmit = (e: React.FormEvent) => {
    if (searchInput === '') {
      e.preventDefault();
      return;
    }

    props.setSearchInput(searchInput);
    setSearchInput('');
    props.history.push({
      pathname: '/searchResults',
      search: `?input=${cleanseInput(searchInput)}`
    });

    e.preventDefault();
  }

  let boxShadowStyle = 'none';
  if (isFocused) {
    boxShadowStyle = '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
      '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
      '0px 1px 3px 0px rgb(0 0 0 / 12%)';
  }

  return (
    <Paper
      component="form"
      className={classes.root}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      style={{ boxShadow: boxShadowStyle }}
      onSubmit={(e) => onSearchSubmit(e)}
    >
      <InputBase
        className={classes.input}
        placeholder="What do you want to see?"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}


const mapStateToProps = (state: any) => ({
  user: state.user,
  searchInput: state.searchInputReducer
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ 
      setSearchInput: SearchActions.setSearchInput,
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);