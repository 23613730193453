import { combineReducers } from "redux";
import userReducer from './userReducer'
import licenseTemplateReducer from './licenseTemplateReducer'
import licenseWindowReducer from './licenseWindowReducer'
import customLicenseReducer from './customLicenseReducer'
import {
    searchResultsReducer,
    searchInputReducer,
    searchStateReducer
} from './searchReducer';

const reducer = combineReducers({
    user: userReducer,
    licenseTemplate: licenseTemplateReducer,
    customLicense: customLicenseReducer,
    licenseWindow: licenseWindowReducer,
    searchResultsReducer: searchResultsReducer,
    searchInputReducer: searchInputReducer,
    searchStateReducer: searchStateReducer

    /*cart: cartReducer,
    cartDrawer: cartDrawerReducer,
    purchase: purchaseReducer,
    homePage: homePageReducer*/
});

export default reducer;
