import { baseUrl, api } from 'services/customAxios'
import jwt_decode from "jwt-decode";

const getCustomLicensesBFI = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/cart/bfi', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data.licenseTemplates;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getCustomLicenses = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/cart', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data.licenseTemplates;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const Download = (id: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return api.get(baseUrl + '/cart/download', {
        params: {
            id: id,
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data.licenseTemplates;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const checkPurchase = (id: string, vizziid: string): any => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
        console.error('User not logged in');
        return '';
    }

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return api.get(baseUrl + '/cart/bfiByBuyerAndId', {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        params: {
            id: id,
            userid: sessionToken.userid,
            vizziid: vizziid
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}


const getContractPreview = (id: string): any => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
        console.error('User not logged in');
        return '';
    }

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return api.get(baseUrl + '/cart/contractpreview', {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        params: {
            id: id,
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const DownloadVideo = (id: string): string => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
        console.error('User not logged in');
        return '';
    }

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return baseUrl + '/cart/download?id=' + id + '&userid=' + sessionToken.userid;
}

const DownloadContract = (id: string): string => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
        console.error('User not logged in');
        return '';
    }

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return baseUrl + '/cart/contract?id=' + id + '&userid=' + sessionToken.userid;
}

export {
    getCustomLicensesBFI, getCustomLicenses, getContractPreview,
    DownloadVideo, DownloadContract, Download, checkPurchase
};