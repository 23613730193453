import React, { Component } from "react";
import './Loading.css';

export default class MLoading extends Component<{xs?: boolean}> {
  render() {
    let loadingClasses = "loading";
    if (this.props.xs) { loadingClasses += " loading-xs"; }

    return (
      <div className={loadingClasses}>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
      </div>
    );
  }
}
