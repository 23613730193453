import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';

import Styles from 'styles/dashboard';

interface OwnProps {
    history: any,
    match: any,
    classes: any
}

class LicenseTemplate extends React.Component<OwnProps> {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >
                <div>
                    <div className={classes.headerContainer}>

                        <Typography
                            variant="h5"
                            className={classes.dashboardSubtitleBlue}
                        >
                            License Template
                        </Typography>
                    </div>
                    <LicenseWindow
                        classes={classes}
                        licenseType='template'
                        operationMode='view'
                        productId=''
                        licenseId={this.props.match.params.templateId}
                    />
                </div>
            </React.Fragment>
        );
    }
}
export default withStyles(Styles as any)(LicenseTemplate);
