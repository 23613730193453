import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link as NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper, Typography, Link,
    TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell,
} from '@material-ui/core';

import { AuthenticatedLink } from 'components/authenticatedLink/authenticatedLink';
import { getCustomLicensesBFI, DownloadContract, DownloadVideo } from 'services/cartService';
import { isBuyer, isSeller } from 'services/authService';
import Loading from 'components/loading/Loading';
import combineStyles from 'utils/combineStyles';
//import { LicenseTemplateModel } from 'models';

import Styles from 'pages/licenses/styles/LListStyles';
import DashStyles from 'styles/dashboard';
import { UserModel } from 'models';
import { ReactComponent as DownloadIcon } from 'assets/images/download-icon.svg';

interface OwnProps {
    classes: any,
    history: any
}

interface State {
    templates: any[],
    loading: boolean
}

interface StateProps {
    user: UserModel
}

type Props = OwnProps & StateProps

class CLlist extends React.Component<Props, State> {
    state = {
        templates: [],
        loading: true
    }

    async componentDidMount() {
        await getCustomLicensesBFI().then(
            (value) => { // On run  
                console.log(value)              
                this.setState({
                    templates: value,
                    loading: false
                })
            },
            (reason) => { // on fail
                console.log(reason)
            }
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment >
                <div className={classes.headerContainer}>
                    <Typography
                        className={classes.dashboardTitle}
                        variant="h4"
                    >
                        My signed licenses
                    </Typography>
                </div>

                {this.state.loading ? <Loading /> :

                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Movie Title</TableCell>
                                    {isBuyer(this.props.user) ? <TableCell>Movie File</TableCell> : <></>}
                                    {isBuyer(this.props.user) ? <TableCell>Licensor</TableCell> : <></>}
                                    {isSeller(this.props.user) ? <TableCell>Licensee</TableCell> : <></>}
                                    {isBuyer(this.props.user) ? <TableCell>Creation Date</TableCell> : <></>}
                                    {isSeller(this.props.user) ? <TableCell>Start Date</TableCell> : <></>}
                                    {isSeller(this.props.user) ? <TableCell>End Date</TableCell> : <></>}
                                    <TableCell>Contract</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={classes.tableBody}>
                                {this.state.templates.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            className={classes.noLicenses}
                                            colSpan={5}
                                        >
                                            No entries were found.
                                        </TableCell>
                                    </TableRow>
                                    :
                                    this.state.templates.map((row: any) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                <Link
                                                    className={classes.downloadLink}
                                                    component={NavLink}
                                                    to={`/catalogue/${row.vizziID}`}
                                                >
                                                    {row.title}
                                                </Link>
                                            </TableCell>

                                            {isBuyer(this.props.user) ?
                                                <TableCell>
                                                    <Link
                                                        className={classes.downloadLink}
                                                        href={DownloadVideo(row.vizziID)}
                                                    >
                                                        <DownloadIcon className={classes.downloadIcon} />
                                                        Download
                                                    </Link>
                                                </TableCell> : <></>
                                            }

                                            {isBuyer(this.props.user) ?
                                                <TableCell>{row.licensor}</TableCell> : <></>
                                            }

                                            {isSeller(this.props.user) ?
                                                <TableCell>{row.licensee}</TableCell> : <></>
                                            }

                                            {isBuyer(this.props.user) ? <TableCell>
                                                {moment(new Date(row.creationDate)).format('MM/DD/YYYY HH:mm')}
                                            </TableCell> : <></>}

                                            {isSeller(this.props.user) ? <TableCell>
                                                {moment(row.startDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm')}
                                            </TableCell> : <></>}

                                            {isSeller(this.props.user) ? <TableCell>
                                                {moment(row.endDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm')}
                                            </TableCell> : <></>}

                                            <TableCell>
                                                <AuthenticatedLink
                                                    className={classes.downloadLink}
                                                    url={DownloadContract(row.licenseID)}
                                                    filename={`Contract-${row.title.replace(/[ ]+/g, '-')}.pdf`}
                                                >
                                                    <DownloadIcon className={classes.downloadIcon} />
                                                    Download
                                                </AuthenticatedLink>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                        </Table>
                    </TableContainer>}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: any) => ({
    user: state.user
});

const combinedStyles = combineStyles(DashStyles, Styles);

export default connect(mapStateToProps)(withStyles(combinedStyles as any)(CLlist));