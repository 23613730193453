import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    IconButton,
    Grid,
    Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ExclusivityComponent from 'components/licenses/forms/fields/ExclusivityComponent';
import PeriodsComponent from 'components/licenses/forms/windows/PeriodsComponent';
import { CommercialRightModel, RightWindowModel } from 'models/LicenseWindowModel';
import {
    LicenseTemplateActions,
    LicenseWindowActions,
    CustomLicenseActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import CloseIcon from '@material-ui/icons/Close';
import Styles from 'components/licenses/styles/LicenseStyles';
import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';

interface OwnProps {
    classes?: any,
    rightName: string,
    subRightName: string,
    clauseName: string,
    licenseType: string,
    operationMode: string
    territoryId: string
}

interface DispatchProps {
    removeTerritoryEntryLW: (payload: any) => void,
    updateTerritoryLW: (payload: any) => void,
    removeTerritoryLW: (payload: any) => void,
    removeTerritoryEntryLT: (payload: any) => void,
    updateTerritoryLT: (payload: any) => void,
    removeTerritoryEntryCL: (payload: any) => void,
    updateTerritoryCL: (payload: any) => void
}

interface StateProps {
    clauseWindow: any,
    territoriesTemplate: any,
    customTerritories: any,
    templateTerritory: any,
    customTerritory: any
}

type Props = StateProps & DispatchProps & OwnProps

class TerritoryWindow extends React.Component<Props> {
    componentDidMount() {
        const { operationMode, territoriesTemplate } = this.props;

        if (operationMode === 'view') {
            for (let i = 0; i < territoriesTemplate.length; i++) {
                this.handleTerritoryInputChange(territoriesTemplate[i].territoryName, territoriesTemplate[i].territoryId, '');
            }
        }
    }

    wasTerritorySelected = (territoryId: string) => {
        let territory = '';

        if (this.props.licenseType === 'template')
            territory = this.props.territoriesTemplate.find(
                (element: any) => element.territoryId === territoryId
            ).territoryName
        else if (this.props.licenseType === 'custom') {
            territory = this.props.customTerritories.find(
                (element: any) => element.territoryId === territoryId
            ).territoryName
        }

        if (territory === '' || typeof territory === 'undefined' || territory === null)
            return 'hidden';
        else return 'visible';
    }

    getTerritory = (territoryId: string) => {
        if (this.props.licenseType === 'template') {
            const territoryTemplate = this.props.territoriesTemplate.find(
                (element: any) => element.territoryId === territoryId
            );

            return territoryTemplate?.territoryName;
        } else if (this.props.licenseType === 'custom') {
            const territoryCustom = this.props.customTerritories.find(
                (element: any) => element.territoryId === territoryId
            );

            return territoryCustom?.territoryName;
        }
        else return '';
    }

    getExclusivity = () => {
        if (this.props.licenseType === 'template') {
            if (this.props.templateTerritory.exclusivityAllowed && this.props.templateTerritory.nonExclusivityAllowed)
                return 'Exclusivity and Non-Exclusivity Allowed'
            else if (this.props.templateTerritory.exclusivityAllowed)
                return 'Exclusivity Allowed'
            else if (this.props.templateTerritory.nonExclusivityAllowed)
                return 'Non-Exclusivity Allowed'
        }
        else if (this.props.licenseType === 'custom') {
            if (this.props.templateTerritory.exclusivity)
                return 'Exclusive'
            else if (this.props.templateTerritory.nonExclusivityAllowed)
                return 'Non-Exclusive'
        }
    }

    RemoveTerritoryComponent = () => {
        const { classes, territoryId } = this.props;

        const handleOnCLick = () => {
            this.props.removeTerritoryEntryLW({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: territoryId
            })

            if (this.props.operationMode === 'create') {
                if (this.props.licenseType === 'template')
                    this.props.removeTerritoryEntryLT({
                        rightName: this.props.rightName,
                        subRightName: this.props.subRightName,
                        clauseName: this.props.clauseName,
                        territoryId: territoryId,
                        licenseType: this.props.licenseType,
                        operationMode: this.props.operationMode
                    })
                if (this.props.licenseType === 'custom')
                    this.props.removeTerritoryEntryCL({
                        rightName: this.props.rightName,
                        subRightName: this.props.subRightName,
                        clauseName: this.props.clauseName,
                        territoryId: territoryId
                    })
            }
        }

        return (<>
            <div className={classes.closeContainer}>
                <IconButton
                    size="small"
                    onClick={handleOnCLick}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </>)
    }

    handleTerritoryInputChange = (newValue: any, territoryId: any, reason: string) => {

        if (this.props.operationMode === 'create') {
            if (this.props.licenseType === 'template')
                this.props.updateTerritoryLT({
                    rightName: this.props.rightName,
                    subRightName: this.props.subRightName,
                    clauseName: this.props.clauseName,
                    territoryId: territoryId,
                    territoryName: newValue
                })
            if (this.props.licenseType === 'custom')
                this.props.updateTerritoryCL({
                    rightName: this.props.rightName,
                    subRightName: this.props.subRightName,
                    clauseName: this.props.clauseName,
                    territoryId: territoryId,
                    territoryName: newValue
                })
        }
        //Event type
        if (reason === 'clear')
            this.props.removeTerritoryLW({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: territoryId
            })
        else this.props.updateTerritoryLW({
            rightName: this.props.rightName,
            subRightName: this.props.subRightName,
            clauseName: this.props.clauseName,
            territoryId: territoryId,
            territoryName: newValue
        })
    }

    render() {
        const { classes, territoryId } = this.props;

        const territoryValue = this.getTerritory(territoryId);
        return (
            <Grid item container
                xs={12} sm={4}
                className={classes.territoryItemGrid}
            >
                <Grid item container className={classes.territoryItem}>
                    <Grid item container alignItems="flex-start">
                        <Grid container item xs={10} sm={10}>
                            {/* Territory */}
                            {this.props.operationMode === 'create' ? (<>
                                <AutocompleteInput
                                    id={territoryId}
                                    label="Territory"
                                    name="territory"
                                    options={this.props.clauseWindow.territoriesLeftToBeChosen}
                                    onChange={(event, newValue, reason) => {
                                        this.handleTerritoryInputChange(newValue, territoryId, reason);
                                    }}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) => option === value}
                                    value={territoryValue}
                                    placeholder="Search territory..."
                                    auth={false}
                                />
                            </>) : (<>
                                Territory:
                                {this.props.licenseType === 'template' ? this.props.templateTerritory?.territoryName : this.props.customTerritory?.territoryName}
                            </>)}

                        </Grid>
                        <Grid item xs={2} sm={2}>
                            {this.props.operationMode === 'create' ? (<>
                                <this.RemoveTerritoryComponent />
                            </>) : ''}
                        </Grid>
                    </Grid>

                    <Grid item className={classes.territoryOptionsContainer}>
                        <Box visibility={this.wasTerritorySelected(territoryId)}>
                            <div className={classes.territoryOptions}>
                                {/* Exclusivity */}
                                {this.props.operationMode === 'create' ? (<>
                                    <ExclusivityComponent
                                        rightName={this.props.rightName}
                                        subRightName={this.props.subRightName}
                                        clauseName={this.props.clauseName}
                                        territoryId={territoryId} // Territory input id
                                        territoryName={this.getTerritory(territoryId)}
                                        licenseType={this.props.licenseType}
                                    />
                                </>) : this.getExclusivity()}

                                {<PeriodsComponent
                                    rightName={this.props.rightName}
                                    subRightName={this.props.subRightName}
                                    clauseName={this.props.clauseName}
                                    territoryId={territoryId} // Territory input id
                                    territoryName={this.getTerritory(territoryId)}
                                    licenseType={this.props.licenseType}
                                    operationMode={this.props.operationMode}
                                />}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </ Grid>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const rightWindow = state.licenseWindow.openTabs.find(
        (element: RightWindowModel) => element.right === ownProps.rightName
    ) as CommercialRightModel;
    const clauseWindow = rightWindow !== undefined ? rightWindow[ownProps.subRightName].openTabs.find(
        (element: any) => element.clause === ownProps.clauseName
    ) : null;

    const territoriesTemplate = state.licenseTemplate.commercialRights[ownProps.rightName].subRights[ownProps.subRightName].clauses[ownProps.clauseName].territories;
    const customTerritories = state.customLicense.commercialRights[ownProps.rightName][ownProps.subRightName][ownProps.clauseName].territories;

    let templateTerritory, customTerritory;

    if (ownProps.licenseType === 'template') {
        templateTerritory = territoriesTemplate.find(
            (element: any) => element.territoryId === ownProps.territoryId
        )
    }
    else if (ownProps.licenseType === 'custom') {
        customTerritory = customTerritories.find(
            (element: any) => element.territoryId === ownProps.territoryId
        )
    }
    return {
        clauseWindow: clauseWindow,
        territoriesTemplate: territoriesTemplate,
        customTerritories: customTerritories,
        templateTerritory: templateTerritory,
        customTerritory: customTerritory
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        removeTerritoryEntryLW: LicenseWindowActions.removeTerritoryEntryLW,
        updateTerritoryLW: LicenseWindowActions.updateTerritoryLW,
        removeTerritoryLW: LicenseWindowActions.removeTerritoryLW,
        /* LicenseTemplate */
        removeTerritoryEntryLT: LicenseTemplateActions.removeTerritoryEntryLT,
        updateTerritoryLT: LicenseTemplateActions.updateTerritoryLT,

        /* CustomLicense */
        removeTerritoryEntryCL: CustomLicenseActions.removeTerritoryEntryCL,
        updateTerritoryCL: CustomLicenseActions.updateTerritoryCL
    }, dispatch)
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(Styles as any)(TerritoryWindow));