import React from 'react';
import {
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { Link as NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import AuthSplash from 'components/authSplash/AuthSplash';
import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import TextInput from 'components/controls/textInput/TextInput';
// import PhoneInput from 'components/phoneInput/PhoneInput';
import { OrgModel } from 'models';
import {
    register,
    checkEmail,
    checkUsername
} from 'services/authService';
import { getInstitutions } from 'services/userService';
import {
    cleanseInput,
    validateForm,
    validateInput,
    validateConfirmPassword
} from 'utils/validation';
import { normalizeMessage } from 'utils/utils';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LogoMFH from 'assets/logo/mfh-logo.jpg';

import AuthStyles from 'styles/auth';
import './RegisterPage.css';

interface OwnProps {
    classes: any,
    history: any,
}

interface State {
    form: {
        firstName: string,
        lastName: string,
        username: string,
        email: string,
        password: string,
        confirmPassword: string,
        institution: OrgModel,
        checkTerms: boolean
    },
    errors: {
        firstNameError: string,
        lastNameError: string,
        usernameError: string,
        emailError: string,
        passwordError: string,
        confirmPasswordError: string,
        institutionError: string,
        checkTermsError: string
    },
    showPassword: boolean,
    showConfirmPassword: boolean,
    formErrorMessage: string,
    institutionsList: OrgModel[]
}

class RegisterPage extends React.Component<OwnProps, State> {
    state = {
        form: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            institution: {
                orgid: '',
                orgname: '',
                role: ''
            },
            checkTerms: false
        },
        errors: {
            firstNameError: '',
            lastNameError: '',
            usernameError: '',
            emailError: '',
            passwordError: '',
            confirmPasswordError: '',
            institutionError: '',
            checkTermsError: ''
        },
        showPassword: false,
        showConfirmPassword: false,
        formErrorMessage: '',
        institutionsList: [] as OrgModel[]
    }


    async componentDidMount() {
        const institutions = await getInstitutions();
        const buyerInstitituions = institutions.filter(institution => institution.role === 'buyer');
        this.setState({ institutionsList: buyerInstitituions })
    }

    handleClickShowPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            showPassword: !this.state.showPassword
        }))
    };

    handleClickShowConfirmPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            showConfirmPassword: !this.state.showConfirmPassword
        }))
    };

    handleChange = (event: any) => {
        // Cleanse input
        const value = event.target.name === 'username' || event.target.name === 'email' ?
            cleanseInput(event.target.value, true) : cleanseInput(event.target.value);

        // Update form state
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [event.target.name]: value
            }
        }))
        this.setInputStateError(`${event.target.name}Error`, '')
    }

    handleCheckedChange = (event: any) => {
        // Update form state
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [event.target.name]: event.target.checked
            }
        }))
        this.setInputStateError(`${event.target.name}Error`, '')
    }

    handleInstitutionChange = (value: any) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                institution: value
            }
        }));
    }

    handleTelephoneChange = (value: string) => {
        // Update form state
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                telephone: value
            }
        }))
    }

    inputStyle = (error: string) => {
        const { classes } = this.props;
        const getelement = (errors: any, error: string) => {
            return errors[error]
        }
        const errors: any = getelement(this.state.errors, error);
        return errors === '' || errors === 'undefined' ?
            classes.inputTextfield :
            classes.inputTextfieldError;
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();

        // validate form
        const validation = validateForm(this.state.form);
        let formErrorMessage = '';

        if (!this.state.form.checkTerms) {
            formErrorMessage = 'You have not agreed to the terms and conditions of the website.';
            this.setState(prevState => ({
                ...prevState,
                formErrorMessage: formErrorMessage
            }));
            return;
        }

        if (validation === ' ') {
            console.log("Inputs Valid")

            try {
                const value = await register(this.state.form);
                console.log('User Registered');
                console.log(value);

                this.props.history.push("/");
                return;
            } catch (reason) {
                console.log('Registration failed', reason)
                console.log(reason)
                formErrorMessage = reason as string;
            }
        } else formErrorMessage = normalizeMessage(validation) + ' is invalid!';

        //Form error
        this.setState(prevState => ({
            ...prevState,
            formErrorMessage: formErrorMessage
        }))
    }
    //deasdfDDF34#
    //newtest1@gmail.com
    //newtest1

    setInputStateError = (errorName: string, errorValue: string) => {
        this.setState(prevState => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                [errorName]: errorValue
            }
        }))
    }

    onBlurHandle = async (name: string) => {
        // Validate
        const errorName = `${name}Error`;
        let errorValue = '';
        const getelement = (form: any, name: string) => {
            return form[name]
        }

        const inputValue = getelement(this.state.form, name);

        if (!(inputValue === '' || typeof inputValue === 'undefined')) { // If input is empty do nothing
            if (name === 'confirmPassword')
                errorValue = validateConfirmPassword(this.state.form.password, inputValue);
            else errorValue = validateInput(name, inputValue);

            if (errorValue === '') { // If no validation errors verify in the database
                if (name === 'username' && this.state.errors.usernameError === '') {
                    await checkUsername(this.state.form.username).then(
                        (value) => { // On run
                            if (value.message === 'Username already exists.') {
                                errorValue = 'Username aready exists.';
                            }
                        },
                        (reason) => { // on fail
                            console.log(reason)
                        });
                }
                else if (name === 'email' && this.state.errors.emailError === '') {
                    await checkEmail(this.state.form.email).then(
                        (value) => { // On run
                            if (value.message === 'Email already exists.') {
                                errorValue = 'Email already exists.';
                            }
                        },
                        (reason) => { // on fail
                            console.log(reason)
                        });
                }
            }
        }

        // Update new Error value
        this.setInputStateError(errorName, errorValue)
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.authFormContainer}>
                <div className={classes.authFormBox}>

                    <div className="sublogo-container-registration">
                        <img className="logo-mfh" src={LogoMFH} alt="Make Film History Logo" />
                    </div>

                    <div className="register-title-container">
                        <Typography component="h1" variant="h5" classes={{ root: classes.registerTitle }}>
                            Create an account
                        </Typography>
                        <p className="register-subtitle">to have the complete solution in your hands</p>
                    </div>
                    <Typography className={classes.authError} component="h1" variant="h6" style={{ color: 'red' }}>
                        {this.state.formErrorMessage}
                    </Typography>
                    <form onSubmit={this.handleSubmit}>
                        <Grid className={classes.formContainer} container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    id="firstName"
                                    className={`${this.inputStyle('firstNameError')}`}
                                    type="text"
                                    label="First Name"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    value={this.state.form.firstName}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="given-name"
                                    onBlur={() => this.onBlurHandle('firstName')}
                                    helperText={this.state.errors.firstNameError}
                                    error={this.state.errors.firstNameError !== ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    id="lastName"
                                    className={`${this.inputStyle('lastNameError')}`}
                                    type="text"
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    value={this.state.form.lastName}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="family-name"
                                    onBlur={() => this.onBlurHandle('lastName')}
                                    helperText={this.state.errors.lastNameError}
                                    error={this.state.errors.lastNameError !== ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    id="username"
                                    className={`${this.inputStyle('usernameError')}`}
                                    type="text"
                                    label="Username"
                                    name="username"
                                    placeholder="Choose your username"
                                    value={this.state.form.username}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="username"
                                    onBlur={() => this.onBlurHandle('username')}
                                    helperText={this.state.errors.usernameError}
                                    error={this.state.errors.usernameError !== ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    id="email"
                                    className={`${this.inputStyle('emailError')}`}
                                    type="email"
                                    label="Email Address"
                                    name="email"
                                    placeholder="Enter your email address"
                                    value={this.state.form.email}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="email"
                                    onBlur={() => this.onBlurHandle('email')}
                                    helperText={this.state.errors.emailError}
                                    error={this.state.errors.emailError !== ''}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AutocompleteInput
                                    id="institution"
                                    label="Institution"
                                    name="institution"
                                    value={this.state.form.institution}
                                    onChange={(event, newValue) => {
                                        this.handleInstitutionChange(newValue);
                                    }}
                                    getOptionLabel={(option: OrgModel) => option && option.orgname ? option.orgname : ''}
                                    getOptionSelected={(option, value) => option.orgname === value.orgname}
                                    options={this.state.institutionsList}
                                    placeholder="Select your institution"
                                    auth={true}
                                />
                                {/*<TextInput
                                        id="institution"
                                        label="Institution"
                                        name="institution"
                                        select
                                        defaultValue=""
                                        value={this.state.form.institution}
                                        setInputValue={this.handleChange}
                                        disabled={false}
                                        required={true}
                                        onBlur={() => this.onBlurHandle('institution')}
                                        helperText={this.state.errors.institutionError}
                                        error={this.state.errors.institutionError !== ''}
                                    >
                                        <MenuItem className="default-option-hidden" value={''} disabled>
                                            Select your institution
                                        </MenuItem>
                                        {this.state.institutionsList.map((institution) =>
                                            <MenuItem key={institution.orgid} value={institution.orgid}>{institution.orgname}</MenuItem>
                                        )}
                                        </TextInput>*/}
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextInput
                                    id="password"
                                    className={`${this.inputStyle('passwordError')}`}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password"
                                    name="password"
                                    placeholder="Choose your password"
                                    value={this.state.form.password}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="current-password"
                                    onBlur={() => this.onBlurHandle('password')}
                                    helperText={this.state.errors.passwordError}
                                    error={this.state.errors.passwordError !== ''}
                                    endAdornment={
                                        <InputAdornment
                                            classes={{ positionEnd: classes.positionEndRegister }}
                                            position="end"
                                        >
                                            <IconButton
                                                classes={{ root: classes.iconButton }}
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    id="confirmPassword"
                                    className={`${this.inputStyle('confirmPasswordError')}`}
                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    placeholder="Confirm your password"
                                    value={this.state.form.confirmPassword}
                                    setInputValue={this.handleChange}
                                    disabled={false}
                                    required={true}
                                    autoComplete="current-password"
                                    onBlur={() => this.onBlurHandle('confirmPassword')}
                                    helperText={this.state.errors.confirmPasswordError}
                                    error={this.state.errors.confirmPasswordError !== ''}
                                    fullWidth
                                    endAdornment={
                                        <InputAdornment
                                            classes={{ positionEnd: classes.positionEndRegister }}
                                            position="end"
                                        >
                                            <IconButton
                                                classes={{ root: classes.iconButton }}
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>

                            <Grid
                                className={classes.disclaimerContainer}
                                item xs={12} sm={12}
                            >
                                <FormControlLabel
                                    className={classes.termsCheckbox}
                                    label={<>I agree with the <Link className={classes.authLink} component={NavLink} to="/auth/termsAndConditions">terms and conditions</Link> of the MAP Marketplace.</>}
                                    control={
                                        <Checkbox
                                            checked={this.state.form.checkTerms}
                                            onChange={this.handleCheckedChange}
                                            name="checkTerms"
                                            color="primary"
                                        />
                                    }
                                />

                                <div className={classes.permissionsDisclaimer}>
                                    By creating an account, you verify that you are able to sign licences on behalf of your higher education institution.
                                </div>

                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="default"
                            className={classes.submit}
                        >
                            Register
                        </Button>

                        <div className={classes.loginLinkContainer}>
                            <Typography className={classes.authTxt}>
                                {"Already have an account?"}
                            </Typography>
                            <Link
                                className={classes.authLink}
                                component={NavLink}
                                to="/auth/login"
                            >
                                Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withStyles(AuthStyles as any)(RegisterPage);