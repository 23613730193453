import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Input,
    Button
} from '@material-ui/core';
//import { createProduct } from 'services/productService'
import { GenreModel, ProductModel } from 'models';
import Styles from 'styles/dashboard';

interface OwnProps {
    classes: any,
    history: any,
    match: any
}

interface State {
    product: ProductModel
}

class CreateProductPage extends React.Component<OwnProps, State> {
    state = {
        product: {
            _id: '',
            productid: -1,
            title: '',
            shorttitle: '',
            creationdate: '',
            poster: '',
            licensor: -1,
            licensetemplateid: '',
            director: '',
            year: 0,
            contentRating: '',
            duration: -1,
            genres: [] as GenreModel[],
            synopsis: '',
            language: '',
            movie: '',
            trailer: '',
            seller: ''
        }
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();

        // TODO: update create products endpoint
        /*await createProduct(this.state.product).then(
            () => { // On run                
                console.log('Created product')
                this.props.history.push("/catalogue/");
            },
            (reason) => { // on fail
                console.log(reason)
            });*/
    }

    handleChange = (event: any) => {
        this.setState((prevState) => ({
            ...prevState,
            product: {
                ...prevState.product,
                [event.target.name]: event.target.value
            }
        }))
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >
                <div>
                    <Grid container>
                        <Grid item container
                            xs={12}
                            sm={12}
                            md={12}
                            xl={12}>
                            <Typography
                                className={classes.dashboardTitle}
                                variant="h4"
                            >
                                Create Product
                            </Typography>
                        </Grid>
                        <Grid item container direction="column">
                            <form onSubmit={this.handleSubmit}>
                                {/* Insert product title */}
                                <Grid item>
                                    <FormControl>
                                        <InputLabel htmlFor="productTitle">Product Title</InputLabel>
                                        <Input id="productTitle" value={this.state.product.title} name="title" onChange={this.handleChange} />
                                    </FormControl>
                                </Grid>
                                {/* Insert product poster */}
                                <Grid item>
                                    <FormControl>
                                        <InputLabel htmlFor="productPoster">Product Poster</InputLabel>
                                        <Input id="productPoster" value={this.state.product.poster} name="poster" onChange={this.handleChange} />
                                    </FormControl>
                                </Grid>

                                {/* Submit */}
                                <Grid item className={classes.dashboardMargin}>
                                    <Button className={classes.dashboardButton} type="submit"> Submit </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(Styles as any)(CreateProductPage);