import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import Loading from 'components/loading/Loading';
import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import { LicenseTemplateModel } from 'models';
import { RootState } from 'redux/store';
import { createTemplateBFI } from 'services/licenseService';
import { getProduct } from 'services/productService';

import Styles from 'styles/dashboard';

interface OwnProps {
    classes: any,
    history: any,
    match: any
}

interface State {
    loading: boolean,
    disabledBtn: boolean,
    productTitle: string,
    licenseError: string
}

interface StateProps {
    licenseTemplate: LicenseTemplateModel
}

type Props = StateProps & OwnProps

class CreateLTemplatePage extends React.Component<Props, State> {
    state = {
        loading: true,
        disabledBtn: true,
        productTitle: '',
        licenseError: '',
    };

    async componentDidMount() {
        /* Runs after the render */
        await getProduct(this.props.match.params.id).then(
            (value) => { // On run
                console.log(value);
                this.setState({ productTitle: value.title, loading: false });
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    createLicenseTemplate = async () => {
        const productId = this.props.match.params.id;
        this.setState({ loading: true });

        /*await createTemplate(productId, this.props.licenseTemplate).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myLicenseTemplates");
                // window.location.reload();
            },
            (reason) => { // on fail
                console.log(reason)
            });*/

        await createTemplateBFI(productId).then(
            (value) => { // On run
                console.log(value);
                this.setState({ loading: false });
                this.props.history.push("/myLicenseTemplates");
                // window.location.reload();
            },
            (reason) => { // on fail
                this.setState({ loading: false, licenseError: reason });
                console.log(reason)
            });
    }

    setDisabledState = (disabled: boolean) => {
        if (this.state.disabledBtn !== disabled) {
            this.setState({ disabledBtn: disabled });
        }
    }

    render() {
        const { classes } = this.props;
        const productId = this.props.match.params.id;
        return (
            <div>
                <div className={classes.headerContainer}>

                    <Typography
                        variant="h5"
                        className={classes.dashboardSubtitleBlue}
                    >
                        Create License
                    </Typography>
                </div>

                {this.state.loading ?
                    <Loading /> : <></>}

                {this.state.loading ? <></> :
                    <Grid container>
                        <Grid item xs={12} md={12} >
                            <LicenseWindow
                                licenseType='template'
                                productId={productId}
                                licenseId=''
                                operationMode='create'
                                setDisabledState={this.setDisabledState.bind(this)}
                            />
                            <div className={classes.footerContainer}>
                                <NavLink className={classes.dashLinkBtn} to={`/movies/${productId}`}>
                                    <Button className={classes.secondaryBtn} variant="outlined">
                                        Back to Movie
                                    </Button>
                                </NavLink>

                                <Button
                                    className={classes.dashboardButton}
                                    variant="outlined"
                                    disableRipple
                                    disabled={this.state.disabledBtn}
                                    onClick={() => {
                                        this.createLicenseTemplate()
                                    }}
                                >
                                    Save Template
                                </Button>
                            </div>
                        </Grid>
                    </Grid>}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    licenseTemplate: state.licenseTemplate
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
    mapStateToProps
)(withStyles(Styles as any)(CreateLTemplatePage));