import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SearchPage from 'pages/search/Search'
import CustomizeLicensePage from 'pages/licenses/forms/CustomizeLicensePage'
import CreateLTemplatePage from 'pages/licenses/forms/CreateLTemplatePage'
import CreateProductPage from 'pages/products/CreateProductPage'
import CollectionsPage from 'pages/collections/CollectionsPage'
import ProductsPage from 'pages/products/ProductsPage'
import ProductPage from 'pages/product/ProductPage'
import ProfilePage from 'pages/profile/Profile';
import LTlist from 'pages/licenses/view/LTlist'
import CLlist from 'pages/licenses/view/CLlist'
import LicenseTemplate from 'pages/licenses/view/LicenseTemplate'
//import CustomLicense from 'pages/licenses/view/CustomLicense'
import { isLoggedIn } from 'services/authService'

const AppRouter = (props: any) => {

    if (!isLoggedIn()) {
        return <Redirect to={{ pathname: "/auth/login" }} />;
    }
    
    return (
        <Switch>
            {/* Movie Collections */}
            <Route path="/catalogue/:id" component={ProductsPage} />
            <Route path="/catalogue" component={CollectionsPage} />

            {/* Movies */}
            <Route path="/movies/create" component={CreateProductPage} />
            <Route path="/movies/:id/createLicense" component={CreateLTemplatePage} />
            <Route path="/movies/:id/customizeLicense" component={CustomizeLicensePage} />
            <Route path="/movies/:id" component={ProductPage} />
            <Route path="/movies/:productId/myLicenseTemplates/:templateId" component={LicenseTemplate} />
            {/*<Route path="/movies" component={ProductsPage} />*/}

            <Route path="/searchResults" component={SearchPage} />
            <Route path="/profile" component={ProfilePage} />
            {/*<Route path="/movies/:productId/myLicenseTemplates/:templateId" component={LicenseTemplate} />*/}

            {/* Licenses */}
            <Route path="/myLicenseTemplates/:templateId" component={LicenseTemplate} />
            <Route path="/myLicenseTemplates" component={LTlist} />
            {/*<Route path="/myCustomLicenses/:id" component={CustomLicense} />*/}
            <Route path="/myCustomLicenses" component={CLlist} />

            <Redirect to={{ pathname: "/catalogue" }} />;
        </Switch>
    );
}

export default AppRouter;
