import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Link } from "@material-ui/core";

import { logout } from 'services/authService';
import { UserActions } from 'redux/actions';
import { UserModel } from 'models';
import { isSeller } from 'services/authService'
import MLogout from 'components/logout/Logout';

import PersonIcon from '@material-ui/icons/Person';
import MovieIcon from '@material-ui/icons/Movie';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';

interface DropdownOptionsProps {
    user: UserModel;
    homePage: string;
    blockchain: boolean;
    updateUser: any;
    setToggle: any;
}

const useStyles = makeStyles({
    dropdownIcon: {
        fontSize: 24,
        width: 50
    },
    dropdownItem: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "15px 25px",
        clear: "both",
        whiteSpace: "nowrap",
        border: 0,
        color: "#7A7A7A",
        fontSize: 16,
        fontWeight: 500,
        "&:hover": {
            textDecoration: "none",
            color: "#2B37EE",
            backgroundColor: "#F0F0F0",
            border: "none",
            outline: "none"
        },
        "&:hover svg": {
            color: 'rgba(43,55,238,0.8)',
        },
        "&:focus": {
            textDecoration: "none",
            color: "#2B37EE",
            backgroundColor: "#F0F0F0",
            border: "none",
            outline: "none"
        },
        "&:active": {
            color: "#2B37EE",
            backgroundColor: "#F0F0F0",
            border: "none",
            outline: "none"
        }
    }
});

function DropdownOptions(props: DropdownOptionsProps) {
    const classes = useStyles();

    return (
        <>
            <Link
                className="dropdown-item"
                classes={{ root: classes.dropdownItem }}
                onClick={(e: any) => props.setToggle(false)}
                component={NavLink}
                activeClassName="active"
                to="/catalogue"
            >
                <MovieIcon classes={{ root: classes.dropdownIcon }} />
                {isSeller(props.user) ? "My collections" : "Collections"}
            </Link>

            {isSeller(props.user) ?
                <Link
                    className="dropdown-item"
                    classes={{ root: classes.dropdownItem }}
                    onClick={(e: any) => props.setToggle(false)}
                    component={NavLink}
                    activeClassName="active"
                    to="/myLicenseTemplates"
                >
                    <AssignmentTurnedInIcon classes={{ root: classes.dropdownIcon }} />
                    My bespoke licenses
                </Link>
                : <></>}

            <Link
                className="dropdown-item"
                classes={{ root: classes.dropdownItem }}
                onClick={(e: any) => props.setToggle(false)}
                component={NavLink}
                activeClassName="active"
                to="/myCustomLicenses"
            >
                <AssignmentIcon classes={{ root: classes.dropdownIcon }} />
                My signed licenses
            </Link>

            <Link
                className="dropdown-item"
                classes={{ root: classes.dropdownItem }}
                onClick={(e: any) => props.setToggle(false)}
                component={NavLink}
                activeClassName="active"
                to="/profile"
            >
                <PersonIcon classes={{ root: classes.dropdownIcon }} />
                Profile
            </Link>

            <Link
                href="#"
                className="dropdown-item"
                classes={{ root: classes.dropdownItem }}
                onClick={() => {
                    logout();
                }}
            >
                <MLogout />
                Sign out
            </Link>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    homePage: state.homePage,
    blockchain: state.blockchain
});

const mapActionsToProps = {
    updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(DropdownOptions);
