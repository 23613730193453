import { createStyles } from '@material-ui/styles';

const useStyles = createStyles({
    moviePosterContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    moviePoster: {
        maxWidth: '100%',
        maxHeight: 390
    },
    movieOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& >*': {
            marginRight: 15,
            marginLeft: 15,
        }
    },
    movieOption: {
        minWidth: 150,
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#E5E5E5',
        color: '#7A7A7A',
        border: '2px solid #7A7A7A',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
        '&:hover': {
            color: 'white',
            backgroundColor: '#7A7A7A'
        },
        '@media (max-width: 1800px)': {
            minWidth: 140
        },
        '@media (max-width: 1600px)': {
            minWidth: 120,
            '& .MuiSvgIcon-root': {
                display: 'none'
            }
        }
    },
    optionDescription: {
        marginLeft: 8,
        '@media (max-width: 1600px)': {
            marginLeft: 0
        }
    },
    productOptions: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        '& >*': {
            marginLeft: 24
        }
    },

    /* Video Popup */
    popupContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 15
    },
    videoHeader: {
        marginBottom: 30
    },
    closeButton: {
        position: 'absolute',
        top: 15,
        right: 20
    },
    videoContainer: {
        '& .video-js': {
            width: '60vw',
            height: '60vh'
        }
    },
    closeButtonFooter: {
        minWidth: 160,
        height: 40,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: '#FFFFFF',
        color: '#7A7A7A',
        border: '2px solid #7A7A7A',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
        '&:hover': {
            color: 'white',
            backgroundColor: '#7A7A7A'
        }
    },
    productNotFoundTitle: {
        fontSize: 50,
        color: 'rgb(43, 55, 238)',
        fontWeight: 700,
    },
    productNotFoundTxt: {
        marginTop: 30,
        fontSize: 35,
        color: '#7A7A7A',
        fontWeight: 500,
    },
    productNotFound: {
        textAlign: 'center'
    },
    licenseError: {
        marginTop: -40,
        fontSize: '1rem',
        paddingBottom: 20,
    },
});

export default useStyles;