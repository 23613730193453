import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { isLoggedIn, logout } from 'services/authService';
import { getUser } from 'services/userService';
import { UserModel } from 'models';
import Dropdown from 'templates/dropdown/Dropdown';
import Search from 'templates/search/Search';
import { UserActions } from 'redux/actions';
import { RootState } from 'redux/store';

//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeaderStyles from './HeaderStyles';

interface OwnProps {
    classes?: any,
    history: any,
    open: boolean,
    handleDrawerOpen: (payload: any) => void
}

interface DispatchProps {
    updateUser: (payload: any) => void
}

interface StateProps {
    user: UserModel
}

type Props = StateProps & DispatchProps & OwnProps

class Header extends React.Component<Props> {

    async componentDidMount() {
        await getUser().then(
            (value) => { // On run
                this.props.updateUser(value)
            },
            (/*reason*/) => { // on fail
                //console.log(reason)
                const accessToken = localStorage.getItem('accessToken');
                console.log(accessToken)
                if(accessToken){
                    logout();
                }

            });
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >
                {isLoggedIn() ?
                    <AppBar position="absolute"
                        className={clsx(classes.appBar, this.props.open && classes.appBarShift)}
                    >
                        <Toolbar className={classes.toolbar}>
                            <Typography className={classes.categoryDropdown}>
                                {/*<span className="navbar-categories">
                                    Category
                                </span>

                                <span className="expand-more">
                                    <ExpandMoreIcon
                                        classes={{ root: classes.expandIcon }}
                                        fontSize="inherit"
                                    />
                                </span>*/}
                            </Typography>

                            <Search history={this.props.history} />
                            <Dropdown />
                        </Toolbar>
                    </AppBar>
                    : <></>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ updateUser: UserActions.updateUser }, dispatch)
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>
    (mapStateToProps, mapDispatchToProps)(withStyles(HeaderStyles)(Header));