import { createReducer } from '@reduxjs/toolkit'
import { CustomClauseModel, CustomLicenseModel, CustomTerritoryModel, PeriodModel } from 'models'
import { CustomLicenseActions, Action } from 'redux/actions'
import {
    commercialRightsDB
} from 'database/rightsDB'
import {
    DeliveryType,
    EducationalLicenseType,
    PeriodType
} from 'models/enums'
import { dateToString } from 'utils/validation';
import { add } from 'date-fns'
const {
    updateCL,
    removeRightEntryCL,
    saveRightEntryCL,
    removeClauseEntryCL,
    saveClauseEntryCL,
    addTerritoryEntryCL,
    removeTerritoryEntryCL,
    updateTerritoryCL,
    updateExclusivityCL,
    updatePeriodCL,
    removePeriodCL,
    updateEduPeriodCL,
    updateEduTypeCL,
    updateEduInstitutionCL,
    updateEduPeriodFormatCL
} = CustomLicenseActions

const inicialCustomLicense: CustomLicenseModel = {
    customLicenseId: '',
    licenseTemplateId: '',
    state: '', // inCart, sold
    licensee: '',
    creationDate: '',
    licensor: '',
    vizziID: '',
    filmTitle: '',
    commercialRights: {
        'Exhibitions': {
            'Cinematic Rights': {
                'Theatrical': {
                    territories: []
                },
                'NonTheatrical': {
                    territories: []
                },
                'Open': {
                    territories: []
                }
            },
            'Ancilliary Rights': {
                "Airline": {
                    territories: []
                },
                "Hotel": {
                    territories: []
                },
                "Ship": {
                    territories: []
                },
                "Train": {
                    territories: []
                },
                "Commercial Vehicle": {
                    territories: []
                }
            },
        },
        'TV': {
            'Distribution Channels': {
                'Cable': {
                    territories: []
                },
                'Satellite': {
                    territories: []
                },
                'Terrestrial': {
                    territories: []
                },
                'Catch-Up': {
                    territories: []
                }
            },
            'Subscription Methods': {
                "Free": {
                    territories: []
                },
                "Basic": {
                    territories: []
                },
                "Premium": {
                    territories: []
                }
            },
        },
        'VOD': {
            'Type': {
                'AdVOD': {
                    territories: []
                },
                'FVOD': {
                    territories: []
                },
                'SVOD': {
                    territories: []
                },
                'TVOD': {
                    territories: []
                },
                'Internet Streaming': {
                    territories: []
                }
            },
        }
    },
    nonCommercialRights: {
        'Educational': {
            institutionName: '',
            type: EducationalLicenseType.COURSE,
            periodFormat: PeriodType.ONE_YEAR,
            period: {
                periodId: '',
                start: dateToString(add(new Date(), { days: 1 })), //default is tomorrow
                end: dateToString(add(new Date(), { days: 1, years: 1})), // default is tomorrow +1 year
            }
        }
    },
    deliveryMethod: DeliveryType.DIGITAL_TAPE
}



const customLicenseReducer = createReducer(inicialCustomLicense, (builder) => {
    builder
        /* License Template */
        .addCase(updateCL, (state: any, action: Action) => {
            state = action.payload
            const {
                customLicenseId,
                licenseTemplateId,
                licensor,
                licensee,
                creationDate,
                commercialRights,
                nonCommercialRights,
                vizziID,
                deliveryMethod,
                filmTitle
            } = action.payload.licenseTemplate;
            state.customLicenseId = customLicenseId;
            state.licenseTemplateId = licenseTemplateId;
            state.licensee = licensee;
            state.licensor = licensor;
            state.state = action.payload.licenseTemplate.state;
            state.creationDate = creationDate;
            state.commercialRights = commercialRights;
            state.nonCommercialRights = nonCommercialRights;
            state.vizziID = vizziID;
            state.deliveryMethod = deliveryMethod;
            state.filmTitle = filmTitle;
        })

        /* Rights */
        .addCase(removeRightEntryCL, (state: any, action: Action) => {
            const { rightName } = action.payload;
            const emptyPeriod: PeriodModel = { 
                periodId: '', 
                start: dateToString(add(new Date(), { days: 1 })), //default is tomorrow
                end: dateToString(add(new Date(), { days: 1, years: 1})), // default is tomorrow +1 year
            };

            if (rightName === 'Educational') {
                const edu = state.nonCommercialRights['Educational'];
                edu.institution = '';
                edu.period = emptyPeriod;
                edu.type = EducationalLicenseType.COURSE;
                edu.saved = false;
            }
            else {
                const subRights = Object.keys(state.commercialRights[rightName]);

                for (let i = 0; i < subRights.length; i++) {
                    const clauses = commercialRightsDB[rightName][subRights[i]];
                    for (let j = 0; j < clauses.length; j++) {
                        const clause = state.rights[rightName][subRights[i]][clauses[j]];
                        clause.territories.splice(0, clause.territories.length)
                    }
                }
            }

        })

        .addCase(saveRightEntryCL, (state: any, action: Action) => {
            const { rightName } = action.payload;

            if (rightName === 'Educational') {
                state.nonCommercialRights[rightName].saved = true;
            }
        })

        /* Clauses */
        .addCase(removeClauseEntryCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName } = action.payload;

            const clause = state.commercialRights[rightName][subRightName][clauseName];

            // Empty array
            clause.territories.splice(0, clause.territories.length);
            clause.saved = false;
        })

        .addCase(saveClauseEntryCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName } = action.payload;

            const clause = state.commercialRights[rightName][subRightName][clauseName];
            clause.saved = true;
        })

        /* Territories Entries */
        .addCase(addTerritoryEntryCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId } = action.payload;

            const clause: CustomClauseModel = state.commercialRights[rightName][subRightName][clauseName];

            const territories = clause.territories;
            territories.push({
                territoryId: territoryId,
                territory: '',
                exclusivity: false,
                nonExclusivity: false,
                periods: []
            })
        })
        .addCase(removeTerritoryEntryCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId } = action.payload;

            const clause = state.commercialRights[rightName][subRightName][clauseName];
            const territories = clause.territories;

            const terrToRemove = territories.find(
                (element: CustomTerritoryModel) => element.territoryId === territoryId
            )

            territories.splice(territories.indexOf(terrToRemove), 1);
        })

        /* Territory Input */
        .addCase(updateTerritoryCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, territoryName } = action.payload;

            state.commercialRights[rightName][subRightName][clauseName].territories.find(
                (element: CustomTerritoryModel) => element.territoryId === territoryId
            ).territoryName = territoryName;
        })

        /* Exclusivity Input */
        .addCase(updateExclusivityCL, (state: any, action: Action) => {
            const { rightName, subRightName, clauseName, territoryId, exclusivity, nonExclusivity } = action.payload;

            const territory = state.commercialRights[rightName][subRightName][clauseName].territories.find(
                (element: CustomTerritoryModel) => element.territoryId === territoryId
            )

            territory.exclusivity = exclusivity;
            territory.nonExclusivity = nonExclusivity;
        })

        /* Period Input */
        .addCase(updatePeriodCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, periodId, period } = action.payload;
            const right = state.commercialRights[rightName]
            let periods: PeriodModel[];

            // Get periods pointer
            if (rightName === 'Educational') {
                periods = state.nonCommercialRights[rightName].periods
            }
            else {
                const territories = right[subRightName][clauseName].territories;
                periods = territories.find(
                    (element: CustomTerritoryModel) => element.territoryId === territoryId
                ).periods;
            }

            const bp = periods.find(
                (element: PeriodModel) => element.periodId === periodId
            )

            // Check if Period already exists
            if (bp === undefined) {
                // Does not exist yet, creating
                periods.push({
                    periodId: periodId,
                    start: period.start,
                    end: period.end
                })
            }
            else {
                // Period exists, updating
                bp.start = period.start;
                bp.end = period.end;
            }
        })
        .addCase(removePeriodCL, (state: any, action: Action) => {
            const { clauseName, rightName, subRightName, territoryId, periodId } = action.payload;
            let periods: PeriodModel[];
            
            if (rightName === 'Educational') {
                periods = state.nonCommercialRights[rightName].periods
            }
            else {
                const territories = state.commercialRights[rightName][subRightName][clauseName].territories;
                periods = territories.find(
                    (element: CustomTerritoryModel) => element.territoryId === territoryId
                ).periods;
            }
            const bp = periods.find(
                (element: PeriodModel) => element.periodId === periodId
            )
            // Check if Period already exists
            if (bp !== undefined) {
                periods.splice(periods.indexOf(bp), 1);
            }
        })

        /* Education Only */
        .addCase(updateEduPeriodCL, (state: any, action: Action) => {
            const { rightName, period } = action.payload;
            state.nonCommercialRights[rightName].period = period
        })
        .addCase(updateEduTypeCL, (state: any, action: Action) => {
            const { rightName, type } = action.payload;
            state.nonCommercialRights[rightName].type = type
        })

        .addCase(updateEduInstitutionCL, (state: any, action: Action) => {
            const { rightName, institution } = action.payload;
            state.nonCommercialRights[rightName].institutionName = institution
        })
        .addCase(updateEduPeriodFormatCL, (state: any, action: Action) => {
            const { rightName, periodFormat } = action.payload;
            state.nonCommercialRights[rightName].periodFormat = periodFormat
        })

})

export default customLicenseReducer;