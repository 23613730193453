import React from 'react';
import { withStyles } from '@material-ui/styles';
import AuthSplash from 'components/authSplash/AuthSplash';
import AuthStyles from 'styles/auth';

interface OwnProps {
    classes?: any;
    history?: any;
    children: any;
}

class AuthLayout extends React.Component<OwnProps> {

    render() {
        const { classes, children } = this.props;

        return (
            <div className={classes.authContainer}>
                <AuthSplash />

                {children}
            </div>
        );
    }
}

export default withStyles(AuthStyles as any)(AuthLayout);