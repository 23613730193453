import { baseUrl, api } from 'services/customAxios'
import jwt_decode from "jwt-decode";

const getCollections = async () => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/collections', {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

const getCollectionById = async (collectionId: string) => {
    // Get UserId
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken)
        return Promise.reject('User not logged in')

    const sessionToken: any = jwt_decode(accessToken !== undefined ? accessToken : '');

    return await api.get(baseUrl + '/collections/' + collectionId, {
        params: {
            userid: sessionToken.userid
        }
    })
        .then(res => {
            return res.data.collection;
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error);
        })
}

export {
    getCollections,
    getCollectionById
}