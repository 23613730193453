import { Action, ACTION_TYPES } from "../actions";
const inicialUserState = {
    userid: '',
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    role: ""
};



const userReducer = (state = inicialUserState, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.USER.UPDATE_USER:
            return { ...action.payload };
        default:
            return state;
    }
};

export default userReducer;