import React from 'react';
import {
    Typography,
    Button,
    Link,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { Link as NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

import TextInput from 'components/controls/textInput/TextInput';
import { login, resendEmail } from 'services/authService';
import { cleanseInput } from 'utils/validation';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EmailAdorn from 'assets/images/email-adorn.png';
import PasswordAdorn from 'assets/images/pass-adorn.png';
import LogoMFH from 'assets/logo/mfh-logo.jpg';

import AuthStyles from 'styles/auth';
import './LoginPage.css';

interface OwnProps {
    history: any;
    classes: any;
}

interface State {
    form: {
        username: string,
        password: string
    },
    errors: {
        [key: string]: any,
        usernameError: string,
        passwordError: string
    },
    verificationError: boolean,
    showPassword: boolean,
    formErrorMessage: string
}

class LoginPage extends React.Component<OwnProps, State> {
    state = {
        form: {
            username: '',
            password: ''
        },
        errors: {
            usernameError: '',
            passwordError: ''
        },
        showPassword: false,
        verificationError: false,
        formErrorMessage: ''
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();

        this.setState({
            formErrorMessage: ''
        });

        let error = false;

        // validate form
        let formErrorMessage = '';
        let verificationError = false;
        console.log("Inputs Valid")
        await login(this.state.form.username, this.state.form.password).then(
            () => { // On run
                formErrorMessage = ''
                this.props.history.push("/");
                // window.location.replace("/catalogue");
                console.log('Logged in')
                // window.location.reload()
            },
            (reason) => { // on fail
                error = true;
                console.log('Loggin failed')
                //console.log(reason.response.data.message)
                // se a razão for password invalid, alterar o erro da password
                formErrorMessage = reason ? reason.response.data.message : 'Network error. Please try again later.';
                if (reason.response.data.verificationError) {
                    verificationError = reason.response.data.verificationError;
                }
                return;
            });

        if (error) {
            //Form error
            this.setState(prevState => ({
                ...prevState,
                formErrorMessage: formErrorMessage,
                verificationError: verificationError
            }))
        }
    }
    //newtest1

    handleChange = (event: any) => {
        // Update errors
        const errorName = `${event.target.name}Error`;

        // Cleanse input
        const value = event.target.name === 'username' ?
            cleanseInput(event.target.value, true) :
            cleanseInput(event.target.value);

        // Update form state
        this.setInputState(event.target.name, value);

        // Validate
        const errorValue = '';

        // Update new Error value
        this.setInputStateError(errorName, errorValue)
    }

    handleClickShowPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            showPassword: !this.state.showPassword
        }))
    };

    setInputState = (name: string, value: string) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    }

    setInputStateError = (errorName: string, errorValue: string) => {
        this.setState(prevState => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                [errorName]: errorValue
            }
        }))
    }

    inputStyle = (error: string) => {
        const getelement = (errors: any, error: string) => {
            return errors[error]
        }
        const { classes } = this.props;

        const errors: any = getelement(this.state.errors, error);
        return errors === '' || typeof errors === 'undefined' ?
            classes.inputTextfield :
            classes.inputTextfieldError;
    }

    resendEmail = async (event: any) => {
        event.preventDefault();

        toast.promise(
            resendEmail(this.state.form.username), {
            pending: 'Sending email...',
            success: 'Email sent with success',
            error: 'Error sending email...'
        }
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.authFormContainer}>
                <div className={classes.authFormBox}>
                    <div className="sublogo-container-login">
                        <img className="logo-mfh" src={LogoMFH} alt="Make Film History Logo" />
                    </div>
                    <Typography component="h1" variant="h5" classes={{ root: classes.loginTitle }}>
                        Welcome back
                    </Typography>
                    {this.state.formErrorMessage ? (
                        <Typography className={classes.authError} component="h1" variant="h6" style={{ color: 'red' }}>
                            {this.state.formErrorMessage}
                            {this.state.verificationError ?
                                <>
                                    <br />
                                    Didn&apos;t get the email?{' '}
                                    <Link
                                        href="#"
                                        classes={{ root: classes.authLink }}
                                        onClick={this.resendEmail.bind(this)}
                                    >
                                        Resend email
                                    </Link>
                                </>
                                : <></>}
                        </Typography>) : <></>}
                    <form className="auth-form" onSubmit={this.handleSubmit}>
                        <TextInput
                            type="text"
                            label="Email Address / Username"
                            name="username"
                            placeholder="Enter your email address or username"
                            value={this.state.form.username}
                            setInputValue={this.handleChange.bind(this)}
                            disabled={false}
                            required={true}
                            autoComplete="email"
                            startAdornment={
                                <InputAdornment
                                    classes={{ positionStart: classes.positionStart }}
                                    position="start"
                                >
                                    <img src={EmailAdorn} alt="Email Adornment" />
                                </InputAdornment>
                            }
                        />

                        <TextInput
                            type={this.state.showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            placeholder="Enter your password"
                            value={this.state.form.password}
                            setInputValue={this.handleChange.bind(this)}
                            disabled={false}
                            required={true}
                            autoComplete="current-password"
                            onChange={this.handleChange}
                            startAdornment={
                                <InputAdornment
                                    classes={{ positionStart: classes.positionStart }}
                                    position="start"
                                >
                                    <img src={PasswordAdorn} alt="Password Adornment" />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment
                                    classes={{ positionEnd: classes.positionEndLogin }}
                                    position="end"
                                >
                                    <IconButton
                                        classes={{ root: classes.iconButton }}
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={(event) => { event.preventDefault() }}
                                    >
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                        {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />*/}

                        <div className="auth-options">
                            <Button
                                classes={{
                                    root: classes.submit
                                }}
                                type="submit"
                                variant="contained"
                                color="default"
                            >
                                Login
                            </Button>

                            {/*<Link
                                    classes={{ root: classes.forgotLink }}
                                    component={NavLink}
                                    to="/auth/resetPassword"
                                >
                                    Forgot your password?
                                </Link>*/}
                        </div>

                        <div className={classes.signUpLinkContainer}>
                            <Typography className={classes.authTxt}>
                                {"Don't have an account?"}
                            </Typography>
                            <Link
                                classes={{ root: classes.authLink }}
                                component={NavLink}
                                to="/auth/register"
                            >
                                Register
                            </Link>
                        </div>
                    </form>
                </div>
            </div>);
    }
}

export default withStyles(AuthStyles as any)(LoginPage);