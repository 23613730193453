import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Typography,
    Grid,
    ListItemIcon,
    IconButton,
} from '@material-ui/core';
import PeriodComponent from '../fields/PeriodComponent'
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { LicenseTemplateActions, LicenseWindowActions, CustomLicenseActions } from 'redux/actions'
import { bindActionCreators } from 'redux'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { RootState } from 'redux/store'
import md5 from 'md5';
import {
    CommercialRightModel,
    RightWindowModel
} from 'models/LicenseWindowModel';
import Styles from 'components/licenses/styles/LicenseStyles';

interface OwnProps {
    classes?: any,
    rightName: string,
    subRightName: string,
    clauseName: string,
    territoryId: string,
    territoryName: string,
    licenseType: string
    operationMode: string
}

interface DispatchProps {
    addBlockedPeriodEntryLW: (payload: any) => void,
    removeBlockedPeriodEntryLW: (payload: any) => void,
    removeBlockedPeriodLT: (payload: any) => void,
    removePeriodCL: (payload: any) => void
}

interface StateProps {
    blockedPeriods: any,
}

type Props = StateProps & DispatchProps & OwnProps

class PeriodsComponent extends React.Component<Props> {

    AddPeriodComponent = () => {
        const { classes, addBlockedPeriodEntryLW } = this.props;

        const handleAddPeriodOnCLick = () => {
            const id = md5('' + new Date().getTime())
            addBlockedPeriodEntryLW({
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                blockedPeriodId: id
            })
        }

        return (<>
            <IconButton
                /*variant="contained"
                size="small"*/
                onClick={handleAddPeriodOnCLick}
            >
                <AddIcon className={classes.plusIcon} />
            </IconButton>
        </>)
    }

    RemovePeriodComponent = (props: any) => {
        const { blockedPeriodId } = props;
        const { classes } = this.props;

        const handleRemovePeriodOnClick = () => {
            const payload = {
                rightName: this.props.rightName,
                subRightName: this.props.subRightName,
                clauseName: this.props.clauseName,
                territoryId: this.props.territoryId,
                periodId: blockedPeriodId
            };

            //Remove from license template state
            if (this.props.licenseType === 'template')
                this.props.removeBlockedPeriodLT(payload)
            if (this.props.licenseType === 'custom')
                this.props.removePeriodCL(payload)
            // Remove from window
            this.props.removeBlockedPeriodEntryLW(payload)
        }

        return (<>
            <ListItemIcon>
                <IconButton
                    size="small"
                    className={classes.removeBlockedPeriodButton}
                    onClick={handleRemovePeriodOnClick}
                >
                    <HighlightOffIcon className={classes.periodRemoveStyle} />
                </IconButton>
            </ListItemIcon>
        </>)
    }

    render() {
        const { classes, blockedPeriods } = this.props;

        return (<>
            <Typography align="left" className={classes.territoryLabel}>
                {
                    this.props.licenseType === 'template' ? 'Blocked Periods:' :
                        'Periods:'
                }
                {this.props.operationMode === 'create' ? <this.AddPeriodComponent /> : <></>}
            </Typography>

            {blockedPeriods ? blockedPeriods.map((item: any) => {
                return (
                    <Grid key={item} container className={classes.blockedPeriodsContainer}>
                        <Grid item xs={10} sm={10} md={10}>
                            <PeriodComponent
                                rightName={this.props.rightName}
                                subRightName={this.props.subRightName}
                                clauseName={this.props.clauseName}
                                territoryId={this.props.territoryId}
                                territoryName={this.props.territoryName}
                                periodId={item}
                                licenseType={this.props.licenseType}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} >
                            <this.RemovePeriodComponent blockedPeriodId={item} />
                        </Grid>
                    </Grid>
                );
            }) : ''}
        </>)
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {

    const rightWindow = state.licenseWindow.openTabs.find(
        (element: RightWindowModel) => element.right === ownProps.rightName
    ) as CommercialRightModel;
    const clauseWindow = rightWindow !== undefined ? rightWindow[ownProps.subRightName].openTabs.find(
        (element: any) => element.clause === ownProps.clauseName
    ) : null;

    const clauseTemplate = state.licenseTemplate.commercialRights[ownProps.rightName].subRights[ownProps.subRightName].clauses[ownProps.clauseName];

    let blockedPeriods;
    if (ownProps.operationMode === 'create') {
        blockedPeriods = clauseWindow.territories.find(
            (element: any) => element.territoryId === ownProps.territoryId
        )?.blockedPeriods;
    } else if (ownProps.operationMode === 'view') {
        blockedPeriods = clauseTemplate.territories.find(
            (element: any) => element.territoryId === ownProps.territoryId
        )?.blockedPeriods;
    }

    return {
        blockedPeriods: blockedPeriods
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        addBlockedPeriodEntryLW: LicenseWindowActions.addBlockedPeriodEntryLW,
        removeBlockedPeriodEntryLW: LicenseWindowActions.removeBlockedPeriodEntryLW,

        /* LicenseTemplate */
        removeBlockedPeriodLT: LicenseTemplateActions.removeBlockedPeriodLT,

        /* Custom License */
        removePeriodCL: CustomLicenseActions.removePeriodCL
    }, dispatch)
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(Styles as any)(PeriodsComponent));