import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import { DurationMark } from 'models';

interface OwnProps {
    classes?: any,
    value: number[],
    step: number,
    minValue: number,
    maxValue: number,
    marks: DurationMark[],
    handleChange: (e: any, newValue: number | number[]) => void,
    handleChangeCommit: (e: any, newValue: number | number[]) => void
}

const useStyles = makeStyles({
    slider: {
        maxWidth: 200,
        marginTop: 5,
        marginBottom: 15
    },
    valueLabel: {
        top: -35,
        left: 'calc(-50% - 6px)',
        color: '#11171d',
        opacity: 0.6,
        fontWeight: 400,
        fontSize: 12,
        '& >*': {
            width: 35,
            height: 35,
            '& >*': {
                color: 'white'
            }
        }
    },
    sliderThumb: {
        width: 14,
        height: 14,
        marginTop: -6,
        marginLeft: -7,
        backgroundColor: 'white',
        border: '1px solid currentColor'
    }
});

export default function MSlider(props: OwnProps) {
    const classes = useStyles();

    return (
        <Slider
            classes={{ root: classes.slider, valueLabel: classes.valueLabel, thumb: classes.sliderThumb }}
            value={props.value}
            onChange={(e: any, newValue: number | number[]) => props.handleChange(e, newValue)}
            onChangeCommitted={(e: any, newValue: number | number[]) => props.handleChangeCommit(e, newValue)}
            min={props.minValue}
            max={props.maxValue}
            step={props.step}
            marks={props.marks}
            valueLabelDisplay="auto"
        />
    );
}
