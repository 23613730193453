import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Button, Checkbox, Dialog, DialogContent,
    IconButton, FormControlLabel, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import combineStyles from 'utils/combineStyles';
import Loading from 'components/loading/Loading';
import { addBFILicenseToCart } from 'services/licenseService';
import { getContractPreview } from 'services/cartService';

import CloseIcon from '@material-ui/icons/Close';
import DashboardStyles from 'styles/dashboard';
import CStyles from './CStyles';

interface OwnProps {
    classes?: any;
    history?: any;
    collectionId: string;
    openPopup: boolean;
    dialogTitle: string;
    setOpenPopup: (value: boolean) => void | undefined;
}

function ContractPreview(props: OwnProps) {
    const { classes, openPopup } = props;
    const [ contractHTML, setContractHTML ] = useState('<div></div>');
    const [ isLoading, setLoading ] = useState(true);
    const [ isChecked, setChecked ] = useState(false);
    const mountedRef = useRef(true);

    const buyCollection = async (collectionId: string) => {
        setLoading(true);
        await addBFILicenseToCart(collectionId).then(
            (value) => { // On run
                setLoading(false);

                console.log(value);
                props.history.push("/myCustomLicenses");
                // window.location.reload();
            },
            (reason) => { // on fail
                console.log(reason)
            });
    }

    useEffect(() => {
        if (!props.collectionId) return;
        getContractPreview(props.collectionId)
            .then((value: string) => {
                if (!mountedRef.current) return;
                setContractHTML(value);
                setLoading(false);
            });

        return () => {
            mountedRef.current = false;
        };
    }, [props.collectionId]);

    const onClose = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            props.setOpenPopup(false)
        }
    }

    return (
        <Dialog
            open={openPopup}
            onClose={onClose}
            maxWidth="lg"
        >
            <DialogContent className={classes.popupContainer}>
                <div className={classes.popupHeader}>
                    <Typography
                        variant="h5"
                        className={classes.dashboardSubtitleBlue}
                    >
                        {props.dialogTitle}
                    </Typography>

                    <IconButton className={classes.closeButton} onClick={() => props.setOpenPopup(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className={isLoading ? classes.hiddenContainer : classes.contractContainer}>
                    
                    <div className={isLoading ? "hidden-contract" : "visible-contract"}>
                        {ReactHtmlParser(contractHTML)}
                    </div>

                    {isLoading ? 
                        <div className={classes.loadingContent}>
                            <Loading />
                        </div>
                    : <></>}
                </div>

                <FormControlLabel
                    className={classes.checkboxContainer}
                    label="I agree with the terms of the contract as set out above."
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={(e) => setChecked(e.target.checked)}
                            name="sign-license"
                            color="primary"
                        />
                    }
                />

                <div className={classes.contractOptions}>
                    <Button
                        className={classes.closeButtonFooter}
                        variant="outlined"
                        onClick={() => props.setOpenPopup(false)}
                    >
                        Decline
                    </Button>
                    <Button
                        className={classes.dashboardButton}
                        variant="outlined"
                        onClick={() => buyCollection(props.collectionId)}
                        disabled={isChecked ? false : true}
                    >
                        Accept
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const combinedStyles = combineStyles(DashboardStyles, CStyles);

export default withStyles(combinedStyles)(ContractPreview);