import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';

import App from './App';
import ScrollToTop from "components/scrollToTop/scrollToTop";
import store from "redux/store";
import { theme } from 'styles/themes';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastContainer/>
            <Provider store={store}>
                <Router basename="/makefilmhistory">
                    <ScrollToTop />
                    <App />
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
